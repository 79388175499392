import React, { useEffect, useState } from "react";
import { Frontend_URL } from "../environment";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  FreeOutputGetDataAction,
  EmptyReducer,
} from "../Redux/actions/PublicActions";
import { Loader } from "../components/Loader";
import Plot from "react-plotly.js";
import "plotly.js/dist/plotly-basic.min.js";

const FreeOutput = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, freeOutputGetData } = useSelector(
    (state) => state.FreeOutputGetReducer
  );
  const uniqueSolarSizes = [...new Set(freeOutputGetData?.graph1?.solar_size)];
  const uniqueSolarSizesTwo = [
    ...new Set(freeOutputGetData?.graph2?.solar_size),
  ];
  const [option1, setoption1] = useState("");
  const [option2, setoption2] = useState("");
  const [solarSize, setsolarSize] = useState("");
  const [solarSize2, setsolarSize2] = useState("");
  const id = localStorage.getItem("id");
  const fetchdata = async () => {
    await dispatch(EmptyReducer());

    const userID = JSON.parse(localStorage.getItem("userData"));
    const data = new FormData();
    data.append("user", userID?.user?.user_id);

    if (id) {
      await dispatch(FreeOutputGetDataAction(id, data));
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);

  useEffect(() => {
    if (freeOutputGetData) {
      setsolarSize(freeOutputGetData?.graph1?.solar_size[0]);
      setsolarSize2(freeOutputGetData?.graph2?.solar_size[0]);
    }
  }, [freeOutputGetData]);

  useEffect(() => {
    if (freeOutputGetData && solarSize) {
      const chartData = [
        {
          name: "Excess Solar Power",
          fill: "tozeroy",
          type: "scatter",
          marker: { color: "#1c5739" },
          x: freeOutputGetData?.graph1?.date_time,
          y: freeOutputGetData?.graph1?.excess_solar_power.filter(
            (_, i) => freeOutputGetData?.graph1?.solar_size[i] == solarSize
          ),
        },
        {
          name: "Grid Power",
          fill: "tozeroy",
          type: "scatter",
          marker: { color: "#4e97df" },
          x: freeOutputGetData?.graph1?.date_time,
          y: freeOutputGetData?.graph1?.grid_power.filter(
            (_, i) => freeOutputGetData?.graph1?.solar_size[i] == solarSize
          ),
        },
        {
          name: "Solar Rooftop",
          fill: "tozeroy",
          type: "scatter",
          marker: { color: "#14f188" },
          x: freeOutputGetData?.graph1?.date_time,
          y: freeOutputGetData?.graph1?.solar_rooftop.filter(
            (_, i) => freeOutputGetData?.graph1?.solar_size[i] == solarSize
          ),
        },
        {
          name: "Battery",
          type: "scatter",
          mode: "lines",
          fill: "tozeroy",
          marker: { color: "#f7a423" },
          x: freeOutputGetData?.graph1?.date_time,
          y: freeOutputGetData?.graph1?.Battery.filter(
            (_, i) => freeOutputGetData?.graph1?.solar_size[i] == solarSize
          ),
        },
      ];
      setoption1(chartData);
    }
  }, [solarSize]);

  const chartStyle = {
    width: "100%",
  };
  const layout1 = {
    xaxis: {
      title: "Date",
      rangeselector: {
        buttons: [
          {
            count: 12,
            label: "12h",
            step: "hour",
            stepmode: "backward",
          },
          {
            count: 1,
            label: "1d",
            step: "day",
            stepmode: "backward",
          },
          {
            count: 3,
            label: "3d",
            step: "day",
            stepmode: "backward",
          },
          {
            count: 7,
            label: "7d",
            step: "day",
            stepmode: "backward",
          },
          {
            count: 14,
            label: "2w",
            step: "day",
            stepmode: "backward",
          },
          {
            count: 1,
            label: "1m",
            step: "month",
            stepmode: "backward",
          },
          {
            count: 6,
            label: "6m",
            step: "month",
            stepmode: "backward",
          },
          {
            step: "all",
          },
        ],
      },
      rangeslider: {},
      type: "date",
    },
    yaxis: {
      title: {
        text: "Energy Used (kWh)",
        standoff: 20,
      },
      hoverformat: ",.0f",
    },
    hovermode: "x unified",
    fixedrange: true,
  };

  const formatValue = (value) => {
    if (value >= 1e3) {
      return Math.round(value / 1e3) + "k";
    } else {
      return Math.round(value);
    }
  };

  useEffect(() => {
    if (freeOutputGetData) {
      const chartData = [
        // {
        //   name: "Peak Cost",
        //   fill: "tozeroy",
        //   type: "bar",
        //   marker: { color: "#4e97df" },
        //   x: freeOutputGetData?.graph2?.years,
        //   y: freeOutputGetData?.graph2?.peak_cost.filter(
        //     (_, i) => freeOutputGetData?.graph2?.solar_size[i] === solarSize2
        //   ),
        // },
        {
          name: "Energy Cost",
          fill: "tozeroy",
          type: "bar",
          marker: { color: "#1c5739" },
          x: freeOutputGetData?.graph2?.years,
          y: freeOutputGetData?.graph2?.system_grid_cost.filter(
            (_, i) => freeOutputGetData?.graph2?.solar_size[i] === solarSize2
          ),
        },
        // {
        //   name: "Shoulder Cost",
        //   type: "bar",
        //   mode: "lines",
        //   fill: "tozeroy",
        //   marker: { color: "#f7a423" },
        //   x: freeOutputGetData?.graph2?.years,
        //   y: freeOutputGetData?.graph2?.shoulder_cost.filter(
        //     (_, i) => freeOutputGetData?.graph2?.solar_size[i] === solarSize2
        //   ),
        // },
        {
          name: "Demand Charge",
          fill: "tozeroy",
          type: "bar",
          marker: { color: "#14f188" },
          x: freeOutputGetData?.graph2?.years,
          y: freeOutputGetData?.graph2?.system_demand_charge.filter(
            (_, i) => freeOutputGetData?.graph2?.solar_size[i] === solarSize2
          ),
        },
        {
          name: "Delivery Charge",
          fill: "tozeroy",
          type: "bar",
          marker: { color: "#1d508d" },
          x: freeOutputGetData?.graph2?.years,
          y: freeOutputGetData?.graph2?.default_delivery_charge.filter(
            (_, i) => freeOutputGetData?.graph2?.solar_size[i] === solarSize2
          ),
        },
        {
          name: "Default Cost",
          type: "scatter",
          marker: { color: "#1cffec" },
          x: freeOutputGetData?.graph2?.years,
          y: freeOutputGetData?.graph2?.default_grid_energy_cost.filter(
            (_, i) => freeOutputGetData?.graph2?.solar_size[i] === solarSize2
          ),
        },
        {
          name: "Total",
          type: "scatter",
          mode: "text",
          x: freeOutputGetData?.graph2?.years,
          text: (freeOutputGetData?.graph2?.years || [])
            .map((_, i) => {
              if (freeOutputGetData?.graph2?.solar_size[i] === solarSize2) {
                return formatValue(
                  freeOutputGetData?.graph2?.default_delivery_charge[i] +
                    freeOutputGetData?.graph2?.system_demand_charge[i] +
                    // freeOutputGetData?.graph2?.shoulder_cost[i] +
                    freeOutputGetData?.graph2?.system_grid_cost[i]
                  // freeOutputGetData?.graph2?.peak_cost[i]
                );
              } else {
                return null;
              }
            })
            .filter((index) => index !== null),
          textposition: "top",
          marker: { color: "blue" },
          showlegend: false,
          hoverinfo: "skip",
          textfont: {
            size: 10,
            color: "white",
          },
        },
      ];
      setoption2(chartData);
    }
  }, [solarSize2]);

  const layout2 = {
    xaxis: {
      title: "Year",
    },
    yaxis: {
      title: {
        text: "Electricity Price ($)",
        standoff: 20,
      },
      hoverformat: ",.0f",
    },
    barmode: "stack",
    hovermode: "x unified",
  };

  const commasAfterThreeDigits = (value) => {
    if (typeof value === "string") {
      return value;
    }
    if (value === 0) {
      return "0";
    } else if (!isNaN(value)) {
      return value.toLocaleString();
    } else {
      return "N/A";
    }
  };

  const MapComponent = ({
    lat = -37.8179789,
    lon = 144.9690576,
    zoom = 19,
    size = "1200x300",
    maptype = "satellite",
  }) => {
    const [mapImageUrl, setMapImageUrl] = useState("");
    const markerColor = "red";
    const markerSize = "mid";
    const markerLabel = "";
    useEffect(() => {
      const getMapImage = async () => {
        try {
          const apiKey = "AIzaSyCBxQbrVABSO6ZpjQ3TR1be7zJRPay_xzk";
          const marker = `&markers=color:${markerColor}|size:${markerSize}|label:${markerLabel}|${lat},${lon}`;
          const url = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lon}&zoom=${zoom}&size=${size}&maptype=${maptype}&key=${apiKey}${marker}`;

          const response = await fetch(url);
          if (response.ok) {
            setMapImageUrl(url);
          } else {
            console.error("Failed to fetch map image");
          }
        } catch (error) {
          console.error("Error fetching map image", error);
        }
      };

      getMapImage();
    }, [lat, lon, zoom, size, maptype]);

    return <div>{mapImageUrl && <img src={mapImageUrl} alt="Map" />}</div>;
  };

  const handleSupplier = () => {
    localStorage.setItem("FreeOuputid", id);
    localStorage.removeItem("PremiumOuputid");
    navigate("/connection")
   
  };

  const handleSurvey = () => {
    window.open(`${Frontend_URL}survey`, "_blank");
  };
  return (
    <div className="freeoutput-container">
      {loading ? (
        <div style={{ marginTop: "10rem" }}>
          {" "}
          <Loader text="Estimating your renewable energy needs" />
        </div>
      ) : (
        <div className="freeOutput-margin">
          <div className="d-flex">
            {" "}
            <h2 className="solarHeading">
              Welcome To Your Personalised Solar Estimate!
            </h2>{" "}
            <button onClick={() => window.print()} className="freeOutput-btn13">
              Print
            </button>
          </div>

          <p className="text-center pb-4">
            Address: {freeOutputGetData?.address}
          </p>
          <div className="d-flex justify-content-center mapDivHeight">
            <MapComponent
              lat={freeOutputGetData?.lat}
              lon={freeOutputGetData?.long}
            />
          </div>
          <h2 className="solarHeading mt-5 mb-0">Summary</h2>
          <p className="freeout-disclaimerDiv">
            <b>Disclaimer</b>: The provided financial and technical data are an estimate and may vary
            based on specific requirements that may not have been fully
            captured. This tool is for feasibility assessment and should not be
            solely relied upon. Consultation with a professional installer is
            strongly advised.
          </p>
          <div className="solarEstimate-backImg">
            <div className="solarEstimate">
              <div className="solarEstChild">
                {freeOutputGetData?.battrey == 0 ? (
                  <>
                    <p className="solarEstText11">Solar Panel Array Area</p>
                    {freeOutputGetData && (
                      <p className="mb-0 solarEstText14">
                        {parseFloat(
                          freeOutputGetData?.installation_area
                        ).toFixed(1)}{" "}
                        Sqm
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <p className="solarEstText11">Battery Size</p>
                    {freeOutputGetData && (
                      <p className="mb-0 solarEstText14">
                        {freeOutputGetData?.battrey} kWh
                      </p>
                    )}
                  </>
                )}
              </div>
              <div className="solarEstChild">
                <p className="solarEstText11">Solar size</p>
                {freeOutputGetData && (
                  <p className="mb-0 solarEstText14">
                    {freeOutputGetData?.solar_size} kW
                  </p>
                )}
              </div>
              <div className="solarEstChild">
                <p className="solarEstText11">System cost</p>
                {freeOutputGetData && (
                  <p className="mb-0 solarEstText14">
                    ${" "}
                    {commasAfterThreeDigits(
                      Math.trunc(freeOutputGetData?.system_cost)
                    )}
                  </p>
                )}
              </div>
              <div className="solarEstChild">
                <p className="solarEstText11">
                  First year electricity bill with solar
                </p>
                {freeOutputGetData && (
                  <div className="d-flex justify-content-between solarEstText14">
                    <p className="mb-0">
                      ${" "}
                      {commasAfterThreeDigits(
                        Math.trunc(freeOutputGetData?.electriciy_bill)
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="solarEstimate">
              <div className="solarEstChild">
                <p className="solarEstText11">First year bill saving</p>
                {freeOutputGetData && (
                  <div className="d-flex justify-content-between align-items-center solarEstText14">
                    <p className="mb-0">
                      ${" "}
                      {commasAfterThreeDigits(freeOutputGetData?.value_display)}
                    </p>
                    <p className="mb-0 freeoutput-text5ty">
                      {Math.trunc(freeOutputGetData?.bill_saving)} %
                    </p>
                  </div>
                )}
              </div>
              <div className="solarEstChild">
                <p className="solarEstText11">Payback period</p>
                {freeOutputGetData?.playback_period && (
                  <div className="d-flex justify-content-between solarEstText14">
                    <p className="mb-0">
                      {parseFloat(freeOutputGetData?.playback_period).toFixed(
                        1
                      )}{" "}
                      years
                    </p>
                  </div>
                )}
              </div>
              <div className="solarEstChild">
                <p className="solarEstText11">
                  Carbon Abated in tonnes of CO<sub>2</sub>
                </p>
                {freeOutputGetData && (
                  <div className="d-flex justify-content-between align-items-center solarEstText14">
                    <p className="mb-0">
                      {commasAfterThreeDigits(freeOutputGetData?.carbon_offset)}{" "}
                      tCO<sub>2</sub>
                    </p>
                    <p className="mb-0 freeoutput-text5ty">
                      {freeOutputGetData?.carbon_emission} %
                    </p>
                  </div>
                )}
              </div>
              <div className="solarEstChild">
                <p className="solarEstText11">
                  Annual Return on Investment (%)
                </p>
                {freeOutputGetData && (
                  <p className="mb-0 solarEstText14">
                    {freeOutputGetData?.ROI} %
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="accordianBox">
            <div className="accordion" id="myAccordion4">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    type="button"
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Solar4"
                  >
                    {/* <img src={image3} alt="image" className="freeOut-img4" /> */}
                    <h2 className="heading55 mb-0 ms-1">
                      Installation Details
                    </h2>
                  </button>
                </h2>
                <div
                  id="Solar4"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#myAccordion4"
                >
                  <div className="padding666cls">
                    <h2 className="annualTextdiv">
                      Amount of solar power generated in Year 1 (kWh)
                    </h2>
                    {freeOutputGetData && (
                      <div className="textydj my-3">
                        {commasAfterThreeDigits(
                          Math.trunc(freeOutputGetData?.panel_genration)
                        )}{" "}
                        kW
                      </div>
                    )}
                    <h2 className="annualTextdiv">
                      Amount of excess solar (%)
                    </h2>
                    {freeOutputGetData && (
                      <div className="textydj my-3">
                        {freeOutputGetData?.consumed_power} %
                      </div>
                    )}
                    <h2 className="annualTextdiv">Energy Independence (%)</h2>
                    {freeOutputGetData && (
                      <div className="textydj my-3">
                        {freeOutputGetData?.sufficient_level} %
                      </div>
                    )}
                    <h2 className="annualTextdiv">
                      Internal Rate of Return (%)
                    </h2>
                    {freeOutputGetData && (
                      <div className="textydj my-3">
                        {" "}
                        {freeOutputGetData?.IRR} %
                      </div>
                    )}
                    <h2 className="annualTextdiv">
                      Total estimated bill savings over the installation life -
                      20 years ($)
                    </h2>
                    {freeOutputGetData && (
                      <div className="textydj my-3">
                        ${" "}
                        {commasAfterThreeDigits(freeOutputGetData?.life_saving)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordianBox">
            <div className="accordion" id="myAccordion2">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    type="button"
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Solar2"
                  >
                    {/* <img src={image3} alt="image" className="freeOut-img4 " /> */}
                    <h2 className="heading55 mb-0 ms-1">
                      Energy Source & Usage Breakdown
                    </h2>
                  </button>
                </h2>
                <div
                  id="Solar2"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#myAccordion2"
                >
                  <div className="padding666cls">
                    <div className="graphwidth11">
                      <select
                        className="select7y"
                        value={solarSize}
                        onChange={(e) => setsolarSize(e.target.value)}
                      >
                        {uniqueSolarSizes?.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <p className="mb-0">
                        Energy Consumption Chart Based on Sources for Solar
                        System {solarSize} kW
                      </p>
                      <div>
                        <Plot
                          data={option1}
                          layout={layout1}
                          style={chartStyle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordianBox">
            <div className="accordion" id="myAccordion3">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    type="button"
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Solar3"
                  >
                    {/* <img src={image3} alt="image" className="freeOut-img4 " /> */}
                    <h2 className="heading55 mb-0 ms-1">
                      Energy Cost Breakdown
                    </h2>
                  </button>
                </h2>
                <div
                  id="Solar3"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#myAccordion3"
                >
                  <div className="padding666cls">
                    <div className="graphwidth11">
                      <select
                        className="select7y"
                        value={solarSize2}
                        onChange={(e) => setsolarSize2(e.target.value)}
                      >
                        {uniqueSolarSizesTwo?.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <p className="mb-0">
                        Annual electricity chart for solar system {solarSize2}{" "}
                        kw
                      </p>
                      <div>
                        <Plot
                          data={option2}
                          layout={layout2}
                          style={chartStyle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="freeout-disclaimerDiv">
            <b> Kind Request</b>: We kindly request your participation in our
            survey, designed to refine and enhance our product. To ensure your
            convenience and prevent loss of data, the survey opens in a new tab.
            As our product is currently complimentary, your feedback is
            invaluable to us. Thank you for sharing your insights!
          </p>
          <div className="d-flex justify-content-evenly">
            <button className="freeOutput-btn " onClick={()=> navigate("/pricing")}>
              Upgrade to advanced feasibility
            </button>
            <button className="freeOutput-btn" onClick={handleSurvey}>
              Survey
            </button>
            <button
              onClick={handleSupplier}
              className="freeOutput-btn ms-4"
            >
              Connect to solar & battery supplier
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FreeOutput;
