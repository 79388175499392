import * as Yup from 'yup';

export const inputPagevalidationSchema = Yup.object().shape({
  organisation_name: Yup.string()
    .max(100, 'Organization Name must not exceed 100 characters')
    .required('Organization Name is required'),
  work_email: Yup.string()
    .email('Invalid email')
    .required('Work Email is required'),
  phone_number: Yup.string()
    .matches(/^\d{1,15}$/, 'Phone No. must be between 1 and 15 digits')
    .required('Phone No. is required'),
  carbon_credits: Yup.string().required('Carbon Credits selection is required'),
  sample_site: Yup.string().required('Sample Site selection is required'),
  nmi: Yup.number()
    .min(100000000000, 'NMI must be 12 digits')
    .max(999999999999, 'NMI must be 12 digits')
    .required('NMI is required'),
  year_of_achieving_renewable_energy: Yup.number()
    .min(new Date().getFullYear(), 'Year must be the current year or later')
    .max(2050, 'Year must be no later than 2050')
    .integer('Year must be an integer')
    .required('Year of Achieving 100% Renewable Energy is required'),
  electricity_price: Yup.string().required('Electricity Price is required'),
  system_capital_cost_per_kw_solar: Yup.string().required(
    'System Capital Cost Per KW-Solar is required'
  ),
  system_capital_cost_per_kw_battery: Yup.string().required(
    'System Capital Cost Per KW-Battery is required'
  ),
  technologies: Yup.array().min(1, 'Please select at least one technology'),
});
