import React, { useState, useEffect, useRef } from "react";
import { Frontend_URL } from "../environment";
import Payment from "./Payment";
import image5 from "../assets/images/upwardArrow.svg";
import GoogleMap from "../components/GoogleMapPremiumEnergy";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FreeEnergyFetchAction,
  FreeEnergyPostAction,
  EmailDataFetch,
  RedirectFetchData,
} from "../Redux/actions/PublicActions";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../components/Loader";
import { validations } from "../utils";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import video1 from "../assets/videos/CDRGuidance.mp4";

const PremiumEnergy = () => {
  const dispatch = useDispatch();
  const uploadFile = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const fromParam = queryParams.get("redirect");

  const { data: response1, error: error1 } = useSelector(
    (state) => state.FreeEnergyFetchReducer
  );
  const { data: response2, error: error2 } = useSelector(
    (state) => state.FreeEnergyPostReducer
  );
  const { data: NmiData, error: NmiError } = useSelector(
    (state) => state.EmailDataGetReducer
  );
  const { data: RedirectData, error: redirectError } = useSelector(
    (state) => state.RedirectFetchGetReducer
  );

  const [tabs, settabs] = useState(1);
  const [btnLoading, setbtnLoading] = useState(false);
  const [fullAddress, setFullAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [roofArea, setRoofArea] = useState(0);
  const [state, setstate] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [polygonPoints, setpolygonPoints] = useState([]);
  const [mapYourRoof, setMapYourRoof] = useState(true);
  const [automaticallyFetch, setautomaticallyFetch] = useState(false);
  const [solarValue, setsolarValue] = useState(true);
  const [solarBattery, setsolarBattery] = useState(false);
  const [weekend, setweekend] = useState("true");
  const [selectedIndustry, setSelectedIndustry] = useState("Aged Care");
  const [emailAddress, setemailAddress] = useState("");
  const [NMIinformation, setNMIinformation] = useState(true);
  const [fileType, setfileType] = useState("0");
  const [uploadfileName, setuploadfileName] = useState("");
  const [flatRate, setflatRate] = useState(true);
  const [FRdeliveryCharge, setFRdeliveryCharge] = useState();
  const [FRflatRate, setFRflatRate] = useState("");
  const [timeOfUse, setTimeOfUse] = useState(false);
  const [TOUdeliveryCharge, setTOUdeliveryCharge] = useState("");
  const [TOUpeakRate, setTOUpeakRate] = useState("");
  const [TOUoffPeakRate, setTOUoffPeakRate] = useState("");
  const [shoulderCharge, setshoulderCharge] = useState(false);
  const [SCshoulderCharge, setSCshoulderCharge] = useState("");
  const [feedTariff, setfeedTariff] = useState(false);
  const [demandCharge, setdemandCharge] = useState(false);
  const [microInverters, setmicroInverters] = useState(true);
  const [microInvertersSure, setmicroInvertersSure] = useState(false);
  const [installationPurpose, setinstallationPurpose] = useState("cost");
  const [cheapElectricity, setcheapElectricity] = useState(false);
  const [annualEnergyUsage, setannualEnergyUsage] = useState("0");
  const [annualEnergyUsageFivePer, setannualEnergyUsageFivePer] =
    useState(true);
  const [federalAndState, setfederalAndState] = useState(true);
  const [victoriaEnergy, setvictoriaEnergy] = useState(false);
  const [render, setRender] = useState(0);
  const [loadingData, setloadingData] = useState(false);
  const [nmiID, setNmiID] = useState();
  const [modalTab9, setmodalTab9] = useState(false);
  const [independence, setindependence] = useState();
  const [independenceValue, setindependenceValue] = useState(0);
  const [modalTab5, setmodalTab5] = useState();
  const [showSampledemo, setshowSampledemo] = useState(false);
  const [errors, setErrors] = useState({
    ShowErrorArea: null,
    ShowErrorAddress: null,
    uploadFile: null,
    emailErr: null,
    frDeliveryErr: null,
    frFlatRateErr: null,
    touDeliveryErr: null,
    touPeakErr: null,
    touOffPeakErr: null,
    shoulderErr: null,
  });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  let arr = [
    `This charge shows up differently on your bill. Sometimes, it's called a delivery charge; other times, it's split into parts. Here's what it includes:<br/> Metering charge: This is for tracking how much energy you use.<br/>Network fixed/access/standing charge covers the energy network cost.<br/>Retail charge: This is what your energy retailer charges you for their services.<br/>These charges are added up daily and can vary depending on which retailer you're with.<br/>For instance, if you have a meter charge of 120c and a standing charge of 80c. The delivery charge is 200c(120+80)`,
    "Flat charges include both the peak energy and network charges. Sometimes, you'll see them as a single value on your bill; other times, the network charges might not be listed separately.",
    "Peak charges include both the peak energy and network charges. Sometimes, you'll see them as a single value on your bill; other times, the network charges might not be listed separately.",
    "Off-peak charges include both the off-peak energy and network charges. Like peak charges, they might be shown as a single value, or the network charges might not be itemised.",
    "Shoulder charges include both shoulder energy and network charges. They might appear as a single value on your bill, or the network charges might not be detailed separately.",
  ];

  useEffect(() => {
    const getData = async () => {
      if (fromParam) {
        setloadingData(true);
        const dataF = JSON.parse(localStorage.getItem("BasicEnergy"));
        const emailData = dataF?.emailAddress;
        const state = dataF?.state;
        const pinCode = dataF?.pinCode;

        const data = { emailData, state, pinCode };
        if (emailData) {
          setFullAddress(dataF?.fullAddress);
          setLongitude(dataF?.longitude);
          setLatitude(dataF?.latitude);
          setRoofArea(dataF?.roofArea);
          setstate(dataF?.state);
          setPinCode(dataF?.pinCode);
          setautomaticallyFetch(dataF?.automaticallyFetch);
          setsolarValue(dataF?.solarValue);
          setsolarBattery(dataF?.solarBattery);
          setSelectedIndustry(dataF?.selectedIndustry);
          setemailAddress(dataF?.emailAddress);
          setNMIinformation(dataF?.NMIinformation);
          setpolygonPoints(dataF?.polygonPoints);
        }
        setRender(4);
        settabs(5);
        await dispatch(RedirectFetchData(data));
      }
    };
    getData();
  }, [fromParam]);

  useEffect(() => {
    if (state == "VIC") {
      setvictoriaEnergy(true);
    }
  }, [state]);

  useEffect(() => {
    if (render == 1 && response1) {
      setbtnLoading(false);
      setRender(0);
      setFRdeliveryCharge(response1?.electricity?.delivery_charge);
      setFRflatRate(response1?.electricity?.flat_rate);
      setTOUdeliveryCharge(response1?.electricity?.delivery_charge);
      setTOUpeakRate(response1?.electricity?.peak);
      setTOUoffPeakRate(response1?.electricity?.off_peak);
      setSCshoulderCharge(response1?.electricity?.shoulder);
      setNmiID(response1?.data?.id);
      settabs(5);
    }
    if (render == 2 && response2) {
      // Swal.fire({
      //   title: "Submit",
      //   text: "Form Submitted",
      //   icon: "success",
      //   showConfirmButton: false,
      //   timer: 3000,
      // });
      localStorage.setItem("id", response2?.id);
      
      setRender(0);
      setTimeout(() => {
        navigate("/premium-output");
      }, [3000]);
    }
    if (render == 3 && NmiData) {
      setRender(0);
      setbtnLoading(false);
      window.open(NmiData.data, "_blank");
    }
    if (RedirectData && render == 4) {
      setRender(0);
      setloadingData(false);
      setFRdeliveryCharge(RedirectData?.electricity?.delivery_charge);
      setFRflatRate(RedirectData?.electricity?.flat_rate);
      setTOUdeliveryCharge(RedirectData?.electricity?.delivery_charge);
      setTOUpeakRate(RedirectData?.electricity?.peak);
      setTOUoffPeakRate(RedirectData?.electricity?.off_peak);
      setSCshoulderCharge(RedirectData?.electricity?.shoulder);
      setNmiID(RedirectData?.data?.id);
    }
  }, [response1, response2, NmiData, RedirectData]);

  useEffect(() => {
    if (render == 1 && error1) {
      Swal.fire({
        title: "Error",
        text: error1,
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
      setbtnLoading(false);
      setRender(0);
    }
    if (render == 2 && error2) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong.",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
      setRender(0);
    }
    if (NmiError && render == 3) {
      setRender(0);
      setbtnLoading(false);
      Swal.fire({
        text: NmiError,
        icon: "error",
        confirmButtonText: "Got it!",
      });
    }
    if (redirectError && render == 4) {
      setloadingData(false);
      Swal.fire({
        text: redirectError,
        icon: "error",
        confirmButtonText: "Got it!",
      });
      setRender(0);
    }
  }, [error1, error2, NmiError, redirectError]);

  const handleTabFirst = () => {
    const tempErrors = {
      area: !fullAddress && "*Please enter address",
      address: mapYourRoof && !roofArea && "*Please select roof area",
    };
    setErrors((prevErrors) => ({
      ...prevErrors,
      ShowErrorArea: tempErrors.address,
      ShowErrorAddress: tempErrors.area,
    }));
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    settabs(2);
  };

  const handleTabSecond = () => {
    if (automaticallyFetch) {
      settabs(3);
    } else {
      settabs(4);
    }
  };

  const handleTabThird = async () => {
    const tempErrors = {
      Email: validations.email(emailAddress),
    };
    setErrors((prevErrors) => ({
      ...prevErrors,
      emailErr: tempErrors.Email,
    }));
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    const data = {
      fullAddress,
      longitude,
      latitude,
      roofArea,
      state,
      pinCode,
      automaticallyFetch,
      solarValue,
      solarBattery,
      selectedIndustry,
      emailAddress,
      NMIinformation,
      polygonPoints,
    };
    localStorage.setItem("BasicEnergy", JSON.stringify(data));
    setRender(3);
    setbtnLoading(true);
    await dispatch(EmailDataFetch(emailAddress));
  };

  const ValueCheck = (val) => {
    if (val === 0) return null;
    if (val === "") return "*Required";
    if (!val) return "*Required";
    return null;
  };

  const handleTabFive = () => {
    const tempErrors = {
      frDelivery: flatRate && ValueCheck(FRdeliveryCharge),
      frFlatRate: flatRate && ValueCheck(FRflatRate),
      touDelivery: timeOfUse && ValueCheck(TOUdeliveryCharge),
      touPeak: timeOfUse && ValueCheck(TOUpeakRate),
      touOffPeak: timeOfUse && ValueCheck(TOUoffPeakRate),
      shoulder: shoulderCharge && ValueCheck(SCshoulderCharge),
    };
    setErrors((prevErrors) => ({
      ...prevErrors,
      frDeliveryErr: tempErrors.frDelivery,
      frFlatRateErr: tempErrors.frFlatRate,
      touDeliveryErr: tempErrors.touDelivery,
      touPeakErr: tempErrors.touPeak,
      touOffPeakErr: tempErrors.touOffPeak,
      shoulderErr: tempErrors.shoulder,
    }));
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    settabs(6);
  };

  const handleFile = () => {
    const selectedFile = uploadFile?.current?.files[0];
    const allowedFormats = ["csv", "xls", "xlsx"];
    const fileNameArray = selectedFile?.name?.split(".");
    const fileExtension =
      fileNameArray[fileNameArray?.length - 1]?.toLowerCase();
    if (allowedFormats?.includes(fileExtension)) {
      setuploadfileName(selectedFile);
      setErrors((prevErrors) => ({
        ...prevErrors,
        uploadFile: null,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        uploadFile: "Upload csv or excel file ",
      }));
      setuploadfileName("");
    }
  };

  const submitHandlerFirst = () => {
    const tempErrors = {
      fileErr: !uploadfileName && "Please upload file",
    };
    setErrors((prevErrors) => ({
      ...prevErrors,
      uploadFile: tempErrors.fileErr,
    }));
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }

    const userID = JSON.parse(localStorage.getItem("userData"))?.user?.user_id;
    const formData = new FormData();
    formData.append("energy_type", fileType);
    formData.append("nmi_file", uploadfileName);
    // formData.append("nmi_file", uploadFile?.current?.files[0] || null);
    formData.append("state", state);
    formData.append("postcode", pinCode);
    formData.append("user", userID);
    setRender(1);
    setbtnLoading(true);
    dispatch(FreeEnergyFetchAction(formData));
  };

  const submitHandlerSecond = () => {
    const userID = JSON.parse(localStorage.getItem("userData"))?.user?.user_id;
    const formData = new FormData();
    formData.append("address", fullAddress);
    formData.append("longitude", longitude);
    formData.append("latitude", latitude);
    formData.append("roof_size", roofArea);
    formData.append("postcode", pinCode);
    formData.append("state", state);
    formData.append("is_automatic_fetch", automaticallyFetch);
    formData.append("solar", true);
    formData.append("battrey", solarBattery);
    if (weekend == "true") {
      formData.append("weekend", true);
    } else {
      formData.append("weekend", false);
    }
    formData.append("industry", selectedIndustry);
    formData.append("energy_type", fileType);
    formData.append("is_flat_rate", flatRate);
    FRdeliveryCharge && formData.append("delivery_charge", FRdeliveryCharge);
    FRflatRate && formData.append("flat_rate", FRflatRate);
    TOUdeliveryCharge && formData.append("delivery_charge", TOUdeliveryCharge);
    TOUpeakRate && formData.append("peak", TOUpeakRate);
    TOUoffPeakRate && formData.append("off_peak", TOUoffPeakRate);
    formData.append("is_shoulder", shoulderCharge);
    SCshoulderCharge && formData.append("shoulder_charge", SCshoulderCharge);
    formData.append("is_fit", feedTariff);
    formData.append("demand_charge", demandCharge);
    if (microInvertersSure) {
      formData.append("is_inverter", true);
    } else {
      formData.append("is_inverter", microInverters);
    }
    formData.append("purpose", installationPurpose);

    if (independence == "true") {
      formData.append("indepen_prop", independenceValue);
      formData.append("off_grid", false);
    }

    if (independence == "false") {
      formData.append("off_grid", true);
      formData.append("indepen_prop", 0);
    }

    formData.append("grid_charge", cheapElectricity);
    if (annualEnergyUsageFivePer) {
      formData.append("increase_energy", 2);
    } else {
      formData.append("increase_energy", annualEnergyUsage);
    }
    formData.append("ren_cert", federalAndState);
    formData.append("is_vic", victoriaEnergy);
    formData.append("nmi", nmiID);
    formData.append("polygon", JSON.stringify(polygonPoints));
    localStorage.removeItem("BasicEnergy");
    setRender(2);
    dispatch(FreeEnergyPostAction(formData));
  };

  const handleTabFirst9Read = () => {
    localStorage.setItem("tab9", true);
    window.open(`${Frontend_URL}glossary`, "_blank");
  };
  const handleTab4Redi1 = () => {
    localStorage.setItem("tab2_1", true);
    window.open(`${Frontend_URL}glossary`, "_blank");
  };

  const handleTab4Redi2 = () => {
    localStorage.setItem("tab2_2", true);
    window.open(`${Frontend_URL}glossary`, "_blank");
  };

  const handleOptionChange = () => {
    setannualEnergyUsageFivePer(!annualEnergyUsageFivePer);
    setannualEnergyUsage(0);
  };

  const handleReachOut = () => {
    // navigate('/contact-us')
    window.open(`${Frontend_URL}contact-us`, "_blank");
  };

  return loadingData ? (
    <div style={{ marginTop: "10rem", minHeight: "40vh" }}>
      <Loader text="Loading..." />
    </div>
  ) : (
    <div className="basicEnergy-container">
      {tabs !== 15 && (
        <div className="basicEnergy-section1 ">
          <p className="basicEnergy-para11 mb-0">
            Scientifically Unlock Your Perfect Solar & Battery Match
          </p>
        </div>
      )}
      {tabs !== 15 && (
        <div className="divFeasib7546">
          {tabs === 1 && (
            <>
              <div className="divFeasiv555">
                <GoogleMap
                  setFullAddress={setFullAddress}
                  setLongitude={setLongitude}
                  setLatitude={setLatitude}
                  roofArea={roofArea}
                  setRoofArea={setRoofArea}
                  setstate={setstate}
                  setPinCode={setPinCode}
                  mapYourRoof={mapYourRoof}
                  setMapYourRoof={setMapYourRoof}
                  setpolygonPoints={setpolygonPoints}
                  errors={errors}
                  setErrors={setErrors}
                />
              </div>

              <div className="d-flex justify-content-end">
                <button className="btn77Feasib" onClick={handleTabFirst}>
                  Next
                </button>
              </div>
            </>
          )}

          {tabs === 2 && (
            <>
              <div className="divFeasiv555">
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    Do you want to automatically fetch your electricity data
                  </label>
                  <div className="d-flex">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value8"
                        checked={automaticallyFetch}
                        onChange={() => {
                          setautomaticallyFetch(true);
                        }}
                      />
                      <label className="label55" htmlFor="value8">
                        Yes
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value7"
                        checked={!automaticallyFetch}
                        onChange={() => {
                          setautomaticallyFetch(false);
                        }}
                      />
                      <label className="label55" htmlFor="value7">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    What are you interested in
                  </label>
                  <div className="d-flex">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value8"
                        checked={solarValue}
                        onChange={() => {
                          setsolarValue(true);
                          setsolarBattery(false);
                        }}
                      />
                      <label className="label55" htmlFor="value8">
                        Solar
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value7"
                        checked={solarBattery}
                        onChange={() => {
                          setsolarBattery(true);
                          setsolarValue(false);
                        }}
                      />
                      <label className="label55" htmlFor="value7">
                        Solar+Battery
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    Do you operate on weekends
                  </label>
                  <div className="d-flex">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value9"
                        name="grouph4"
                        checked={weekend == "true" && true}
                        onChange={() => setweekend("true")}
                      />
                      <label className="label55" htmlFor="value9">
                        Yes/Frequently
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value9"
                        name="grouph4"
                        checked={weekend == "false1" && true}
                        onChange={() => setweekend("false1")}
                      />
                      <label className="label55" htmlFor="value9">
                        Occasionally
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value10"
                        name="grouph4"
                        checked={weekend == "false2" && true}
                        onChange={() => setweekend("false2")}
                      />
                      <label className="label55" htmlFor="value10">
                        No/Rarely
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-5 ">
                  <label className="feasibLabel mb-2">
                    What industry do you operate in (If your industry isn't
                    listed, choose one that's similar in how it uses energy.)
                  </label>
                  <select
                    className="feasibInput"
                    value={selectedIndustry}
                    onChange={(e) => setSelectedIndustry(e.target.value)}
                  >
                    <option value="Aged Care">Aged Care Center</option>
                    <option value="Agriculture">Agricultural Site</option>
                    <option value="Bakery">Bakery Producer</option>
                    <option value="Beverage Manufacturer">
                      Beverage Producer
                    </option>
                    <option value="Child care">Child Care Center</option>
                    <option value="Cool Storage">Cold Storage Warehouse</option>
                    <option value="Community Centre">Community Centre</option>
                    <option value="Gym">Community Gym</option>
                    <option value="Coporate Body Commercial">
                      Coporate Headquarters
                    </option>
                    <option value="Data Center">Data Center</option>
                    <option value="Distribution Center">
                      Distribution Center
                    </option>
                    <option value="Fastfood Chain">Fastfood Shop</option>
                    <option value="Hospital">Hospital</option>
                    <option value="Medium Hotel">Hotel</option>
                    <option value="Logistics Centre">
                      Logistics Warehouse
                    </option>
                    <option value="Manufacturing">Manufacturing Complex</option>
                    <option value="Metal Fabrication">Metal Workshop</option>
                    <option value="Office Small">Office</option>
                    <option value="Retail Pharmacist">   Pharmaceutical Shop </option>
                    <option value="Printing Press">Printing Center</option>
                    <option value="Residential House">Residential House</option>
                    <option value="Restaurant Bar n Grill">Restaurant</option>
                    <option value="Hardware store">Retail Hardware Shop</option>
                    <option value="Retail 7 days">Retail Shop</option>
                    <option value="Timbermill">Sawmill</option>
                    <option value="Shopping Center Large">
                      Shopping Center
                    </option>
                    <option value="Supermarket">Supermarket</option>
                  </select>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button className="btn77Feasib" onClick={() => settabs(1)}>
                  Back
                </button>
                <button
                  className="btn77Feasib btn44-reachOut"
                  onClick={handleReachOut}
                >
                  Feeling Stuck, Reach Out!
                </button>
                <button className="btn77Feasib" onClick={handleTabSecond}>
                  Next
                </button>
              </div>
            </>
          )}

          {tabs === 3 && (
            <>
              <div className="divFeasiv555">
                <div className="mb-5 mt-4">
                  <label className="feasibLabel mb-2">
                    Please provide the email address that you receive your
                    electricity bill on or that you used in setting up your
                    electricity account.{" "}
                    <span
                      className="basicEnergy-span3333t"
                      onClick={() => setshowSampledemo(true)}
                    >
                      See a sample demo
                    </span>
                  </label>
                  <input
                    className="feasibInput"
                    type="text"
                    value={emailAddress}
                    onChange={(e) => {
                      setemailAddress(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        emailErr: null,
                      }));
                    }}
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors?.emailErr ? 1 : 0,
                    }}
                  >
                    {errors?.emailErr ?? "valid"}
                  </span>
                </div>
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    By providing this initial email address, you agree to share
                    your NMI information to Nature Electric
                  </label>
                  <div className="d-flex">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value8"
                        checked={NMIinformation}
                        onChange={() => setNMIinformation(true)}
                      />
                      <label className="label55" htmlFor="value8">
                        Yes
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value7"
                        checked={!NMIinformation}
                        onChange={() => setNMIinformation(false)}
                      />
                      <label className="label55" htmlFor="value7">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button className="btn77Feasib" onClick={() => settabs(2)}>
                  Back
                </button>
                <button
                  className="btn77Feasib btn44-reachOut"
                  onClick={handleReachOut}
                >
                  Feeling Stuck, Reach Out!
                </button>
                <button
                  className={`btn77Feasib ${!NMIinformation && "btndisbale33"}`}
                  disabled={!NMIinformation}
                  onClick={handleTabThird}
                >
                  {btnLoading ? (
                    <>
                      Loading..
                      <i
                        className="fa fa-circle-o-notch fa-spin"
                        style={{ height: "18px", width: "20px" }}
                      ></i>
                    </>
                  ) : (
                    <>Next</>
                  )}
                </button>
              </div>
            </>
          )}
          <Modal show={showSampledemo} class="ravi" id="modal-sampleDemo">
            <div className="basicEnergy-modalDiv333">
              <div className="d-flex justify-content-end">
                <i
                  class="fa-regular fa-xmark mb-2 text-white"
                  style={{ cursor: "pointer" }}
                  onClick={() => setshowSampledemo(false)}
                ></i>
              </div>
              <div className="sampledemoDiv99">
                <ReactPlayer
                  url={video1}
                  type="video/mp4"
                  controls={true}
                  playing={true}
                  loop={true}
                  muted={true}
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </Modal>
          {tabs === 4 && (
            <>
              <div className="divFeasiv555">
                <div className="mb-4 mt-4">
                  <label className="feasibLabel mb-2">
                    Select the type of file to be uploaded.{" "}
                    <span className="readmore111" onClick={handleTab4Redi1}>
                      Learn how to request
                    </span>{" "}
                    your meter data, and{" "}
                    <span className="readmore111" onClick={handleTab4Redi2}>
                      upload different kind of file samples.
                    </span>
                  </label>
                  <select
                    className="feasibInput mb-3"
                    onChange={(e) => setfileType(e.target.value)}
                  >
                    <option value={0}>NEM12</option>
                    <option value={1}>Formatted Data</option>
                  </select>
                </div>
                <div className="position-relative mb-5">
                  <div className="position-relative">
                    <div
                      className="divfeasiu8"
                      onClick={() => uploadFile.current.click()}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={uploadFile}
                        onChange={handleFile}
                      />
                      <img className="imgFeas344h" src={image5} alt="image" />
                      <p className="textFeas89k mb-0">
                        {uploadfileName
                          ? uploadfileName?.name
                          : "Please upload your meter data in the selected format"}
                      </p>
                    </div>
                  </div>
                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      position: "absolute",
                      opacity: errors?.uploadFile ? 1 : 0,
                    }}
                  >
                    {errors?.uploadFile ?? "valid"}
                  </span>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button className="btn77Feasib" onClick={() => settabs(2)}>
                  Back
                </button>
                <button
                  className="btn77Feasib btn44-reachOut"
                  onClick={handleReachOut}
                >
                  Feeling Stuck, Reach Out!
                </button>
                <button className="btn77Feasib" onClick={submitHandlerFirst}>
                  {btnLoading ? (
                    <>
                      Loading..
                      <i
                        className="fa fa-circle-o-notch fa-spin"
                        style={{ height: "18px", width: "20px" }}
                      ></i>
                    </>
                  ) : (
                    <>Next</>
                  )}
                </button>
              </div>
            </>
          )}
          {tabs === 5 && (
            <>
              <div className="divFeasiv555">
                <label className="feasibLabel mt-4 mb-3">
                  Enter your electricity tarrif details. See an example bill for
                  more details
                </label>
                <div className="mb-3">
                  <div className="d-flex align-items-start mb-3">
                    <input
                      type="radio"
                      className="input-Topmargin"
                      id="value8"
                      checked={flatRate}
                      onChange={() => {
                        setflatRate(true);
                        setTimeOfUse(false);
                      }}
                    />
                    <label className="label55" htmlFor="value8">
                      Flat Rate
                    </label>
                  </div>
                  {flatRate && (
                    <div className="ms-4">
                      <div className="d-flex mb-3">
                        <p className="text111Address width-444div">
                          <span
                            className="basicEnergy-span3333t"
                            onClick={() => setmodalTab5(1)}
                          >
                            Delivery/Supply Charge
                          </span>
                        </p>
                        <div>
                          <input
                            type="text"
                            className="input-Address12"
                            value={FRdeliveryCharge}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (!isNaN(value)) {
                                setFRdeliveryCharge(value);
                              }
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                frDeliveryErr: null,
                              }));
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "red",
                              fontSize: "13px",
                              opacity: errors?.frDeliveryErr ? 1 : 0,
                            }}
                          >
                            {errors?.frDeliveryErr ?? "valid"}
                          </span>
                        </div>
                        <p className="text111Address ms-3">c/day</p>
                      </div>
                      <div className="d-flex">
                        <p className="text111Address width-444div">
                          {" "}
                          <span
                            className="basicEnergy-span3333t"
                            onClick={() => setmodalTab5(2)}
                          >
                            Flat Rate
                          </span>
                        </p>
                        <div>
                          <input
                            type="text"
                            className="input-Address12"
                            value={FRflatRate}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (!isNaN(value)) {
                                setFRflatRate(value);
                              }
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                frFlatRateErr: null,
                              }));
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "red",
                              fontSize: "13px",
                              opacity: errors?.frFlatRateErr ? 1 : 0,
                            }}
                          >
                            {errors?.frFlatRateErr ?? "valid"}
                          </span>
                        </div>
                        <p className="text111Address ms-3">c/kWh</p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mb-5">
                  <div className="d-flex align-items-start mb-3">
                    <input
                      type="radio"
                      className="input-Topmargin"
                      id="value8"
                      checked={timeOfUse}
                      onChange={() => {
                        setTimeOfUse(true);
                        setflatRate(false);
                      }}
                    />
                    <label className="label55" htmlFor="value8">
                      Time of Use(TOU)
                    </label>
                  </div>
                  {timeOfUse && (
                    <div className="ms-4">
                      <div className="d-flex  mb-3">
                        <p className="text111Address width-444div">
                          <span
                            className="basicEnergy-span3333t"
                            onClick={() => setmodalTab5(1)}
                          >
                            Delivery/Supply Charge
                          </span>
                        </p>
                        <div>
                          <input
                            type="text"
                            className="input-Address12"
                            value={TOUdeliveryCharge}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (!isNaN(value)) {
                                setTOUdeliveryCharge(value);
                              }
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                touDeliveryErr: null,
                              }));
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "red",
                              fontSize: "13px",
                              opacity: errors?.touDeliveryErr ? 1 : 0,
                            }}
                          >
                            {errors?.touDeliveryErr ?? "valid"}
                          </span>
                        </div>
                        <p className="text111Address ms-3">c/day</p>
                      </div>
                      <div className="d-flex  mb-3">
                        <p className="text111Address width-444div">
                          {" "}
                          <span
                            className="basicEnergy-span3333t"
                            onClick={() => setmodalTab5(3)}
                          >
                            Peak Rate
                          </span>
                        </p>
                        <div>
                          <input
                            type="text"
                            className="input-Address12"
                            value={TOUpeakRate}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (!isNaN(value)) {
                                setTOUpeakRate(value);
                              }
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                touPeakErr: null,
                              }));
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "red",
                              fontSize: "13px",
                              opacity: errors?.touPeakErr ? 1 : 0,
                            }}
                          >
                            {errors?.touPeakErr ?? "valid"}
                          </span>
                        </div>
                        <p className="text111Address ms-3">c/kWh</p>
                      </div>
                      <div className="d-flex mb-3">
                        <p className="text111Address width-444div">
                          <span
                            className="basicEnergy-span3333t"
                            onClick={() => setmodalTab5(4)}
                          >
                            Off Peak Rate
                          </span>
                        </p>
                        <div>
                          <input
                            type="text"
                            className="input-Address12"
                            value={TOUoffPeakRate}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (!isNaN(value)) {
                                setTOUoffPeakRate(value);
                              }
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                touOffPeakErr: null,
                              }));
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "red",
                              fontSize: "13px",
                              opacity: errors?.touOffPeakErr ? 1 : 0,
                            }}
                          >
                            {errors?.touOffPeakErr ?? "valid"}
                          </span>
                        </div>
                        <p className="text111Address ms-3">c/kWh</p>
                      </div>
                      <div className="">
                        <label className="feasibLabel mb-2">
                          Is there a shoulder charge
                        </label>
                        <div className="d-flex">
                          <div className="d-flex align-items-start">
                            <input
                              type="radio"
                              className="input-Topmargin"
                              id="value8"
                              checked={shoulderCharge}
                              onChange={() => setshoulderCharge(true)}
                            />
                            <label className="label55" htmlFor="value8">
                              Yes
                            </label>
                          </div>
                          <div className="d-flex align-items-start">
                            <input
                              type="radio"
                              className="input-Topmargin"
                              id="value7"
                              checked={!shoulderCharge}
                              onChange={() => setshoulderCharge(false)}
                            />
                            <label className="label55" htmlFor="value7">
                              No
                            </label>
                          </div>
                        </div>
                        {shoulderCharge && (
                          <div className="d-flex mt-3">
                            <p className="text111Address width-444div">
                              <span
                                className="basicEnergy-span3333t"
                                onClick={() => setmodalTab5(5)}
                              >
                                Shoulder Charge
                              </span>
                            </p>
                            <div>
                              <input
                                type="text"
                                className="input-Address12"
                                value={SCshoulderCharge}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (!isNaN(value)) {
                                    setSCshoulderCharge(value);
                                  }
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    shoulderErr: null,
                                  }));
                                }}
                              />
                              <br />
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "13px",
                                  opacity: errors?.shoulderErr ? 1 : 0,
                                }}
                              >
                                {errors?.shoulderErr ?? "valid"}
                              </span>
                            </div>
                            <p className="text111Address ms-3">c/kWh</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-between">
                {automaticallyFetch ? (
                  <button className="btn77Feasib" onClick={() => settabs(3)}>
                    Back
                  </button>
                ) : (
                  <button className="btn77Feasib" onClick={() => settabs(4)}>
                    Back
                  </button>
                )}
                <button
                  className="btn77Feasib btn44-reachOut"
                  onClick={handleReachOut}
                >
                  Feeling Stuck, Reach Out!
                </button>
                <button className="btn77Feasib" onClick={handleTabFive}>
                  Next
                </button>
              </div>
              <Modal show={modalTab5}>
                <div className="basicEnergy-modalDiv333">
                  <div className="d-flex justify-content-end">
                    {" "}
                    <i
                      class="fa-regular fa-xmark"
                      style={{ cursor: "pointer" }}
                      onClick={() => setmodalTab5(null)}
                    ></i>
                  </div>
                  <hr className="mt-2" />
                  {/* <p className="mb-0">{modalTab5 && arr[modalTab5 - 1]}</p> */}
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{ __html: arr[modalTab5 - 1] }}
                  ></p>
                </div>
              </Modal>
            </>
          )}
          {tabs === 6 && (
            <>
              <div className="divFeasiv555">
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    Should Feed in Tariff be estimated
                  </label>
                  <div className="d-flex">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value8"
                        checked={feedTariff}
                        onChange={() => setfeedTariff(true)}
                      />
                      <label className="label55" htmlFor="value8">
                        Yes
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value7"
                        checked={!feedTariff}
                        onChange={() => setfeedTariff(false)}
                      />
                      <label className="label55" htmlFor="value7">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button className="btn77Feasib" onClick={() => settabs(5)}>
                  Back
                </button>
                <button
                  className="btn77Feasib btn44-reachOut"
                  onClick={handleReachOut}
                >
                  Feeling Stuck, Reach Out!
                </button>
                <button className="btn77Feasib" onClick={() => settabs(7)}>
                  Next
                </button>
              </div>
            </>
          )}
          {tabs === 7 && (
            <>
              <div className="divFeasiv555">
                <p className="feasibLabel mb-5">Demand Charge</p>
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    Do you have demand charge
                  </label>
                  <div className="d-flex">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value8"
                        checked={demandCharge}
                        onChange={() => setdemandCharge(true)}
                      />
                      <label className="label55" htmlFor="value8">
                        Yes
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value7"
                        checked={!demandCharge}
                        onChange={() => setdemandCharge(false)}
                      />
                      <label className="label55" htmlFor="value7">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button className="btn77Feasib" onClick={() => settabs(6)}>
                  Back
                </button>
                <button
                  className="btn77Feasib btn44-reachOut"
                  onClick={handleReachOut}
                >
                  Feeling Stuck, Reach Out!
                </button>
                <button className="btn77Feasib" onClick={() => settabs(9)}>
                  Next
                </button>
              </div>
            </>
          )}

          {tabs === 9 && (
            <>
              <div className="divFeasiv555">
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    Would you like the inverter to be replaced, or do you prefer
                    <span
                      className="basicEnergy-span3333t"
                      onClick={() => setmodalTab9(true)}
                    >
                      {" "}
                      micro inverters?
                    </span>
                  </label>
                  <div className="d-flex">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value8"
                        checked={microInverters}
                        onChange={() => {
                          setmicroInverters(true);
                          setmicroInvertersSure(false);
                        }}
                      />
                      <label className="label55" htmlFor="value8">
                        Yes
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value7"
                        checked={!microInverters && !microInvertersSure}
                        onChange={() => {
                          setmicroInverters(false);
                          setmicroInvertersSure(false);
                        }}
                      />
                      <label className="label55" htmlFor="value7">
                        No
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value9"
                        checked={!microInverters && microInvertersSure}
                        onChange={() => {
                          setmicroInverters(false);
                          setmicroInvertersSure(true);
                        }}
                      />
                      <label className="label55" htmlFor="value9">
                        I am not sure
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button className="btn77Feasib" onClick={() => settabs(7)}>
                  Back
                </button>
                <button
                  className="btn77Feasib btn44-reachOut"
                  onClick={handleReachOut}
                >
                  Feeling Stuck, Reach Out!
                </button>
                <button
                  className="btn77Feasib"
                  onClick={() => (solarBattery ? settabs(10) : settabs(12))}
                >
                  Next
                </button>
              </div>
              <Modal show={modalTab9}>
                <div className="basicEnergy-modalDiv333">
                  <div className="d-flex justify-content-end">
                    {" "}
                    <i
                      class="fa-regular fa-xmark"
                      style={{ cursor: "pointer" }}
                      onClick={() => setmodalTab9(false)}
                    ></i>
                  </div>
                  <hr className="mt-2" />
                  <p className="mb-0">
                    For businesses, the choice depends on budget, maintenance
                    preferences, and the importance of maximizing solar panel
                    efficiency, with micro-inverters offering better individual
                    panel performance, monitoring and longer lifespan but at a
                    higher initial cost (up to 30% more).{" "}
                  </p>
                  <p className="readmore111" onClick={handleTabFirst9Read}>
                    Read more..
                  </p>
                </div>
              </Modal>
            </>
          )}
          {tabs === 10 && (
            <>
              <div className="divFeasiv555">
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    What is the main purpose for battery installation
                  </label>
                  <div className="">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value8"
                        checked={installationPurpose == "cost" && true}
                        onChange={() => {
                          setinstallationPurpose("cost");
                          setindependence();
                          setindependenceValue(0);
                        }}
                      />
                      <label className="label55" htmlFor="value8">
                        Electricity Bill reduction
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value8"
                        checked={installationPurpose == "demand" && true}
                        onChange={() => {
                          setinstallationPurpose("demand");
                          setindependence();
                          setindependenceValue(0);
                        }}
                      />
                      <label className="label55" htmlFor="value8">
                        Electricity Demand Shaving
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value7"
                        checked={installationPurpose == "independence" && true}
                        onChange={() => setinstallationPurpose("independence")}
                      />
                      <label className="label55" htmlFor="value7">
                        Energy Independence/ Sustainability
                      </label>
                    </div>

                    {installationPurpose == "independence" && (
                      <div className="d-flex ms-3 mt-2">
                        <div className="d-flex align-items-start">
                          <input
                            type="radio"
                            className="input-Topmargin"
                            id="value10"
                            checked={independence == "true" && true}
                            onChange={() => setindependence("true")}
                          />
                          <div>
                            <label className="label55" htmlFor="value10">
                              Independence Level
                            </label>
                            {independence == "true" && (
                              <>
                                <div className="d-flex align-items-center">
                                  <span className="me-2">0</span>
                                  <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    value={independenceValue}
                                    onChange={(e) => {
                                      setindependenceValue(e.target.value);
                                    }}
                                  />
                                  <span className="ms-2">100</span>
                                </div>
                                <p> {independenceValue}%</p>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="d-flex align-items-start">
                          <input
                            type="radio"
                            className="input-Topmargin"
                            id="value11"
                            checked={independence == "false" && true}
                            onChange={() => {
                              setindependence("false");
                              setindependenceValue(0);
                            }}
                          />
                          <label className="label55" htmlFor="value11">
                            Off Grid
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button className="btn77Feasib" onClick={() => settabs(9)}>
                  Back
                </button>
                <button
                  className="btn77Feasib btn44-reachOut"
                  onClick={handleReachOut}
                >
                  Feeling Stuck, Reach Out!
                </button>
                <button className="btn77Feasib" onClick={() => settabs(11)}>
                  Next
                </button>
              </div>
            </>
          )}
          {tabs === 11 && (
            <>
              <div className="divFeasiv555">
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    Do you want to charge your battery using cheap electricity
                    from your retailer
                  </label>
                  <div className="d-flex">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value8"
                        checked={cheapElectricity}
                        onChange={() => setcheapElectricity(true)}
                      />
                      <label className="label55" htmlFor="value8">
                        Yes
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value7"
                        checked={!cheapElectricity}
                        onChange={() => setcheapElectricity(false)}
                      />
                      <label className="label55" htmlFor="value7">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button className="btn77Feasib" onClick={() => settabs(10)}>
                  Back
                </button>
                <button
                  className="btn77Feasib btn44-reachOut"
                  onClick={handleReachOut}
                >
                  Feeling Stuck, Reach Out!
                </button>
                <button className="btn77Feasib" onClick={() => settabs(12)}>
                  Next
                </button>
              </div>
            </>
          )}
          {tabs === 12 && (
            <>
              <div className="divFeasiv555">
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    Annual increase in energy usage (%) (This is due to increase
                    in machinery, staff and operations)
                  </label>
                  <br />

                  <div className="d-flex align-items-center">
                    <span className="me-2">0</span>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={annualEnergyUsage}
                      onChange={(e) => {
                        setannualEnergyUsage(e.target.value);
                        setannualEnergyUsageFivePer(false);
                      }}
                    />
                    <span className="ms-2">100</span>
                  </div>
                  <p className="feasibLabel">{annualEnergyUsage}%</p>
                </div>

                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    Average Industry Default (2%)
                  </label>
                  <br />

                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      id="option2"
                      name="options"
                      checked={annualEnergyUsageFivePer}
                      onChange={handleOptionChange}
                    />
                    <label className=" feasibLabel ms-2 mb-0" htmlFor="option2">
                      2%
                    </label>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button
                  className="btn77Feasib"
                  onClick={() => (solarBattery ? settabs(11) : settabs(9))}
                >
                  Back
                </button>
                <button
                  className="btn77Feasib btn44-reachOut"
                  onClick={handleReachOut}
                >
                  Feeling Stuck, Reach Out!
                </button>
                <button className="btn77Feasib" onClick={() => settabs(13)}>
                  Next
                </button>
              </div>
            </>
          )}
          {tabs === 13 && (
            <>
              <div className="divFeasiv555">
                <p className="feasibLabel mb-5">Energy Rebate</p>
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    Do you want to to consider energy /solar rebates from
                    Federal and State Governments?
                  </label>
                  <div className="d-flex">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value8"
                        checked={federalAndState}
                        onChange={() => setfederalAndState(true)}
                      />
                      <label className="label55" htmlFor="value8">
                        Yes
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value7"
                        checked={!federalAndState}
                        onChange={() => setfederalAndState(false)}
                      />
                      <label className="label55" htmlFor="value7">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button className="btn77Feasib" onClick={() => settabs(12)}>
                  Back
                </button>
                <button
                  className="btn77Feasib btn44-reachOut"
                  onClick={handleReachOut}
                >
                  Feeling Stuck, Reach Out!
                </button>
                <button
                  className="btn77Feasib"
                  onClick={() =>
                    settabs(federalAndState && state === "VIC" ? 14 : 15)
                  }
                >
                  Next
                </button>
              </div>
            </>
          )}
          {tabs === 14 && (
            <>
              <div className="divFeasiv555">
                {" "}
                <p className="feasibLabel mb-5">Energy Rebate</p>
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    Will you prefer Victoria's Energy Incentive compared to the
                    Federal Incentive
                  </label>
                  <div className="d-flex">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value8"
                        checked={victoriaEnergy}
                        onChange={() => setvictoriaEnergy(true)}
                      />
                      <label className="label55" htmlFor="value8">
                        Yes
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value7"
                        checked={!victoriaEnergy}
                        onChange={() => setvictoriaEnergy(false)}
                      />
                      <label className="label55" htmlFor="value7">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button className="btn77Feasib" onClick={() => settabs(13)}>
                  Back
                </button>
                <button
                  className="btn77Feasib btn44-reachOut"
                  onClick={handleReachOut}
                >
                  Feeling Stuck, Reach Out!
                </button>
                <button className="btn77Feasib" onClick={() => settabs(15)}>
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      )}

      {tabs === 15 && <Payment submitHandlerSecond={submitHandlerSecond} />}
    </div>
  );
};

export default PremiumEnergy;
