import Logo from '../assets/images/Logo.svg';
import Profile from '../assets/images/profile.svg';
import Toparrow from '../assets/images/toparrow.svg';
import Startfill from '../assets/images/Starfill.svg';
import Star from '../assets/images/Star.svg';

export const icons = {
  Logo,
  Profile,
  Toparrow,
  Startfill,
  Star,
};
