import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Swal from "sweetalert2";
import { BACKEND_URL } from "../environment";

const stripePromise = loadStripe("pk_live_51PPam104h3AMXCk7AZp9fLV7BVHdBw6seUz9bO09ZG6FwOC6EvkPiVLLRJ9nbeAyLuDeeoCA28KIddSCVb1aTrRz00WHPGsboy");

const StripePayment = ({ submitHandlerSecond, isCouponValid }) => {
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState("");
  const [succeeded, setSucceeded] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [success, setSuccess] = useState(false);
  // const [customerName, setCustomerName] = useState("");

  const stripe = useStripe();
  const elements = useElements();


  const apiEndpoint = `${BACKEND_URL}api/stripe/create-payment-intent/`;

  // create a payment intent
  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await fetch(apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        if (data.client_secret) {
          setClientSecret(data.client_secret);
        } else {
          throw new Error("Client secret not found in the response");
        }
      } catch (err) {
        setError("Error fetching client secret");
        console.error(err);
      }
    };

    fetchClientSecret();
  }, [apiEndpoint]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (elements) {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [elements]);


  // Card element style
  const cardStyle = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  // handle input errors
  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };


  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    // setLoading(true);
    setProcessing(true);
    // Set a flag in localStorage
    console.log('Stripe Form submitted');
    localStorage.setItem('formSubmitted', 'StripeForm');
    

    if (!stripe || !elements) {
      // setLoading(false);
      setProcessing(false)
      setError("Stripe has not loaded yet");
      return;
    }

    // Use CardElement to confirm payment
    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) {
      setProcessing(false);
      setError("Stripe CardNumberElement not found");
      return;
    }

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardNumberElement, // Use the CardNumberElement here
      },
    });

    if (payload.error) {
      // setError(`Payment failed ${payload.error.message}`);

      // Check if the error is related to Indian regulations
      if (!payload.error.message.includes("As per Indian regulations, export transactions require a customer name and address")) {
        // Only display the error if it's not related to Indian regulations
        setError(`Payment failed: ${payload.error.message}`);
      } else {
        // Log the error to console for debugging, if needed
        console.warn("Payment failed due to missing customer information as per Indian regulations.");
      }
      setProcessing(false);
      // Call submitHandlerSecond and wait for it to complete
      submitHandlerSecond() // Payment Failed, call the submit handler

    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      submitHandlerSecond();// Payment succeeded, call the submit handler

    }




    // const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: cardElement,
    //   billing_details: {
    //     name: customerName,
    //   },
    // });

    // if (paymentMethodError) {
    //   setError(paymentMethodError.message);
    //   setLoading(false);
    // } else {
    //   // Confirm the payment with the client secret
    //   const { error: confirmError } = await stripe.confirmCardPayment(clientSecret, {
    //     payment_method: paymentMethod.id,
    //   });

    //   if (confirmError) {
    //     setError(confirmError.message);
    //     setLoading(false);
    //   } else {
    //     // Payment succeeded, call the submit handler
    //     submitHandlerSecond();
    //   }
    // }
  };




  return (

    <form onSubmit={handleSubmit} className="card p-4 mt-3" id="payment-form">
      <div className="form-group" >
        <label htmlFor="cardNumber">Card Number</label>
        <CardNumberElement
          id="cardNumber"
          className="form-control"
          onChange={handleChange}
          options={cardStyle}
       
        />
      </div>

      <div className="form-row d-flex py-2">
        <div className="form-group col-md-6">
          <label htmlFor="cardExpiry">Expiration Date</label>
          <CardExpiryElement
            id="cardExpiry"
            className="form-control"
            onChange={handleChange}
            options={cardStyle}
          />
        </div>

        <div className="form-group col-md-6 px-1">
          <label htmlFor="cardCvc">CVV</label>
          <CardCvcElement
            id="cardCvc"
            className="form-control"
            onChange={handleChange}
            options={cardStyle}
          />
        </div>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">Payment successful!</div>}
      <button
        type="submit"
        className="btn btn-primary mt-3"
        disabled={!stripe || processing || !clientSecret || isCouponValid}
      >
        {processing ? "Processing..." : "Pay"}
      </button>
    </form>


  );
};

const StripePaymentWrapper = ({ submitHandlerSecond, isCouponValid }) => {
  return (
    <Elements stripe={stripePromise}>
      <StripePayment submitHandlerSecond={submitHandlerSecond} isCouponValid={isCouponValid} />
    </Elements>
  );

}

export default StripePaymentWrapper;