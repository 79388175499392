import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ForgotPasswordAction } from "../Redux/actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { validations } from "../utils";
import swal from "sweetalert";
import image1 from "../assets/images/Singup55Image.svg";
import image2 from "../assets/images/Logo3333Image.svg";

const Forgot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [render, setRender] = useState(false);

  const { success, error, message } = useSelector(
    (state) => state.ForgotPasswordReducer
  );
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({
    email: "",
  });
  const handleForgotSubmit = () => {
    // navigate("/dashboard");
    const formData = new FormData();
    formData.append("email", email);
    dispatch(ForgotPasswordAction(formData));
    setRender(true);
  };
  const validateSubmit = (e) => {
    e.preventDefault();
    const tempErrors = {
      email: validations.email(email),
    };
    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    handleForgotSubmit();
  };

  useEffect(() => {
    if (success && render) {
      swal({
        title: "Successfully",
        text: message,
        className: "successAlert",
        icon: "img/logo.svg",
        buttons: false,
        timer: 3000,
        open: true,
      });
      navigate("/login");
    }
    if (error && render) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "img/logo.svg",
        buttons: false,
        timer: 3000,
      });
      setRender(false);
    }
  }, [success, error]);
  return (
    <>
      {/* <div className="forgotdiv">
        <div className="forgotsec">
          <div className="login-logoimg">
            <img src="img/logo.svg" />
          </div>
          <div className="forgot">
            <div className="forgotinput">
              <input
                type="Email"
                onChange={(e) => {
                  setErrors({ ...errors, email: null });
                  setEmail(e.target.value);
                }}
                placeholder="Enter Email"
                className="input-1"
                required
              />
              <span
                style={{
                  color: "red",
                  fontSize: "13px",
                  opacity: errors.email ? 1 : 0,
                }}
              >
                {errors.email ?? "valid"}
              </span>
            </div>
            <div className="forgotbtn">
              <button className="send" type="button" onClick={validateSubmit}>
                Send
              </button>
              <br />
              <Link className="cancel" to="/">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div> */}
      <div className="login-container">
        <div class="login-flex-div1">
          <img className="login-img122" src={image1} alt="img" />
          <div className="login-div12">
            <h2 className="login-heading12e">
              The journey to Renewable transition can be simple
            </h2>
            <p className="login-paraText11">
              Find out how renewable options can help reduce your bills and
              carbon footprint
            </p>
          </div>
        </div>
        <div className="login-flex-div2">
          <div className="login-flex-div33">
            <h2 className="login-heading33">Forget Password</h2>

            <div className="login-labelInput-Div">
              <label className="login-label">Enter Email</label>
              <input
                type="email"
                className="login-input "
                onChange={(e) => {
                  setErrors({ ...errors, email: null });
                  setEmail(e.target.value);
                }}
              />
              <p
                style={{
                  color: "red",
                  fontSize: "13px",
                  opacity: errors.email ? 1 : 0,
                  position:'absolute'
                }}
              >
                {errors.email ?? "valid"}
              </p>
            </div>
            <button className="login-btn11" onClick={(e) => validateSubmit(e)}>
              Send
            </button>
            <p className="Login-LinkText text-start">
             <Link to="/login">Back</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;
