import * as Auth from "../types/AuthType";

export const ContactusPostReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.CONTACT_POST_REQUEST:
      return { loading: true };
    case Auth.CONTACT_POST_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.CONTACT_POST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ConnectionPostReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.CONNECTION_POST_REQUEST:
      return { loading: true };
    case Auth.CONNECTION_POST_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.CONNECTION_POST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const FeasibilityTab1PostReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.FEASIBILITY_TAB1_POST_REQUEST:
      return { loading: true };
    case Auth.FEASIBILITY_TAB1_POST_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.FEASIBILITY_TAB1_POST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const FeasibilityPostReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.FEASIBILITY_POST_REQUEST:
      return { loading: true };
    case Auth.FEASIBILITY_POST_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.FEASIBILITY_POST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const FreeOutputGetReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.FREE_OUTPUT_GET_REQUEST:
      return { loading: true };
    case Auth.FREE_OUTPUT_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        freeOutputGetData: action.payload,
        message: action.payload.message,
      };
    case Auth.FREE_OUTPUT_GET_FAIL:
      return { loading: false, error: action.payload };
    case Auth.FREE_OUTPUT_EMPTY_REDUCER:
      return { loading: false, freeOutputGetData: {} };
    default:
      return state;
  }
};

export const PlanInputPostReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.PLANINPUT_POST_REQUEST:
      return { loading: true };
    case Auth.PLANINPUT_POST_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.PLANINPUT_POST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const BasicOutputGetReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.BASIC_OUTPUT_GET_REQUEST:
      return { loading: true };
    case Auth.BASIC_OUTPUT_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        GetData: action.payload,
        message: action.payload.message,
      };
    case Auth.BASIC_OUTPUT_GET_FAIL:
      return { loading: false, error: action.payload };
    case Auth.BASIC_OUTPUT_EMPTY_REDUCER:
      return { loading: false, GetData: {} };
    default:
      return state;
  }
};

export const UploadHistoryGetReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.UPLOAD_HISTORY_GET_REQUEST:
      return { loading: true };
    case Auth.UPLOAD_HISTORY_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.UPLOAD_HISTORY_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const UploadHistoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.UPLOAD_HISTORY_DELETE_REQUEST:
      return { loading: true };
    case Auth.UPLOAD_HISTORY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.UPLOAD_HISTORY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const DeleteProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.DELETE_PROFILE_REQUEST:
      return { loading: true };
    case Auth.DELETE_PROFILE_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.DELETE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const EmailDataGetReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.EMAIL_DATA_GET_REQUEST:
      return { loading: true };
    case Auth.EMAIL_DATA_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.EMAIL_DATA_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const RedirectFetchGetReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.REDIRECT_FETCH_GET_REQUEST:
      return { loading: true };
    case Auth.REDIRECT_FETCH_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.REDIRECT_FETCH_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const FeeOutPutPostReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.FREE_OUTPUT_POST_REQUEST:
      return { loading: true };
    case Auth.FREE_OUTPUT_POST_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.FREE_OUTPUT_POST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const FreeEnergyFetchReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.ENERGY_FETCH_DATA_REQUEST:
      return { loading: true };
    case Auth.ENERGY_FETCH_DATA_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.ENERGY_FETCH_DATA_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const FreeEnergyPostReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.FREE_ENERGY_POST_REQUEST:
      return { loading: true };
    case Auth.FREE_ENERGY_POST_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.FREE_ENERGY_POST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const MoreTrailsPostReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.TRAILS_CONTACTUS_POST_REQUEST:
      return { loading: true };
    case Auth.TRAILS_CONTACTUS_POST_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.TRAILS_CONTACTUS_POST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const SurveyFormsGetReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.SURVEY_FORMS_GET_REQUEST:
      return { loading: true };
    case Auth.SURVEY_FORMS_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.SURVEY_FORMS_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const SurveyFormsPostReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.SURVEY_FORMS_POST_REQUEST:
      return { loading: true };
    case Auth.SURVEY_FORMS_POST_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.message,
      };
    case Auth.SURVEY_FORMS_POST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};



