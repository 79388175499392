import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GoogleMap from "../components/googleMap";
import { useFormik } from "formik";
import { inputPagevalidationSchema } from "../validation/inputPage";
import { ErrorMessage } from "../components/ErrorMessage";
import { inputPageService } from "../services/inputPag.service";
import { storageService } from "../services/storage.service";

const InputPage = () => {
  const [isToggle, setIsisToggle] = useState(false);
  const [fullAddress, setFullAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [roofArea, setRoofArea] = useState(null);

  const navigate = useNavigate();

  const handleToggle = () => {
    setIsisToggle(!isToggle);
  };

  const checkboxesData = [
    { id: "1", label: "Solar Roof Top", name: "solarpv" },
    { id: "2", label: "Battery", name: "battery" },
    { id: "3", label: "Solar PPA", name: "solarppa" },
    { id: "4", label: "Wind PPA", name: "windppa" },
    { id: "5", label: "Hydro PPA", name: "hydroppa" },
    { id: "6", label: "Green PPA", name: "greenppa" },
  ];

  const handleCheckboxChange = (checkbox) => {
    if (checkbox.name === "greenppa") {
      // When "Green PPA" is checked, uncheck the others
      const idToRemove = ["3", "4", "5"];
      const updateTechnology = values.technologies.filter(
        (t) => !idToRemove.includes(t)
      );
      if (!updateTechnology.includes(checkbox.id)) {
        updateTechnology.push(checkbox.id);
      }
      setFieldValue("technologies", updateTechnology);
    } else if (checkbox.name === "battery") {
      let updateTechnology = [...values.technologies];
      // Toggle the state of "Battery" and "Solar Roof Top"
      if (values.technologies.includes("2")) {
        const idToRemove = ["1", "2"];
        updateTechnology = updateTechnology.filter(
          (t) => !idToRemove.includes(t)
        );
      } else {
        updateTechnology.push("2");
        updateTechnology.push("1");
      }
      setFieldValue("technologies", updateTechnology);
    } else if (checkbox.name === "solarpv") {
      // Toggle the state of "Solar Roof Top" and "Battery"

      let updateTechnology = [...values.technologies];
      // Toggle the state of "Battery" and "Solar Roof Top"
      if (values.technologies.includes("1")) {
        const idToRemove = ["1", "2"];
        updateTechnology = updateTechnology.filter(
          (t) => !idToRemove.includes(t)
        );
      } else {
        updateTechnology.push("1");
      }
      setFieldValue("technologies", updateTechnology);
    } else {
      // When others are checked, uncheck "Green PPA"
      let updateTechnology = [...values.technologies];
      if (values.technologies.includes(checkbox.id)) {
        updateTechnology = updateTechnology.filter((t) => t !== checkbox.id);
      } else {
        updateTechnology.push(checkbox.id);
      }
      updateTechnology = updateTechnology.filter((t) => t !== "6");
      setFieldValue("technologies", updateTechnology);
    }
  };

  const initialValues = {
    organisation_name: "",
    work_email: "",
    phone_number: "",
    carbon_credits: "",
    demand_charge: "",
    battery_frm_grid: "",
    sample_site: "",
    nmi: "",
    year_of_achieving_renewable_energy: "",
    electricity_price: "28.00",
    system_capital_cost_per_kw_solar: "1000.00",
    system_capital_cost_per_kw_battery: "1000.00",
    technologies: [],
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: inputPagevalidationSchema,
    onSubmit: (values, action) => {
      handleSubmitForm();
      action.resetForm();
    },
  });

  const handleSubmitForm = async () => {
    try {
      const withLocation = {
        ...values,
        with_giving_access_nmi: isToggle,
        site_address: fullAddress,
        roof_size: roofArea,
        longitude: longitude,
        latitude: latitude,
      };
      const result = await inputPageService.inputPage(withLocation);
      storageService.setToLocalStorage("nature_electric_input_id", result?.id);
      storageService.setToLocalStorage("work_email", values.work_email);
      navigate("/output-one");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="inputpage">
            {/* <h2 className="mb-4">Input Page</h2> */}

            <div className="inputPageForm">
              <div className="mb-3">
                <label className="form-label">
                  Organization Name<span>*</span>
                </label>
                <input
                  name="organisation_name"
                  {...getFieldProps("organisation_name")}
                  onBlur={handleBlur}
                  className={`form-control ${
                    touched["organisation_name"] && errors["organisation_name"]
                      ? "is-invalid"
                      : ""
                  }`}
                  type="text"
                  placeholder="Enter Organization Name"
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="organisation_name"
                />
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className="form-label">
                    Work Email<span>*</span>
                  </label>
                  <input
                    {...getFieldProps("work_email")}
                    onBlur={handleBlur}
                    className={`form-control ${
                      touched["work_email"] && errors["work_email"]
                        ? "is-invalid"
                        : ""
                    }`}
                    name="work_email"
                    type="email"
                    placeholder="Enter work email"
                  />
                  <ErrorMessage
                    errors={errors}
                    touched={touched}
                    name="work_email"
                  />
                </div>
                <div className="col">
                  <label className="form-label">
                    Phone No.<span>*</span>
                  </label>
                  <input
                    name="phone_number"
                    {...getFieldProps("phone_number")}
                    onBlur={handleBlur}
                    className={`form-control ${
                      touched["phone_number"] && errors["phone_number"]
                        ? "is-invalid"
                        : ""
                    }`}
                    type="number"
                    placeholder="Enter Your Mobile Number"
                  />
                  <ErrorMessage
                    errors={errors}
                    touched={touched}
                    name="phone_number"
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">
                  Technologies <span>*</span>
                </label>
                <div className="technologyInput">
                  {checkboxesData.map((checkbox) => (
                    <div className="form-group inputbox" key={checkbox.id}>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          value="True"
                          id={`checkbox-${checkbox.id}`}
                          className={`form-check-input p-3 ${
                            touched["technologies"] && errors["technologies"]
                              ? "is-invalid"
                              : ""
                          }`}
                          checked={values.technologies.includes(checkbox.id)}
                          onChange={() => handleCheckboxChange(checkbox)}
                        />
                        {checkbox.label}
                      </div>
                    </div>
                  ))}
                </div>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="technologies"
                />
              </div>

              <div className="mb-2 mt-4">
                <div className="d-flex">
                  <label className="form-label">
                    Carbon Credits<span>*</span>
                  </label>
                  <div className="form-check">
                    <input
                      name="carbon_credits"
                      {...getFieldProps("carbon_credits")}
                      onBlur={handleBlur}
                      className={`form-check-input ${
                        touched["carbon_credits"] && errors["carbon_credits"]
                          ? "is-invalid"
                          : ""
                      }`}
                      type="radio"
                      value="True"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label  me-4"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      name="carbon_credits"
                      {...getFieldProps("carbon_credits")}
                      onBlur={handleBlur}
                      className={`form-check-input ${
                        touched["carbon_credits"] && errors["carbon_credits"]
                          ? "is-invalid"
                          : ""
                      }`}
                      type="radio"
                      value="False"
                      id="flexRadioDefault2"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      No
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="carbon_credits"
                />
              </div>

              <div className="mb-2 mt-4">
                <div className="d-flex">
                  <label className="form-label">
                    Demand Charge<span>*</span>
                  </label>
                  <div className="form-check">
                    <input
                      name="demand_charge"
                      {...getFieldProps("demand_charge")}
                      onBlur={handleBlur}
                      className={`form-check-input ${
                        touched["demand_charge"] && errors["demand_charge"]
                          ? "is-invalid"
                          : ""
                      }`}
                      type="radio"
                      value="True"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label  me-4"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      name="demand_charge"
                      {...getFieldProps("demand_charge")}
                      onBlur={handleBlur}
                      className={`form-check-input ${
                        touched["demand_charge"] && errors["demand_charge"]
                          ? "is-invalid"
                          : ""
                      }`}
                      type="radio"
                      value="False"
                      id="flexRadioDefault2"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      No
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="demand_charge"
                />
              </div>

              <div className="mb-2 mt-4">
                <div className="d-flex">
                  <label className="form-label">
                    Charge battery from Grid<span>*</span>
                  </label>
                  <div className="form-check">
                    <input
                      name="battery_frm_grid"
                      {...getFieldProps("battery_frm_grid")}
                      onBlur={handleBlur}
                      className={`form-check-input ${
                        touched["battery_frm_grid"] &&
                        errors["battery_frm_grid"]
                          ? "is-invalid"
                          : ""
                      }`}
                      type="radio"
                      value="True"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label  me-4"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      name="battery_frm_grid"
                      {...getFieldProps("battery_frm_grid")}
                      onBlur={handleBlur}
                      className={`form-check-input ${
                        touched["battery_frm_grid"] &&
                        errors["battery_frm_grid"]
                          ? "is-invalid"
                          : ""
                      }`}
                      type="radio"
                      value="False"
                      id="flexRadioDefault2"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      No
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="battery_frm_grid"
                />
              </div>

              <GoogleMap
                fullAddress={fullAddress}
                setFullAddress={setFullAddress}
                longitude={longitude}
                setLongitude={setLongitude}
                latitude={latitude}
                setLatitude={setLatitude}
                roofArea={roofArea}
                setRoofArea={setRoofArea}
              />

              <div className="mb-3">
                <label className="form-label">Sample Site</label>
                <select
                  name="sample_site"
                  {...getFieldProps("sample_site")}
                  onBlur={handleBlur}
                  className={`form-select ${
                    touched["sample_site"] && errors["sample_site"]
                      ? "is-invalid"
                      : ""
                  }`}
                  aria-label="Default select example"
                >
                  <option value="" defaultValue>
                    Select
                  </option>
                  <option value="samplesiteA">Site 1</option>
                  <option value="samplesiteB">Site 2</option>
                  <option value="samplesiteC">Site 3</option>
                </select>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="sample_site"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">NMI</label>
                <input
                  name="nmi"
                  type="number"
                  {...getFieldProps("nmi")}
                  onBlur={handleBlur}
                  className={`form-control ${
                    touched["nmi"] && errors["nmi"] ? "is-invalid" : ""
                  }`}
                  placeholder="Enter 12 digit NMI"
                />
                <ErrorMessage errors={errors} touched={touched} name="nmi" />
                <small id="emailHelp" className="form-text text-muted">
                  As we are finalising the development, NMI data connot be
                  fatched at this stage. We can get your energy use file in
                  excel and run the simulation for you
                </small>
              </div>

              <div className="switchbtn">
                <p>
                  With giving us the access to your NMI, you agree to share your
                  energy cost and energy consumption
                </p>
                <input
                  type="checkbox"
                  id="switch"
                  checked={isToggle}
                  onChange={handleToggle}
                />
                <label htmlFor="switch">Toggle</label>
              </div>

              <div className="mb-3">
                <label className="form-label">
                  Year of Achiving 100% Renewable energy
                </label>
                <input
                  name="year_of_achieving_renewable_energy"
                  type="text"
                  {...getFieldProps("year_of_achieving_renewable_energy")}
                  onBlur={handleBlur}
                  className={`form-control ${
                    touched["year_of_achieving_renewable_energy"] &&
                    errors["year_of_achieving_renewable_energy"]
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter Year..."
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="year_of_achieving_renewable_energy"
                />
                <small id="emailHelp" className="form-text text-muted">
                  {`${
                    touched["year_of_achieving_renewable_energy"] &&
                    errors["year_of_achieving_renewable_energy"]
                      ? ""
                      : "2050 Maximum"
                  }`}
                </small>
              </div>

              <div className="mb-3">
                <label className="form-label">Electricity Price</label>
                <input
                  name="electricity_price"
                  type="text"
                  {...getFieldProps("electricity_price")}
                  onBlur={handleBlur}
                  className={`form-control ${
                    touched["electricity_price"] && errors["electricity_price"]
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="$28.00"
                  disabled={true}
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="electricity_price"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">
                  System Capital Cost Per KW-Solor
                </label>
                <input
                  name="system_capital_cost_per_kw_solar"
                  type="text"
                  {...getFieldProps("system_capital_cost_per_kw_solar")}
                  onBlur={handleBlur}
                  className={`form-control ${
                    touched["system_capital_cost_per_kw_solar"] &&
                    errors["system_capital_cost_per_kw_solar"]
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="$1000.00"
                  disabled={true}
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="system_capital_cost_per_kw_solar"
                />
              </div>

              <div className="mb-5">
                <label className="form-label">
                  System Capital Cost Per KW-Battery
                </label>
                <input
                  name="system_capital_cost_per_kw_battery"
                  type="text"
                  {...getFieldProps("system_capital_cost_per_kw_battery")}
                  onBlur={handleBlur}
                  className={`form-control ${
                    touched["system_capital_cost_per_kw_battery"] &&
                    errors["system_capital_cost_per_kw_battery"]
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="$1000.00"
                  disabled={true}
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="system_capital_cost_per_kw_battery"
                />
                <small id="emailHelp" className="form-text text-muted">
                  Other assumptions made in the model would be available in the
                  full product
                </small>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between p-3 mb-3">
          <button type="button" className="cancelBtn">
            Cancel
          </button>
          <button type="submit" className="submitBtn">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default InputPage;
