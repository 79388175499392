import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import image1 from "../assets/images/PriceImage33g5.svg";

const Pricing = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);

  return (
    <div className="pricing-outerContainer">
      {tab == 1 && (
        <>
          <div className="pricing-container">
            <div className="">
              <h2 className="price-heading55">Pricing</h2>
              <p className="price-text87u">
                Choose the best plan to conduct more detailed analysis for your
                solar&battery adoption pathway
              </p>
            </div>
            <div className="pricing-flex34">
              <div className="pricing-flexChild34">
                <h2 className="priceHeading66p">Free</h2>
                <p className="price-txt63">
                  Realise the benefits of installing solar & battery with the
                  least amount of information giving away.
                </p>
                <button
                  className="button34-price"
                  onClick={() => navigate("/")}
                >
                  Stay Free
                </button>
                <div className="mt-3 px-4">
                  <p className="price-txt63">
                    Solar & battery installation area mapping
                  </p>
                  <p className="price-txt63">Energy profile estimation</p>
                  <p className="price-txt63">
                    Solar & battery installation system estimation
                  </p>
                  <p className="price-txt63">
                    Solar & battery installation investment estimation
                  </p>
                  <p className="price-txt63">
                    Solar & battery installation return on investment estimation
                  </p>
                </div>
              </div>
              <div className="pricing-flexChild34">
                <div className="pricing-flex34-Div4">
                  <h2 className="priceHeading66p position-relative">
                    Premium{" "}
                    <span className="priceheading3-recomm">Recommended</span>
                  </h2>
                  <p className="price-txt63 ">
                    Unlock more detailed assessments and improve the return on
                    Investment by over 20%.
                  </p>
                  <button
                    className="button34-price pricebtn-color1"
                    onClick={() => navigate("/login")}
                  >
                    Get started
                  </button>
                  <p className="mt-3 price-txt63">Multiple roof area mapping</p>
                  <p className="mt-3 price-txt63">
                    Solar & Battery Detailed Assessment (e.g. demand charge;
                    Feed-in-tariff, battery grid charging, battery needs)
                  </p>
                  <p className="mt-3 price-txt63">
                    Recommendations for Up to 5 Solar & Battery Installation
                    Systems
                  </p>
                  <p className="mt-3 price-txt63">
                    Personalized Energy Usage Profile Analysis
                  </p>
                  <p className="mt-3 price-txt63">
                    Intelligent Derivation of Charges Based on Location
                  </p>
                  <p className="mt-3 price-txt63">
                    Government Rebates Based on Location
                  </p>
                  <p className="mt-3 price-txt63">
                    Comprehensive Metrics Comparison (over 20)
                  </p>
                  <p className="mt-3 price-txt63">
                    Detailed Report and Excel Downloadables
                  </p>
                </div>
              </div>
              <div className="pricing-flexChild34">
                <h2 className="priceHeading66p">Enterprise Solution</h2>
                <p className="price-txt63 ">
                  For consultants and companies with multiple sites (over 5) or
                  have specific requirements
                </p>
                <button
                  className="button34-price pricebtn-color2"
                  onClick={() => navigate("/contact-us")}
                >
                  Contact us
                </button>

                <div className="mt-3"></div>
              </div>
            </div>
          </div>
        </>
      )}
      {tab == 2 && (
        <div className="pricingSection2">
          <img src={image1} alt="img" />
          <Link to="/dashboard">
            <button className="priceBtn6t6 pricebtn33">
              Leave your feedback, your opinion is really importnat to us!
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Pricing;
