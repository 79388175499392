import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SetForgotPasswordAction } from "../Redux/actions/AuthAction";
import { validations } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

const ResetPassword = () => {
  const { token, uid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.authReducer);
  const { success, message, error, setpass } = useSelector(
    (state) => state.SetForgotPasswordAction
  );
  const [oldpassword, setOldPassword] = useState();
  const [rerender, setRerender] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [cNewpassword, setCnewPassword] = useState();
  const [errors, setErrors] = useState({
    newPassword: "",
    cNewpassword: "",
  });
  const handleUpdateSubmit = async () => {
    // navigate("/dashboard");
    const formData = new FormData();
    formData.append("password", newPassword);
    formData.append("confirm_password", cNewpassword);
    formData.append("email", userData?.user?.email);
    setRerender(true);
    dispatch(SetForgotPasswordAction(formData, token, uid));
  };
  useEffect(() => {
    if (success && rerender) {
      swal({
        title: " ",
        text: message || "success",
        className: "successAlert",
        icon: "/img/logo.svg",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
      navigate("/");
    }
    if (error && rerender) {
      swal({
        title: "Error",
        text: error || "something went wrong",
        className: "errorAlert",
        icon: "/img/logo.svg",
        buttons: false,
        timer: 2000,
      });
      setRerender(false);
    }
  }, [success, message, rerender, error]);
  const validateSubmit = (e) => {
    e.preventDefault();
    const tempErrors = {
      newPassword: validations.password(newPassword),
      cNewpassword: validations.confirmPassword(newPassword, cNewpassword),
    };
    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    handleUpdateSubmit();
  };

  return (
    <div className="login">
      <div className="login_sec">
        <div className="login-logoimg">
          <img src="/img/logo.svg" />
        </div>
        <form>
          <div className="login-input">
            <div className="loginuser">
              <input
                type="Password"
                placeholder="Password"
                name="uname"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setErrors({ ...errors, newPassword: null });
                }}
              />
              <span
                style={{
                  color: "red",
                  fontSize: "15px",
                  opacity: errors.newPassword ? 1 : 0,
                }}
              >
                {errors.newPassword ?? "valid"}
              </span>
            </div>
            <div className="loginuser">
              <input
                type="password"
                onChange={(e) => {
                  setCnewPassword(e.target.value);
                  setErrors({ ...errors, cNewpassword: null });
                }}
                placeholder="Confirm Password"
                name="psw"
              />
              <span
                style={{
                  color: "red",
                  fontSize: "15px",
                  opacity: errors.cNewpassword ? 1 : 0,
                }}
              >
                {errors.cNewpassword ?? "valid"}
              </span>
            </div>

            <button
              className="loginbtn"
              onClick={(e) => validateSubmit(e)}
              type="submit"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
