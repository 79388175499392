import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo2.svg";
import image1 from "../assets/images/imgnew.png";
import image2 from "../assets/images/img-1.png";
import image3 from "../assets/images/img-2.png";
import image4 from "../assets/images/img-3.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PlanInputPostData } from "../Redux/actions/PublicActions";
import Swal from "sweetalert2";

const PlanInput = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [render, setrender] = useState(false);
  const { data: tabRespose, error: errorTab } = useSelector(
    (state) => state.PlanInputPostReducer
  );

  useEffect(() => {
    if (errorTab && render) {
      Swal.fire({
        text: errorTab,
        icon: "error",
        confirmButtonText: "Got it!",
      });
      setrender(false);
    }
  }, [errorTab]);

  useEffect(() => {
    if (tabRespose && render) {
      setrender(false);
      Swal.fire({
        title: "Submit",
        text: "Form Submitted",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
      setTimeout(() => {
        navigate("/premium-output");
      }, [3000]);
    }
  }, [tabRespose]);

  const [renewableEnergy, setrenewableEnergy] = useState(false);
  const [victoriaCompanies, setvictoriaCompanies] = useState(false);
  const [estimateDemand, setestimateDemand] = useState(false);
  const [supportBattery, setsupportBattery] = useState(false);
  const [estimateFeed, setestimateFeed] = useState(false);
  const [energyUsage, setenergyUsage] = useState(5);

  const handleRangeChange = (e) => {
    setenergyUsage(e.target.value);
  };

  const handleConfirm = async () => {
    const userID = JSON.parse(localStorage.getItem("userData"));
    const id = localStorage.getItem("id");

    const data = new FormData();
    data.append("user", userID?.user?.user_id);
    data.append("id", id);
    data.append("ren_cert", renewableEnergy);
    data.append("veecs_use", victoriaCompanies);
    data.append("demand_charge", estimateDemand);
    data.append("grid_charge", supportBattery);
    data.append("fit_rate", estimateFeed);
    data.append("increase_energy", energyUsage);
    setrender(true);
    await dispatch(PlanInputPostData(data));
  };
  return (
    <div className="planInput-containeryy">
      <div className="basicEnergy-section1 ">
        <p className="basicEnergy-para11 mb-0">
          Realise the benefits of adopting solar & battery with a simple 4 steps
          estimation
        </p>
      </div>
      <div className="divFeasib7546">
        <div className="marginBDiv">
          <p className="plantext958">
            Consider Renewable Energy Certificates as Revenue (e.g. LGCs and
            VEECs)
          </p>
          <div className="d-flex">
            <div className="d-flex">
              <input
                type="radio"
                name="group1"
                id="value1"
                checked={renewableEnergy && "True"}
                onChange={() => setrenewableEnergy(true)}
              />
              <label className="planLabel" for="value1">
                Yes
              </label>
            </div>
            <div className="d-flex">
              <input
                type="radio"
                name="group1"
                id="value2"
                checked={!renewableEnergy && "True"}
                onChange={() => setrenewableEnergy(false)}
              />
              <label className="planLabel" for="value2">
                No
              </label>
            </div>
          </div>
        </div>

        <div className="marginBDiv">
          <p className="plantext958">
            For Victoria Companies, would you prefer VEECs
          </p>
          <div className="d-flex">
            <div className="d-flex">
              <input
                type="radio"
                name="group5"
                id="value5"
                checked={victoriaCompanies && "True"}
                onChange={() => setvictoriaCompanies(true)}
              />
              <label className="planLabel" for="value5">
                Yes
              </label>
            </div>
            <div className="d-flex">
              <input
                type="radio"
                name="group5"
                id="value7"
                checked={!victoriaCompanies && "True"}
                onChange={() => setvictoriaCompanies(false)}
              />
              <label className="planLabel" for="value7">
                No
              </label>
            </div>
          </div>
        </div>

        <div className="marginBDiv">
          <p className="plantext958">Estimate Demand Charge</p>
          <div className="d-flex">
            <div className="d-flex">
              <input
                type="radio"
                name="group2"
                id="value3"
                checked={estimateDemand && "True"}
                onChange={() => setestimateDemand(true)}
              />
              <label className="planLabel" for="value3">
                Yes
              </label>
            </div>
            <div className="d-flex">
              <input
                type="radio"
                name="group2"
                id="value4"
                checked={!estimateDemand && "True"}
                onChange={() => setestimateDemand(false)}
              />
              <label className="planLabel" for="value4">
                No
              </label>
            </div>
          </div>
        </div>
        <div className="marginBDiv">
          <p className="plantext958">Support Battery Charging From the Grid</p>
          <div className="d-flex">
            <div className="d-flex">
              <input
                type="radio"
                name="group3"
                id="value5"
                checked={supportBattery && "True"}
                onChange={() => setsupportBattery(true)}
              />
              <label className="planLabel" for="value5">
                Yes
              </label>
            </div>
            <div className="d-flex">
              <input
                type="radio"
                name="group3"
                id="value6"
                checked={!supportBattery && "True"}
                onChange={() => setsupportBattery(false)}
              />
              <label className="planLabel" for="value6">
                No
              </label>
            </div>
          </div>
        </div>
        <div className="marginBDiv">
          <p className="plantext958">Estimate Feed in Tarrif</p>
          <div className="d-flex">
            <div className="d-flex">
              <input
                type="radio"
                name="group4"
                id="value7"
                checked={estimateFeed && "True"}
                onChange={() => setestimateFeed(true)}
              />
              <label className="planLabel" for="value7">
                Yes
              </label>
            </div>
            <div className="d-flex">
              <input
                type="radio"
                name="group4"
                id="value8"
                checked={!estimateFeed && "True"}
                onChange={() => setestimateFeed(false)}
              />
              <label className="planLabel" for="value8">
                No
              </label>
            </div>
          </div>
        </div>
        <div class="marginBDiv position-relative">
          <label class="plantext958">
            Estimated Increase in Annual Energy Usage
          </label>
          <br />
          <div className="d-flex">
            <input
              type="range"
              className="rangeCls238 me-2"
              min="0"
              max="100"
              value={energyUsage}
              onChange={handleRangeChange}
              style={{
                background: `linear-gradient(to right, green ${energyUsage}%, #ccc ${energyUsage}%)`,
              }}
            />
            {energyUsage}
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <button className="planbtn6uu" onClick={handleConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanInput;
