import React, { useState, useEffect, useRef } from "react";
import { CanvasJSChart } from "canvasjs-react-charts";
import { Loader } from "../Loader";

const Cleanest = ({ loadingData, outputData }) => {
  const [graphDataOne, setGraphDataOne] = useState({});
  const [graphDataTwo, setGraphDataTwo] = useState({});
  const [loading, setIsLoading] = useState(false);

  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);

  const tableDataProps = outputData?.cleanest;

  useEffect(() => {
    try {
      if (outputData) {
        let chartData = [];
        let chartData2 = [];
        const myData1 = outputData?.cleanest_graph1?.graph1 || {};
        const myData2 = outputData?.cleanest_graph2 || {};

        if (myData1) {
          const yearMonthData = outputData?.cleanest_graph1?.year_month || [];
          const formattedDates = yearMonthData;

          chartData = [
            {
              type: "stackedArea",
              name: "Battery to Grid",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.cleanest_graph1?.battery_to_grid?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
            {
              type: "stackedArea",
              name: "Grid kW",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.cleanest_graph1?.grid_kw?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
            {
              type: "stackedArea",
              name: "Renewable kW",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.cleanest_graph1?.renewable_kw?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
            {
              type: "stackedArea",
              name: "Solar to Grid",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.cleanest_graph1?.solar_to_grid?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
          ];
          if (
            chartData.some(
              (item) => item.dataPoints && item.dataPoints.length > 0
            )
          ) {
            setGraphDataOne(chartData);
          }
        }
        if (myData2) {
          const yearMonthData =
            outputData?.cleanest_graph2?.new_date_time || [];
          const formattedDates = yearMonthData;

          chartData2 = [
            {
              type: "stackedArea",
              name: "Grid kW",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.cleanest_graph2?.grid_kw?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
            {
              type: "stackedArea",
              name: "Battery to Grid",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.cleanest_graph2?.battery_to_grid?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
            {
              type: "stackedArea",
              name: "Renewable kW",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.cleanest_graph2?.renewable_kw?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
            {
              type: "stackedArea",
              name: "Solar to Grid",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.cleanest_graph2?.solar_to_grid?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
          ];
          if (
            chartData2.some(
              (item) => item.dataPoints && item.dataPoints.length > 0
            )
          ) {
            setGraphDataTwo(chartData2);
          }
        }
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      console.log("Something went wrong while getting data", error);
      setIsLoading(false);
    }
  }, [outputData]);

  useEffect(() => {
    if (graphDataOne) {
      toggleGraph1();
    }
  }, [graphDataOne]);

  useEffect(() => {
    if (graphDataTwo) {
      toggleGraph2();
    }
  }, [graphDataTwo]);

  const toggleGraph1 = () => {
    // Get the chart from the ref
    const chart = chartRef1.current;

    // Check if the chart exists before accessing its properties
    if (chart) {
      // Access the chart properties and perform the desired action
      const legend = chart.container.getElementsByClassName(
        "canvasjs-chart-legend"
      )[0];

      if (legend) {
        const legendItems = legend.getElementsByTagName("li");

        if (legendItems.length > 0) {
          const event = new MouseEvent("dblclick", {
            bubbles: true,
            cancelable: true,
            view: window,
          });
          legendItems[0].dispatchEvent(event);
        }
      }
    }
  };

  const toggleGraph2 = () => {
    // Check if the chartRef has been assigned
    if (chartRef2.current) {
      // Access the chart component's API
      const chart = chartRef2.current.chart;

      // Toggle the visibility of the first data series in the graphDataOne
      if (chart.options.data[0]) {
        chart.options.data[0].visible = !chart.options.data[0].visible;

        // Render the updated chart
        // chart.render();
      }
      chartRef2.current.chart = chart;
    }
  };

  const toggleDataSeries = (e) => {
    const prevData = [...graphDataOne];
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    setGraphDataOne(prevData);
  };

  const toggleDataSeries2 = (e) => {
    const prevData = [...graphDataTwo];
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    setGraphDataTwo(prevData);
  };

  const options1 = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    zoomEnabled: true,
    panEnabled: true,
    axisY: {
      title: "Energy Used",
    },
    axisX: {
      title: "Month",
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    data: graphDataOne,
  };

  const options2 = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    zoomEnabled: true,
    panEnabled: true,
    axisY: {
      title: "Energy Used",
    },
    axisX: {
      title: "Month",
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries2,
    },
    data: graphDataTwo,
  };

  const commasAfterThreeDigits = (value) => {
    if (typeof value === "string") {
      return value;
    }
    if (value === 0) {
      return "0";
    } else if (!isNaN(value)) {
      return value.toLocaleString();
    } else {
      return "N/A";
    }
  };

  return (
    <>
      {loadingData || loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="Outputarea output1">
            <div className="accordianBox">
              <div className="accordion" id="myAccordion-cleanest-one">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo-cleanest-one">
                    <button
                      type="button"
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Graph-cleanest-one"
                    >
                      Graphical Summary
                    </button>
                  </h2>
                  <div
                    id="Graph-cleanest-one"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#myAccordion-cleanest-one"
                  >
                    <div className="chartBox">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="Perhour">
                            {graphDataOne && (
                              <>
                                <h2>Daily Energy Use </h2>
                                <CanvasJSChart
                                  options={options1}
                                  ref={chartRef1}
                                  // ref={chartRef1}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-5 mb-5">
                          <div className="Perhour">
                            {graphDataTwo && (
                              <>
                                <h2>Average Hourly Energy Use </h2>
                                <CanvasJSChart
                                  options={options2}
                                  ref={chartRef2}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordianBox">
              <div className="accordion" id="myAccordion-cleanest-two">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo-cleanest-two">
                    <button
                      type="button"
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Solar-cleanest-two"
                    >
                      Result comparison
                    </button>
                  </h2>
                  <div
                    id="Solar-cleanest-two"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#myAccordion-cleanest-two"
                  >
                    <div className="card-body">
                      <div className="resultTable">
                        {tableDataProps ? (
                          <table className="table table-borderless">
                            <thead>
                              <tr>
                                <th>Information</th>
                                <th>Business As Usual</th>
                                <th>Cleanest Case</th>
                                <th>Difference</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={6} className="borderBottom">
                                  System Size
                                </td>
                              </tr>
                              <tr>
                                <td>PV Size</td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][0])}{" "}
                                  kW
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][0])}{" "}
                                  kW
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][0])}{" "}
                                  kW
                                </td>
                              </tr>
                              <tr className="mp-5">
                                <td>Battery Capacity</td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][1])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][1])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][1])}{" "}
                                  kWh
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={6} className="borderBottom">
                                  Energy Production
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Average Annual PV Energy Production to Site
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][2])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][2])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][2])}{" "}
                                  kWh
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Average Annual Energy Supplied from Grid
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][3])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][3])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][3])}{" "}
                                  kWh
                                </td>
                              </tr>
                              <tr>
                                <td>Average Annual Energy Supplied from PPA</td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][4])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][4])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][4])}{" "}
                                  kWh
                                </td>
                              </tr>
                              <tr>
                                <td>Average Annual Clean Energy Supplied </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][5])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][5])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][5])}{" "}
                                  kWh
                                </td>
                              </tr>
                              {/* <tr>
                                <td colSpan={6} className="borderBottom">
                                  Renewable Energy Metrics
                                </td>
                              </tr>
                              <tr>
                                <td>Annual Renewable Electricity</td>
                                <td>{tableDataProps[0][6]}%</td>
                                <td>{tableDataProps[1][6]}%</td>
                                <td>{tableDataProps[2][6]}%</td>
                              </tr> */}
                              <tr>
                                <td colSpan={6} className="borderBottom">
                                  Carbon Footprint
                                </td>
                              </tr>
                              <tr>
                                <td>CO₂ Emissions in Year 1 </td>
                                <td>{tableDataProps[0][7]} tons</td>
                                <td>{tableDataProps[1][7]} tons</td>
                                <td>{tableDataProps[2][7]} tons</td>
                              </tr>
                              <tr>
                                <td>Percent Reduction in CO₂ Emissions</td>
                                <td>{tableDataProps[0][8]}%</td>
                                <td>{tableDataProps[1][8]}%</td>
                                <td>{tableDataProps[2][8]}%</td>
                              </tr>
                              <tr>
                                <td>
                                  Total CO₂ Emissions in the transistion period{" "}
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][9])}{" "}
                                  tons
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][9])}{" "}
                                  tons
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][9])}{" "}
                                  tons
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={6} className="borderBottom">
                                  Year 1 Utility Electricity Cost — Before Tax
                                </td>
                              </tr>
                              <tr>
                                <td>Utility Energy Cost </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][10]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][10]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][10]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Utility Demand Cost </td>
                                <td>${tableDataProps[0][11]}</td>
                                <td>${tableDataProps[1][11]}</td>
                                <td>${tableDataProps[2][11]}</td>
                              </tr>
                              <tr>
                                <td>Utility Fixed Cost </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][12]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][12]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][12]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Total Year 1 Utility Cost - Before Tax</td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][13]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][13]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][13]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={6} className="borderBottom">
                                  Life Cycle Cost Breakdown
                                </td>
                              </tr>
                              <tr>
                                <td>Technology Capital Costs </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][14]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][14]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][14]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>O&M Costs </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][15]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][15]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][15]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Total Utility Electricity Cost (Transition){" "}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][16]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][16]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][16]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Total Utility Electricity Cost </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][17]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][17]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][17]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Total Life Cycle Cost </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][18]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][18]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][18]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={6} className="borderBottom">
                                  Summary Financial Metrics
                                </td>
                              </tr>
                              <tr>
                                <td>Net Present Value </td>
                                <td>
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][21]
                                  )}{" "}
                                  years
                                </td>
                                <td>
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][21]
                                  )}{" "}
                                  years
                                </td>
                                <td>
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][21]
                                  )}{" "}
                                  years
                                </td>
                              </tr>
                              <tr>
                                <td>Payback Period </td>
                                <td>{tableDataProps[0][19]} years</td>
                                <td>{tableDataProps[1][19]} years</td>
                                <td>{tableDataProps[2][19]} years</td>
                              </tr>
                              <tr>
                                <td>Discounted Payback Period </td>
                                <td>${tableDataProps[0][20]}</td>
                                <td>${tableDataProps[1][20]}</td>
                                <td>${tableDataProps[2][20]}</td>
                              </tr>
                              <tr>
                                <td>Internal Rate of Return </td>
                                <td>{tableDataProps[0][22]}%</td>
                                <td>{tableDataProps[1][22]}%</td>
                                <td>{tableDataProps[2][22]}%</td>
                              </tr>
                            </tbody>
                          </table>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cleanest;
