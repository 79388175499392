import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { Frontend_URL, BACKEND_URL } from "../environment";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BasicOutputGetData,
  BasicOutputEmptyReducer,
} from "../Redux/actions/PublicActions";
import { Loader } from "../components/Loader";
import Plot from "react-plotly.js";
import { saveAs } from "file-saver";

const PremiumOutput = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, GetData } = useSelector((state) => state.BasicOutputGetReducer);


  const uniqueSolarSizes = [...new Set(GetData?.graph1?.solar_size)];
  const uniqueSolarSizesTwo = [...new Set(GetData?.graph2?.solar_size)];
  const uniqueCateory = [...new Set(GetData?.graph3?.Category)];
  const [solarSize, setsolarSize] = useState("");
  const [solarSize2, setsolarSize2] = useState("");
  const [category, setcategory] = useState("");
  const [option1, setoption1] = useState("");
  const [option2, setoption2] = useState("");
  const [option3, setoption3] = useState("");
  const [reportFile, setReportFile] = useState();
  const [pdfFile, setPdfFile] = useState("");

  useEffect(() => {
    if (GetData) {
      console.log("GetData>>",GetData)
      setsolarSize(GetData?.graph1?.solar_size[0]);
      setsolarSize2(GetData?.graph2?.solar_size[0]);
      setcategory(GetData?.graph3?.Category[0]);
    }
  }, [GetData]);

  useEffect(() => {
    // alert("form submitted")
    const formSubmitted = localStorage.getItem('formSubmitted');
    if (formSubmitted) {
      Swal.fire({
        title: formSubmitted === "StripeForm"
          ? 'Stripe form Submitted!' 
          : formSubmitted === "BillingDetails" 
          ? 'Billing form Submitted!' 
          : "Form Submitted!",
        text: formSubmitted === 'StripeForm'
          ? 'Your Stripe form submission has been received.'
          : formSubmitted === 'BillingDetails'
            ? 'Your billing details have been received.'
            : 'Your submission has been received.',
        icon: 'success',
        timer: 2000,
        timerProgressBar: true,
        willClose: () => {
          // Clear the flag from localStorage
          localStorage.removeItem('formSubmitted');
        }
      });
    }
  }, []);


  const id = parseInt(localStorage.getItem("id"));

  const fetchdata = async () => {
    await dispatch(BasicOutputEmptyReducer());

    const userID = JSON.parse(localStorage.getItem("userData"));
    const data = new FormData();
   
    data.append("user", userID?.user?.user_id);

    if (id) {
      await dispatch(BasicOutputGetData(id, data));                                                                                                                                                                                                                                                                               
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);

  useEffect(() => {
    if (GetData) {
      console.log("GetData.pdf_file>>", GetData.pdf_file);
  
      // Encode the entire URL, which will handle spaces
      const encodedPdfFile = encodeURI(GetData.pdf_file);
  
      setReportFile(GetData?.file_path);
      setPdfFile(encodedPdfFile);
    }
  }, [GetData]);
  
  

  useEffect(() => {
    if (GetData) {
      const chartData = [
        {
          name: "Excess Solar Power",
          fill: "tozeroy",
          type: "scatter",
          marker: { color: "#1c5739" },
          x: GetData?.graph1?.date_time,
          y: GetData?.graph1?.excess_solar_power.filter(
            (_, i) => GetData?.graph1?.solar_size[i] === solarSize
          ),
        },
        {
          name: "Grid Power",
          fill: "tozeroy",
          type: "scatter",
          marker: { color: "#4e97df" },
          x: GetData?.graph1?.date_time,
          y: GetData?.graph1?.grid_power.filter(
            (_, i) => GetData?.graph1?.solar_size[i] === solarSize
          ),
        },
        {
          name: "Solar Rooftop",
          fill: "tozeroy",
          type: "scatter",
          marker: { color: "#14f188" },
          x: GetData?.graph1?.date_time,
          y: GetData?.graph1?.solar_rooftop.filter(
            (_, i) => GetData?.graph1?.solar_size[i] === solarSize
          ),
        },
        {
          name: "Battery",
          type: "scatter",
          mode: "lines",
          fill: "tozeroy",
          marker: { color: "#f7a423" },
          x: GetData?.graph1?.date_time,
          y: GetData?.graph1?.Battery.filter(
            (_, i) => GetData?.graph1?.solar_size[i] === solarSize
          ),
        },
      ];
      setoption1(chartData);
    }
  }, [solarSize]);

  const chartStyle = {
    width: "100%",
  };
  const layout1 = {
    displayModeBar: true,
    xaxis: {
      title: "Date",
      rangeselector: {
        buttons: [
          {
            count: 12,
            label: "12h",
            step: "hour",
            stepmode: "backward",
          },
          {
            count: 1,
            label: "1d",
            step: "day",
            stepmode: "backward",
          },
          {
            count: 3,
            label: "3d",
            step: "day",
            stepmode: "backward",
          },
          {
            count: 7,
            label: "7d",
            step: "day",
            stepmode: "backward",
          },
          {
            count: 14,
            label: "2w",
            step: "day",
            stepmode: "backward",
          },
          {
            count: 1,
            label: "1m",
            step: "month",
            stepmode: "backward",
          },
          {
            count: 6,
            label: "6m",
            step: "month",
            stepmode: "backward",
          },
          {
            step: "all",
          },
        ],
      },
      rangeslider: {},
      type: "date",
    },
    yaxis: {
      title: {
        text: "Energy Used (kWh)",
        standoff: 20,
      },
      hoverformat: ",.0f",
    },
    hovermode: "x unified",
    fixedrange: true,
  };
  const formatValue = (value) => {
    if (value >= 1e3) {
      return Math.round(value / 1e3) + "k";
    } else {
      return Math.round(value);
    }
  };

  useEffect(() => {
    if (GetData) {
      const chartData = [
        // {
        //   name: "Peak Cost",
        //   fill: "tozeroy",
        //   type: "bar",
        //   marker: { color: "#4e97df" },
        //   x: GetData?.graph2?.years,
        //   y: GetData?.graph2?.peak_cost.filter(
        //     (_, i) => GetData?.graph2?.solar_size[i] === solarSize2
        //   ),
        // },
        {
          name: "Energy Cost",
          fill: "tozeroy",
          type: "bar",
          marker: { color: "#1c5739" },
          x: GetData?.graph2?.years,
          y: GetData?.graph2?.system_grid_cost.filter(
            (_, i) => GetData?.graph2?.solar_size[i] === solarSize2
          ),
        },
        // {
        //   name: "Shoulder Cost",
        //   type: "bar",
        //   mode: "lines",
        //   fill: "tozeroy",
        //   marker: { color: "#f7a423" },
        //   x: GetData?.graph2?.years,
        //   y: GetData?.graph2?.shoulder_cost.filter(
        //     (_, i) => GetData?.graph2?.solar_size[i] === solarSize2
        //   ),
        // },
        {
          name: "Demand Charge",
          fill: "tozeroy",
          type: "bar",
          marker: { color: "#14f188" },
          x: GetData?.graph2?.years,
          y: GetData?.graph2?.system_demand_charge.filter(
            (_, i) => GetData?.graph2?.solar_size[i] === solarSize2
          ),
        },
        {
          name: "Delivery Charge",
          fill: "tozeroy",
          type: "bar",
          marker: { color: "#1d508d" },
          x: GetData?.graph2?.years,
          y: GetData?.graph2?.default_delivery_charge.filter(
            (_, i) => GetData?.graph2?.solar_size[i] === solarSize2
          ),
        },
        {
          name: "Default Cost",
          type: "scatter",
          marker: { color: "#1cffec" },
          x: GetData?.graph2?.years,
          y: GetData?.graph2?.default_grid_energy_cost.filter(
            (_, i) => GetData?.graph2?.solar_size[i] === solarSize2
          ),
        },
        {
          name: "Total",
          type: "scatter",
          mode: "text",
          x: GetData?.graph2?.years,
          text: (GetData?.graph2?.years || [])
            .map((_, i) => {
              if (GetData?.graph2?.solar_size[i] === solarSize2) {
                return formatValue(
                  GetData?.graph2?.default_delivery_charge[i] +
                  GetData?.graph2?.system_demand_charge[i] +
                  // GetData?.graph2?.shoulder_cost[i] +
                  GetData?.graph2?.system_grid_cost[i]
                  // GetData?.graph2?.peak_cost[i]
                );
              } else {
                return null;
              }
            })
            .filter((index) => index !== null),
          textposition: "top",
          marker: { color: "blue" },
          showlegend: false,
          hoverinfo: "skip",
          textfont: {
            size: 10,
            color: "White",
          },
        },
      ];
      setoption2(chartData);
    }
  }, [solarSize2]);

  const layout2 = {
    xaxis: {
      title: "Year",
    },
    yaxis: {
      title: {
        text: "Electricity Price ($)",
        standoff: 20,
      },
      hoverformat: ",.0f",
    },
    barmode: "stack",
    hovermode: "x unified",
  };
  
  useEffect(() => {
    if (GetData && category) {
      const chartData = [
        {
          type: "bar",
          marker: { color: "#5a6871" },
          x: GetData?.graph3?.solar_size.filter(
            (_, i) => GetData?.graph3?.Category[i] === category
          ),
          y: GetData?.graph3?.value.filter(
            (_, i) => GetData?.graph3?.Category[i] === category
          ),
        },
      ];
      setoption3(chartData);
    }
  }, [category]);

  const layout3 = {
    xaxis: {
      title: "Solar System Size",
      type: "category",
    },
    hovermode: "x unified",
    yaxis: {
      hoverformat: ",.0f",
    },
  };

  const commasAfterThreeDigits = (value) => {
    if (typeof value === "string") {
      return value;
    }
    if (value === 0) {
      return "0";
    } else if (!isNaN(value)) {
      return value.toLocaleString();
    } else {
      return "N/A";
    }
  };
  const MapComponent = ({
    lat = -37.8179789,
    lon = 144.9690576,
    zoom = 19,
    size = "1200x300",
    maptype = "satellite",
  }) => {
    const [mapImageUrl, setMapImageUrl] = useState("");
    const markerColor = "red";
    const markerSize = "mid";
    const markerLabel = "";

    useEffect(() => {
      const getMapImage = async () => {
        try {
          const apiKey = "AIzaSyCBxQbrVABSO6ZpjQ3TR1be7zJRPay_xzk";
          const marker = `&markers=color:${markerColor}|size:${markerSize}|label:${markerLabel}|${lat},${lon}`;
          const url = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lon}&zoom=${zoom}&size=${size}&maptype=${maptype}&key=${apiKey}${marker}`;
          const response = await fetch(url);
          if (response.ok) {
            setMapImageUrl(url);
          } else {
            console.error("Failed to fetch map image");
          }
        } catch (error) {
          console.error("Error fetching map image", error);
        }
      };

      getMapImage();
    }, [lat, lon, zoom, size, maptype]);

    return <div>{mapImageUrl && <img src={mapImageUrl} alt="Map" />}</div>;
  };
  const handleReport = async () => {
    try {
      const response = await axios.post(
        `${BACKEND_URL}api/v1/common/download-excel/`,
        {
          file_path: reportFile,
        },
        {
          responseType: "blob",
        }
      );
      saveAs(
        response.data,
        reportFile.split("/")[1].split("_")[1].split(".")[0] +
        "_spreadsheet.xlsx"
      );
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handlePrintReport = () => {
    window.open(pdfFile, "_blank");
  };

  const handleNextStep = () => {
    localStorage.setItem("PremiumOuputid", id);
    localStorage.removeItem("FreeOuputid");
    navigate("/connection");
  };

  const handleSurvey = () => {
    window.open(`${Frontend_URL}survey`, "_blank");
  };
  return (
    <div className="basicOutput-container">
      {loading ? (
        <div style={{ marginTop: "10rem" }}>
          <Loader text="Calculating your personalised renewable energy needs" />
        </div>
      ) : (
        <div className="freeOutput-margin">
          <h2 className="solarHeading">
            Welcome To Your Personalised Solar Estimate!
          </h2>
          <p className="mb-0 text-center">Address: {GetData?.address}</p>
          <p className="text-center">NMI number: {GetData?.nmi_num}</p>
          <div className="d-flex justify-content-center mapDivHeight">
            <MapComponent lat={GetData?.lat} lon={GetData?.long} />
          </div>
          <div className="mt-5">
            <h2 className="solarHeading mb-3">
              Here are your custom-tailored estimates, just for you!
            </h2>
            <p className="freeout-disclaimerDiv">
              <b>Disclaimer</b>: The provided financial and technical data are an estimate and may vary
              based on specific requirements that may not have been fully
              captured. This tool is for feasibility assessment and should not
              be solely relied upon. Consultation with a professional installer
              is strongly advised.
            </p>
            <div className="planout-flex6">
              <div>
                <p className="optionText">Option 1</p>
                <div className="planout-flexChild6">
                  <p className="text656y">
                    Solar({GetData?.solar_size_0 ? GetData?.solar_size_0 : 0}{" "}
                    kW){" "}
                    {GetData?.battrey_0 > 0 &&
                      `+ Battery(
                    ${GetData?.battrey_0} kWh)`}
                  </p>
                  <div className="line444"></div>
                  <div className="div656y">
                    <p className="text65kk">System Cost</p>
                    <p className="txet8ij">
                      ${" "}
                      {GetData?.system_cost_0
                        ? commasAfterThreeDigits(
                          Math.trunc(GetData?.system_cost_0)
                        )
                        : 0}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">
                      First-year electricity bill with solar
                    </p>
                    <p className="txet8ij">
                      ${" "}
                      {GetData?.electriciy_bill_0
                        ? commasAfterThreeDigits(
                          Math.trunc(GetData?.electriciy_bill_0)
                        )
                        : 0}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">First-year bill saving</p>
                    <div className="divhgh78">
                      <p className="txet8ij txet8ij11">
                        ${" "}
                        {GetData?.value_display_0
                          ? commasAfterThreeDigits(
                            Math.trunc(GetData?.value_display_0)
                          )
                          : 0}
                      </p>
                      <p className="txet8ij txet8ij11">
                        {GetData?.bill_saving_0
                          ? Math.trunc(GetData?.bill_saving_0)
                          : 0}{" "}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">Payback period</p>
                    <p className="txet8ij">
                      {GetData?.playback_period_0
                        ? `${parseFloat(GetData?.playback_period_0)?.toFixed(
                          1
                        )} years`
                        : "nan - 1"}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">
                      Carbon Abated in tonnes of CO<sub>2</sub>
                    </p>
                    <div className="divhgh78">
                      <p className="txet8ij txet8ij11 ">
                        {GetData?.carbon_offset_0
                          ? GetData?.carbon_offset_0
                          : 0}{" "}
                        tCO<sub>2</sub>
                      </p>
                      <p className="txet8ij txet8ij11 ">
                        {GetData?.carbon_emission_0
                          ? GetData?.carbon_emission_0
                          : 0}{" "}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="div656y">
                    <p className="text65kk"> Annual Return on Investment (%)</p>
                    <p className="txet8ij">
                      {GetData?.ROI_0 ? GetData?.ROI_0 : 0} %
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <p className="optionText">Option 2</p>
                <div className="planout-flexChild6">
                  <p className="text656y">
                    Solar({GetData?.solar_size_1 ? GetData?.solar_size_1 : 0}{" "}
                    kW){" "}
                    {GetData?.battrey_1 > 0 &&
                      `+ Battery(
                    ${GetData?.battrey_1} kWh)`}
                  </p>
                  <div className="line444"></div>
                  <div className="div656y">
                    <p className="text65kk">System Cost</p>

                    <p className="txet8ij">
                      ${" "}
                      {GetData?.system_cost_1
                        ? commasAfterThreeDigits(
                          Math.trunc(GetData?.system_cost_1)
                        )
                        : 0}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">
                      First-year electricity bill with solar
                    </p>
                    <p className="txet8ij">
                      ${" "}
                      {GetData?.electriciy_bill_1
                        ? commasAfterThreeDigits(
                          Math.trunc(GetData?.electriciy_bill_1)
                        )
                        : 0}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">First-year bill saving</p>
                    <div className="divhgh78">
                      <p className="txet8ij txet8ij11">
                        ${" "}
                        {GetData?.value_display_1
                          ? commasAfterThreeDigits(
                            Math.trunc(GetData?.value_display_1)
                          )
                          : 0}
                      </p>
                      <p className="txet8ij txet8ij11">
                        {GetData?.bill_saving_1
                          ? Math.trunc(GetData?.bill_saving_1)
                          : 0}{" "}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">Payback period</p>
                    <p className="txet8ij">
                      {GetData?.playback_period_1
                        ? `${parseFloat(GetData?.playback_period_1)?.toFixed(
                          1
                        )} years`
                        : "nan - 1"}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">
                      Carbon Abated in tonnes of CO<sub>2</sub>
                    </p>
                    <div className="divhgh78">
                      <p className="txet8ij txet8ij11 ">
                        {GetData?.carbon_offset_1
                          ? GetData?.carbon_offset_1
                          : 0}{" "}
                        tCO<sub>2</sub>
                      </p>
                      <p className="txet8ij txet8ij11 ">
                        {GetData?.carbon_emission_1
                          ? GetData?.carbon_emission_1
                          : 0}{" "}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="div656y">
                    <p className="text65kk"> Annual Return on Investment (%)</p>
                    <p className="txet8ij">
                      {GetData?.ROI_1 ? GetData?.ROI_1 : 0} %
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <p className="optionText">Option 3</p>
                <div className="planout-flexChild6">
                  <p className="text656y">
                    Solar({GetData?.solar_size_2 ? GetData?.solar_size_2 : 0}{" "}
                    kW){" "}
                    {GetData?.battrey_2 > 0 &&
                      `+ Battery(
                    ${GetData?.battrey_2} kWh)`}
                  </p>
                  <div className="line444"></div>
                  <div className="div656y">
                    <p className="text65kk">System Cost</p>
                    <p className="txet8ij">
                      ${" "}
                      {GetData?.system_cost_2
                        ? commasAfterThreeDigits(
                          Math.trunc(GetData?.system_cost_2)
                        )
                        : 0}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">
                      First-year electricity bill with solar
                    </p>

                    <p className="txet8ij">
                      ${" "}
                      {GetData?.electriciy_bill_2
                        ? commasAfterThreeDigits(
                          Math.trunc(GetData?.electriciy_bill_2)
                        )
                        : 0}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">First-year bill saving</p>
                    <div className="divhgh78">
                      <p className="txet8ij txet8ij11">
                        ${" "}
                        {GetData?.value_display_2
                          ? commasAfterThreeDigits(
                            Math.trunc(GetData?.value_display_2)
                          )
                          : 0}
                      </p>
                      <p className="txet8ij txet8ij11">
                        {GetData?.bill_saving_2
                          ? Math.trunc(GetData?.bill_saving_2)
                          : 0}{" "}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">Payback period</p>
                    <p className="txet8ij">
                      {GetData?.playback_period_2
                        ? `${parseFloat(GetData?.playback_period_2)?.toFixed(
                          1
                        )} years`
                        : "nan - 1"}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">
                      Carbon Abated in tonnes of CO<sub>2</sub>
                    </p>
                    <div className="divhgh78">
                      <p className="txet8ij txet8ij11 ">
                        {GetData?.carbon_offset_2
                          ? GetData?.carbon_offset_2
                          : 0}{" "}
                        tCO<sub>2</sub>
                      </p>
                      <p className="txet8ij txet8ij11 ">
                        {GetData?.carbon_emission_2
                          ? GetData?.carbon_emission_2
                          : 0}{" "}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="div656y">
                    <p className="text65kk"> Annual Return on Investment (%)</p>
                    <p className="txet8ij">
                      {GetData?.ROI_2 ? GetData?.ROI_2 : 0} %
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <p className="optionText">Option 4</p>
                <div className="planout-flexChild6">
                  <p className="text656y">
                    Solar({GetData?.solar_size_3 ? GetData?.solar_size_3 : 0}{" "}
                    kW){" "}
                    {GetData?.battrey_3 > 0 &&
                      `+ Battery(
                    ${GetData?.battrey_3} kWh)`}
                  </p>
                  <div className="line444"></div>
                  <div className="div656y">
                    <p className="text65kk">System Cost</p>
                    <p className="txet8ij">
                      ${" "}
                      {GetData?.system_cost_3
                        ? commasAfterThreeDigits(
                          Math.trunc(GetData?.system_cost_3)
                        )
                        : 0}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">
                      First-year electricity bill with solar
                    </p>
                    <p className="txet8ij">
                      ${" "}
                      {GetData?.electriciy_bill_3
                        ? commasAfterThreeDigits(
                          Math.trunc(GetData?.electriciy_bill_3)
                        )
                        : 0}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">First-year bill saving</p>

                    <div className="divhgh78">
                      <p className="txet8ij txet8ij11">
                        ${" "}
                        {GetData?.value_display_3
                          ? commasAfterThreeDigits(
                            Math.trunc(GetData?.value_display_3)
                          )
                          : 0}
                      </p>
                      <p className="txet8ij txet8ij11">
                        {GetData?.bill_saving_3
                          ? Math.trunc(GetData?.bill_saving_3)
                          : 0}{" "}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">Payback period</p>
                    <p className="txet8ij">
                      {GetData?.playback_period_3
                        ? `${parseFloat(GetData?.playback_period_3)?.toFixed(
                          1
                        )} years`
                        : "nan - 1"}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">
                      Carbon Abated in tonnes of CO<sub>2</sub>
                    </p>
                    <div className="divhgh78">
                      <p className="txet8ij txet8ij11 ">
                        {GetData?.carbon_offset_3
                          ? GetData?.carbon_offset_3
                          : 0}{" "}
                        tCO<sub>2</sub>
                      </p>
                      <p className="txet8ij txet8ij11 ">
                        {GetData?.carbon_emission_0
                          ? GetData?.carbon_emission_0
                          : 0}{" "}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="div656y">
                    <p className="text65kk"> Annual Return on Investment (%)</p>
                    <p className="txet8ij">
                      {GetData?.ROI_3 ? GetData?.ROI_3 : 0} %
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <p className="optionText">Option 5</p>
                <div className="planout-flexChild6">
                  <p className="text656y">
                    Solar({GetData?.solar_size_4 ? GetData?.solar_size_4 : 0}{" "}
                    kW){" "}
                    {GetData?.battrey_4 > 0 &&
                      `+ Battery(
                    ${GetData?.battrey_4} kWh)`}
                  </p>
                  <div className="line444"></div>
                  <div className="div656y">
                    <p className="text65kk">System Cost</p>
                    <p className="txet8ij">
                      ${" "}
                      {GetData?.system_cost_4
                        ? commasAfterThreeDigits(
                          Math.trunc(GetData?.system_cost_4)
                        )
                        : 0}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">
                      First-year electricity bill with solar
                    </p>

                    <p className="txet8ij">
                      ${" "}
                      {GetData?.electriciy_bill_4
                        ? commasAfterThreeDigits(
                          Math.trunc(GetData?.electriciy_bill_4)
                        )
                        : 0}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">First-year bill saving</p>

                    <div className="divhgh78">
                      <p className="txet8ij txet8ij11">
                        ${" "}
                        {GetData?.value_display_4
                          ? commasAfterThreeDigits(
                            Math.trunc(GetData?.value_display_4)
                          )
                          : 0}
                      </p>
                      <p className="txet8ij txet8ij11">
                        {GetData?.bill_saving_4
                          ? Math.trunc(GetData?.bill_saving_4)
                          : 0}{" "}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">Payback period</p>
                    <p className="txet8ij">
                      {GetData?.playback_period_4
                        ? `${parseFloat(GetData?.playback_period_4)?.toFixed(
                          1
                        )} years`
                        : "nan - 1"}
                    </p>
                  </div>
                  <div className="div656y">
                    <p className="text65kk">
                      Carbon Abated in tonnes of CO<sub>2</sub>
                    </p>
                    <div className="divhgh78">
                      <p className="txet8ij txet8ij11 ">
                        {GetData?.carbon_offset_4
                          ? GetData?.carbon_offset_4
                          : 0}{" "}
                        tCO<sub>2</sub>
                      </p>
                      <p className="txet8ij txet8ij11 ">
                        {GetData?.carbon_emission_4
                          ? GetData?.carbon_emission_4
                          : 0}{" "}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="div656y">
                    <p className="text65kk"> Annual Return on Investment (%)</p>
                    <p className="txet8ij">
                      {GetData?.ROI_4 ? GetData?.ROI_4 : 0} %
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordianBox">
            <div className="accordion" id="myAccordion4">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    type="button"
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Solar4"
                  >
                    <h2 className="heading55 mb-0 ms-1">
                      Summarised System Comparison
                    </h2>
                  </button>
                </h2>
                <div
                  id="Solar4"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#myAccordion4"
                >
                  <div className="padding666cls">
                    <div className="graphwidth11">
                      <p className="freeoutput-mappara">
                        Explore and compare various options by clicking on the
                        metric dropdown to toggle between different metrics. See
                        what best fits your needs!
                      </p>
                      <select
                        className="select7y"
                        value={category}
                        onChange={(e) => setcategory(e.target.value)}
                      >
                        {uniqueCateory?.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <div>
                        <Plot
                          data={option3}
                          layout={layout3}
                          style={chartStyle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordianBox">
            <div className="accordion" id="myAccordion2">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    type="button"
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Solar2"
                  >
                    <h2 className="heading55 mb-0 ms-1">
                      Energy Source & Usage Breakdown
                    </h2>
                  </button>
                </h2>
                <div
                  id="Solar2"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#myAccordion2"
                >
                  <div className="padding666cls">
                    <div className="graphwidth11">
                      <p className="freeoutput-mappara">
                        Explore and compare various options by clicking on the
                        metric dropdown to toggle between different metrics. See
                        what best fits your needs!
                      </p>
                      <select
                        className="select7y"
                        value={solarSize}
                        onChange={(e) => setsolarSize(parseInt(e.target.value))}
                      >
                        {uniqueSolarSizes?.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <p className="mb-0">
                        Energy Consumption Chart Based on Sources for Solar
                        System {solarSize} kW
                      </p>
                      <div>
                        <Plot
                          data={option1}
                          layout={layout1}
                          style={chartStyle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordianBox">
            <div className="accordion" id="myAccordion3">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    type="button"
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Solar3"
                  >
                    <h2 className="heading55 mb-0 ms-1">
                      Energy Cost Breakdown
                    </h2>
                  </button>
                </h2>
                <div
                  id="Solar3"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#myAccordion3"
                >
                  <div className="padding666cls">
                    <div className="graphwidth11">
                      <p className="freeoutput-mappara">
                        Explore and compare various options by clicking on the
                        metric dropdown to toggle between different metrics. See
                        what best fits your needs!
                      </p>
                      <select
                        className="select7y"
                        value={solarSize2}
                        onChange={(e) =>
                          setsolarSize2(parseInt(e.target.value))
                        }
                      >
                        {uniqueSolarSizesTwo?.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <p className="mb-0">
                        Annual Electricity Chart for Solar System {solarSize2}{" "}
                        kW
                      </p>
                      <div>
                        <Plot
                          data={option2}
                          layout={layout2}
                          style={chartStyle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="freeout-disclaimerDiv mt-5">
            <b> Kind Request</b>: We kindly request your participation in our
            survey, designed to refine and enhance our product. To ensure your
            convenience and prevent loss of data, the survey opens in a new tab.
            As our product is currently complimentary, your feedback is
            invaluable to us. Thank you for sharing your insights!
          </p>
          <div className="planOutput333">
            <button className="planoutputBTn55" onClick={handleReport}>
              Download Spreadsheet
            </button>
            <button className="planoutputBTn55" onClick={handlePrintReport}>
              Print Report
            </button>
            <button className="planoutputBTn55" onClick={handleSurvey}>
              Survey
            </button>
            <button className="planoutputBTn55" onClick={handleNextStep}>
              Next Step
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PremiumOutput;
