

export const BACKEND_URL = "https://natureelectric.energy:81/";
export const Frontend_URL = "https://natureelectric.energy/";



// export const BACKEND_URL = "http://40.90.248.160:8000/";
// export const Frontend_URL = "http://40.90.248.160:3000/";



