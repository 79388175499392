import React from 'react';
import {Link} from 'react-router-dom'
import { PAGE_URLS } from "../../constants";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "3rem",
      }}
    >
      <h2>404</h2>
      <h5>Page Not Found</h5>
      <Link to={PAGE_URLS.DASHBOARD}>Go to Home Page</Link>
    </div>
  );
};

export default NotFound;
