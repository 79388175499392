import React, { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import { saveAs } from "file-saver";

const Glossary = () => {
  const [openCollapse1, setOpenCollapse1] = useState(false);
  const [openCollapse2, setOpenCollapse2] = useState(false);
  const [openCollapse3, setOpenCollapse3] = useState(false);
  const [openCollapse4, setOpenCollapse4] = useState(false);
  const [openCollapse5, setOpenCollapse5] = useState(false);
  const [openCollapse6, setOpenCollapse6] = useState(false);
  const [openCollapse7, setOpenCollapse7] = useState(false);
  const [openCollapse8, setOpenCollapse8] = useState(false);
  const [openCollapse9, setOpenCollapse9] = useState(false);
  const [openCollapse10, setOpenCollapse10] = useState(false);
  const [openCollapse11, setOpenCollapse11] = useState(false);
  const [openCollapse12, setOpenCollapse12] = useState(false);
  const [openCollapse13, setOpenCollapse13] = useState(false);
  const [openCollapse14, setOpenCollapse14] = useState(false);
  const [openCollapse15, setOpenCollapse15] = useState(false);
  const [openCollapse16, setOpenCollapse16] = useState(false);
  const [openCollapse17, setOpenCollapse17] = useState(false);
  const [openCollapse18, setOpenCollapse18] = useState(false);
  const [openCollapse19, setOpenCollapse19] = useState(false);

  const [loadMore, setloadMore] = useState(false);
  const [scrollToDiv, setscrollToDiv] = useState(null);

  useEffect(() => {
    const tab9 = localStorage.getItem("tab9");
    if (tab9) {
      setloadMore(true);
      setscrollToDiv(9);
    }
    const tab2_1 = localStorage.getItem("tab2_1");
    if (tab2_1) {
      setloadMore(true);
      setscrollToDiv(2.1);
    }
    const tab2_2 = localStorage.getItem("tab2_2");
    if (tab2_2) {
      setloadMore(true);
      setscrollToDiv(2.2);
    }
  }, []);

  useEffect(() => {
    if (loadMore && scrollToDiv == 9) {
      const element = document.getElementById("div11");
      setTimeout(() => {
        if (element) {
          setOpenCollapse11(true);
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 400);

      setTimeout(() => {
        localStorage.removeItem("tab9");
      }, 2000);
    }
  }, [loadMore]);

  useEffect(() => {
    if (loadMore && scrollToDiv == 2.1) {
      const element = document.getElementById("div15");
      setTimeout(() => {
        if (element) {
          setOpenCollapse15(true);
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 400);

      setTimeout(() => {
        localStorage.removeItem("tab2_1");
      }, 2000);
    }
  }, [loadMore]);

  useEffect(() => {
    if (loadMore && scrollToDiv == 2.2) {
      const element = document.getElementById("div16");
      setTimeout(() => {
        if (element) {
          setOpenCollapse16(true);
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 400);

      setTimeout(() => {
        localStorage.removeItem("tab2_2");
      }, 2000);
    }
  }, [loadMore]);

  const handleSampleDownload = () => {
    const fileUrl = "/FileSample.xlsx";
    saveAs(fileUrl, fileUrl);
  };

  return (
    <div className="glossary-conatiner">
      <div className="glossary-section1">
        <p className="glossary-para11 mb-0">
          Below you'll find answers to the most common questions on Nature
          Electric. Also, please contact us if you would like a further
          discussion.
        </p>
      </div>

      <div className="faq-section2 glosssary-marginTop">
        <div className="faq-section2-flex">
          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse1(!openCollapse1)}
            >
              <span> Energy Contract</span>
              {openCollapse1 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse1}>
              <div>
                <p className="faq-answerSection">
                  An energy contract is a legal agreement between two parties,
                  typically between a consumer and an energy supplier. This
                  contract outlines the terms and conditions under which the
                  energy will be supplied and consumed.
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse2(!openCollapse2)}
            >
              <span> Power Purchase Agreement </span>
              {openCollapse2 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse2}>
              <div>
                <p className="faq-answerSection">
                  A Solar Power Purchase Agreement (PPA) is a financial
                  arrangement specifically for the procurement of electricity
                  generated by a solar photovoltaic (PV) system. In this
                  agreement, a third-party developer installs, owns, and
                  operates the solar system, typically on a customer's property,
                  and the customer agrees to purchase the power produced by the
                  system for a set period. This arrangement allows customers to
                  use solar energy without having to bear the high upfront costs
                  of installation and maintenance.
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse3(!openCollapse3)}
            >
              <span>Payback Period</span>
              {openCollapse3 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse3}>
              <div>
                <p className="faq-answerSection">
                  The payback period is the length of time needed for an
                  investment to "pay back" its initial cost out of the savings
                  it generates. It is typically calculated by dividing the
                  initial investment cost by the annual savings. For instance,
                  if a solar panel system costs $10,000 and saves $2,500 per
                  year in energy costs, the payback period would be $10,000 /
                  $2,500 = 4 years.
                </p>
              </div>
            </Collapse>
          </div>
          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse4(!openCollapse4)}
            >
              <span> CO2 Abatement </span>
              {openCollapse4 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse4}>
              <div>
                <p className="faq-answerSection">
                  CO2 abatement, in the context of using solar power, refers to
                  the reduction of carbon dioxide (CO2) emissions achieved by
                  replacing traditional fossil fuel-based energy sources with
                  solar energy. The process of generating electricity from solar
                  panels does not produce CO2, which is a significant greenhouse
                  gas contributing to climate change.
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse5(!openCollapse5)}
            >
              <span> Demand charge</span>
              {openCollapse5 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse5}>
              <div>
                <p className="faq-answerSection">
                  A demand charge is a fee that a utility charges based on the
                  maximum amount of electrical power that a customer consumes
                  during a specified period. It is often measured in kilowatts
                  (kW)/kVA.
                </p>
              </div>
            </Collapse>
          </div>

          <Collapse in={loadMore}>
            <div>
              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse17(!openCollapse17)}
                >
                  <span>Peak Charges</span>
                  {openCollapse17 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse17}>
                  <div>
                    <div className="faq-answerSection">
                      Peak charges include both the peak energy and network
                      charges. Sometimes, you'll see them as a single value on
                      your bill; other times, the network charges might not be
                      listed separately.
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse18(!openCollapse18)}
                >
                  <span>Shoulder Charges</span>
                  {openCollapse18 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse18}>
                  <div>
                    <div className="faq-answerSection">
                      Shoulder charges include both the shoulder energy and
                      network charges. They might appear as a single value on
                      your bill, or the network charges might not be detailed
                      separately.
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="collapse-section" id="div11">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse11(!openCollapse11)}
                >
                  <span>Difference between String and Micro Inverters </span>
                  {openCollapse11 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse11}>
                  <div>
                    <div className="faq-answerSection">
                      <table className=" table table-bordered">
                        <thead>
                          <tr>
                            <th></th>
                            <th>String Inverters</th>
                            <th>Micro Inverters</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Number of Units</td>
                            <td>
                              One or multiple big inverter for multiple solar
                              panels.
                            </td>
                            <td>One small inverter for each solar panel.</td>
                          </tr>
                          <tr>
                            <td>Cost</td>
                            <td>Generally cheaper upfront.</td>
                            <td>
                              Usually more expensive upfront due to individual
                              inverters.
                            </td>
                          </tr>
                          <tr>
                            <td>Maintenance</td>
                            <td>
                              Easier to maintain, as there are fewer components.
                            </td>
                            <td>
                              More components to maintain, potentially more
                              complex.
                            </td>
                          </tr>
                          <tr>
                            <td>Efficiency</td>
                            <td>
                              Efficiency may be slightly lower if one panel is
                              shaded or underperforming, as it affects the whole
                              string.
                            </td>
                            <td>
                              Better performance, as each panel operates
                              independently, even if one is shaded or not
                              performing well.
                            </td>
                          </tr>
                          <tr>
                            <td>Monitoring</td>
                            <td>Limited individual panel monitoring.</td>
                            <td>
                              Enhanced monitoring with insights into each
                              panel's performance.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse12(!openCollapse12)}
                >
                  <span>Energy Demand Shaving </span>
                  {openCollapse12 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse12}>
                  <div>
                    <div className="faq-answerSection">
                      Energy demand shaving, often referred to as peak shaving,
                      is a strategy used by energy consumers, particularly in
                      commercial and industrial sectors, to reduce their
                      electricity costs. It involves reducing electricity usage
                      during peak demand periods when energy costs are highest.
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse13(!openCollapse13)}
                >
                  <span>
                    What does it mean to replace inverter with micro inverter?
                  </span>
                  {openCollapse13 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse13}>
                  <div>
                    <p className="faq-answerSection">
                      While microinverters generally have a higher upfront cost
                      compared to traditional inverters, this can be offset by
                      their longer lifespan, reduced maintenance costs, and
                      potentially higher energy production over time.
                    </p>
                  </div>
                </Collapse>
              </div>
            </div>
          </Collapse>
        </div>

        <div className="faq-section2-flex">
          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse6(!openCollapse6)}
            >
              <span>Excess Power</span>
              {openCollapse6 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse6}>
              <div>
                <p className="faq-answerSection">
                  Excess solar power refers to the additional electricity
                  generated by a solar photovoltaic (PV) system that is not
                  consumed immediately on-site where it's produced. This power
                  can be sent back to the grid or wasted.
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse7(!openCollapse7)}
            >
              <span>Energy Independence</span>
              {openCollapse7 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse7}>
              <div>
                <p className="faq-answerSection">
                  Energy independence achieved through the adoption of solar
                  panels refers to the reduced reliance on external or
                  traditional energy sources, like the electrical grid or fossil
                  fuels. By generating one's own electricity using solar power,
                  individuals, businesses, and communities can gain greater
                  control over their energy usage, costs, and environmental
                  impact.
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse8(!openCollapse8)}
            >
              <span> Flat Rate</span>
              {openCollapse8 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse8}>
              <div>
                <p className="faq-answerSection">
                  A flat rate in the context of an electricity bill refers to a
                  pricing structure where the customer pays a fixed charge for
                  electricity regardless of how much is used. This is in
                  contrast to a variable rate, where the cost depends on the
                  amount of electricity consumed.
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse9(!openCollapse9)}
            >
              <span>Delivery/Supply Charge</span>
              {openCollapse9 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse9}>
              <div>
                <div className="faq-answerSection">
                  This charge shows up differently on your bill. Sometimes, it's
                  called a delivery charge; other times, it's split into parts.
                  Here's what it includes:
                  <ul>
                    <li>
                      Metering charge: This is for tracking how much energy you
                      use.
                    </li>
                    <li>
                      Network fixed/access/standing charge: This covers the
                      energy network cost.
                    </li>
                    <li>
                      Retail charge: This is what your energy retailer charges
                      you for their services.
                    </li>
                    <li>
                      These charges are added up daily and can very depending on
                      which retailer you're with. For instance, if you have a
                      meter charge of 120 cents and a standing charge of 80
                      cents, then the delivery charge would be 200 cents
                      (120+80).
                    </li>
                  </ul>
                </div>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse10(!openCollapse10)}
            >
              <span> Feed In Tariff</span>
              {openCollapse10 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse10}>
              <div>
                <p className="faq-answerSection">
                  Under a feed-in tariff, energy producers are paid a set rate
                  for the electricity they generate and feed into the grid.
                </p>
              </div>
            </Collapse>
          </div>

          <Collapse in={loadMore}>
            <div>
              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse19(!openCollapse19)}
                >
                  <span>Off-Peak Charges</span>
                  {openCollapse19 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse19}>
                  <div>
                    <div className="faq-answerSection">
                      Off-peak charges also include both the off-peak energy and
                      network charges. Like peak charges, they might be shown as
                      a single value, or the network charges might not be
                      itemized.
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse14(!openCollapse14)}
                >
                  <span> Annual Return On Investment</span>
                  {openCollapse14 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse14}>
                  <div>
                    <div className="faq-answerSection">
                      Annual ROI calculates the percentage increase or decrease
                      in the value of an investment over a year. It considers
                      the gains from the investment compared to its initial
                      cost. <br />
                      Annual Return on Investment: (annual saving/initial
                      investment) *100. For example, if you invest $10,000 and
                      saves $2,500 per year in energy costs, the annual return
                      on investment would be ($2,500/$10,000)*100 = 25%
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="collapse-section" id="div15">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse15(!openCollapse15)}
                >
                  <span>Requesting NEM12 Data </span>
                  {openCollapse15 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse15}>
                  <div>
                    <div className="faq-answerSection">
                      <p>Electricity Distributor.</p>
                      <p>
                        In identifying your electricity distributor (or DNSP),
                        it is usually found on your bill at the ‘Faults &
                        Emergencies’ section. A general guide on electricity
                        providers by state can be found on the{" "}
                        <a
                          href="https://www.aer.gov.au/consumers/understanding-energy/who-your-distributor"
                          target="_blank"
                        >
                          AER website.
                        </a>
                      </p>
                      <p>
                        To obtain your NEM12 data for the previous 12 months,
                        contact your electricity distributor through the
                        provided channels below.
                      </p>
                      <table className=" table table-bordered">
                        <thead>
                          <tr>
                            <th>State</th>
                            <th>Electricity distributor</th>
                            <th>Request Point</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>ACT</td>
                            <td>EvoEnergy</td>
                            <td>
                              <a
                                href="https://www.evoenergy.com.au/residents/request-for-consumption-records-data"
                                target="_blank"
                              >
                                Customer Portal
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>ACT</td>
                            <td>Essential Energy</td>
                            <td>
                              {" "}
                              <a
                                href="https://www.essentialenergy.com.au/web-forms/retail-customer-single-nmi-request"
                                target="_blank"
                              >
                                Meter data request form
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>NSW</td>
                            <td>Endeavour Energy</td>
                            <td>
                              <a
                                href="https://www.endeavourenergy.com.au/your-energy/your-electricity-meter/how-to-read-your-meter/customer-access-data"
                                target="_blank"
                              >
                                Meter data request form
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>NSW</td>
                            <td>Essential Energy</td>
                            <td>
                              <a
                                href="https://www.essentialenergy.com.au/web-forms/retail-customer-single-nmi-request"
                                target="_blank"
                              >
                                Meter data request form
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>NSW</td>
                            <td>Ausgrid</td>
                            <td>
                              <a
                                href="https://www.ausgrid.com.au/Your-energy-use/Meters/Access-your-meter-data"
                                target="_blank"
                              >
                                Meter data request form
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>NT</td>
                            <td>Power and Water Corporation</td>
                            <td>
                              {" "}
                              <p className="d-flex gap-1">
                                Email{" "}
                                <a href="mailto:customerservice@powerwater.com.au,">
                                  {" "}
                                  customerservice@powerwater.com.au,
                                </a>
                              </p>
                              <p>call 1800 245 092</p>
                            </td>
                          </tr>
                          <tr>
                            <td>QLD</td>
                            <td>Energex</td>
                            <td>
                              <a
                                href="https://www.energex.com.au/contact-us/forms/metering-data-request-form"
                                target="_blank"
                              >
                                Meter data request form
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>QLD</td>
                            <td>Ergon</td>
                            <td>
                              <a
                                href="https://www.ergon.com.au/network/contact-us/forms/metering-data-request-form"
                                target="_blank"
                              >
                                Meter data request form
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>SA</td>
                            <td>SA Power Networks</td>
                            <td>
                              <a
                                href="https://customer.portal.sapowernetworks.com.au/meterdata/CADRegoPage"
                                target="_blank"
                              >
                                Customer portal
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>TAS</td>
                            <td>Aurora</td>
                            <td>
                              <a
                                href="https://www.auroraenergy.com.au/residential/contact/general-enquiries-form"
                                target="_blank"
                              >
                                Contact form,
                              </a>{" "}
                              call 1300 132 003
                            </td>
                          </tr>{" "}
                          <tr>
                            <td>VIC</td>
                            <td>Ausnet</td>
                            <td>
                              <a
                                href="https://meterdata.ausnetservices.com.au/webui/#/"
                                target="_blank"
                              >
                                Meter data request form
                              </a>
                            </td>
                          </tr>{" "}
                          <tr>
                            <td>VIC</td>
                            <td>Citipower</td>
                            <td>
                              <a
                                href="https://myenergy.powercor.com.au/s/login/?ec=302&startURL=%2Fs%2F"
                                target="_blank"
                              >
                                Customer portal,
                              </a>{" "}
                              call 1300 301 101
                            </td>
                          </tr>{" "}
                          <tr>
                            <td>VIC</td>
                            <td>Jemena</td>
                            <td>
                              <a
                                href="https://electricityoutlook.jemena.com.au/register/index"
                                target="_blank"
                              >
                                Customer portal,
                              </a>{" "}
                              call 1300 131 871
                            </td>
                          </tr>{" "}
                          <tr>
                            <td>VIC</td>
                            <td>PowerCor</td>
                            <td>
                              <a
                                href="https://myenergy.powercor.com.au/s/login/?ec=302&startURL=%2Fs%2F"
                                target="_blank"
                              >
                                Customer portal,
                              </a>{" "}
                              call 13 22 06
                            </td>
                          </tr>{" "}
                          <tr>
                            <td>VIC</td>
                            <td>United Energy</td>
                            <td>
                              <a
                                href="https://myenergy.ue.com.au/s/login/?ec=302&startURL=%2Fs%2F"
                                target="_blank"
                              >
                                Customer portal,
                              </a>{" "}
                              or{" "}
                              <a
                                href="https://media.unitedenergy.com.au/factsheets/UE_CustomerInformationRequest_Form.pdf"
                                target="_blank"
                              >
                                Meter data request form
                              </a>
                            </td>
                          </tr>{" "}
                          <tr>
                            <td>WA</td>
                            <td>Western Power</td>
                            <td>Call 13 10 87</td>
                          </tr>{" "}
                          <tr>
                            <td>WA</td>
                            <td>Horizon Power</td>
                            <td>Call 1800 267 926</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="collapse-section" id="div16">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse16(!openCollapse16)}
                >
                  <span>Energy data upload</span>
                  {openCollapse16 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse16}>
                  <div className="faq-answerSection">
                    <p>
                      Uploading your energy data to Nature Electric is
                      straightforward and simple. When uploading your data, the
                      system is good at handling different time intervals,
                      making sure everything matches up nicely by using
                      30-minute intervals. If there's any missing information,
                      it tries to fill in the gaps either with similar data or
                      just leaves it empty. It's best to upload a full year's
                      worth of data, which gets sorted into a calendar year
                      format for optimal recommendation. You have two options:
                      you can use the standard NEM12 format or a custom
                      formatted file. Here's what you need to know.
                    </p>

                    <p>
                      <b>NEM12 Format:</b>
                    </p>
                    <ul>
                      <li>
                        The NEM12 file follows the official AEMO specification.
                      </li>
                      <li>
                        Most files from electricity distributors are in the
                        NEM12 format for smart meter data.
                      </li>
                      <li>
                        You can download your data and upload it directly
                        without any modifications.{" "}
                        <span
                          className="basicEnergy-span3333t"
                          onClick={handleSampleDownload}
                        >
                          See a sample file
                        </span>
                      </li>
                    </ul>

                    <p>
                      <b>How NEM12 Files Work:</b>
                    </p>
                    <p>
                      The file consists of six main blocks, four of which are
                      mandatory and two are required.
                    </p>
                    <ol>
                      <li>
                        The 100 record marks the start of the file and is
                        mandatory.
                      </li>
                      <li>
                        The 200 record provides key details about the meter,
                        consumption, and data quality (mandatory). The NMI
                        suffix, starting with "E," represents energy consumed;
                        "B" represents energy generated, and "Q" represents
                        energy demand.
                      </li>
                      <li>
                        The 300 record contains interval data (mandatory).
                      </li>
                      <li>
                        The 400 and 500 records are required. The 400 is used to
                        record events, while the 500 is used in manual meter
                        reading.
                      </li>
                      <li>The 900 record marks the end of the data.</li>
                    </ol>

                    <p>
                      <b>Uploading Formatted Energy Data File:</b>
                    </p>
                    <p>
                      When preparing energy data for upload, it's crucial to
                      organize the information correctly. Here's a simple guide
                      to ensure everything is in the right place:
                    </p>
                    <ol>
                      <li>
                        <b>File Format:</b>Your energy data file should be in
                        Excel format, which means it should be either a .csv or
                        .xlsx file.
                      </li>
                      <li>
                        <b>Column Arrangement: </b>The data consists of four
                        mandatory columns and one optional column:
                        <ol type="a">
                          <li>
                            <b>Column 1</b>:This column should contain the
                            site's unique identification number, called the site
                            NMI.
                          </li>
                          <li>
                            <b>Column 2</b>:Here, you'll input the site's energy
                            usage. Make sure this column only contains numbers.
                          </li>
                          <li>
                            <b>Column 3 </b>:This column represents the site's
                            demand, measured in either kVA or reactive kVArh.
                            It's necessary to include this column, but if
                            there's no data for it, you can leave it empty or
                            fill it with zeroes. Just like the energy usage
                            column, it should only contain numbers.
                          </li>
                          <li>
                            <b>Column 4</b>:In this column, you'll input the
                            date and time of the energy usage. You can either
                            input the date alone (e.g., 11/01/2023) or both date
                            and time (e.g., 11/01/2023 10:00 AM). Various
                            date-time formats are accepted, so choose the one
                            that works best for you.
                          </li>
                          <li>
                            <b>Column 5</b>(Optional):This column is only
                            necessary when the preceding column contains the
                            date alone (without time). It represents the time of
                            the energy usage. You can input it in two formats:
                            either time only (e.g., 00:30) or both date and time
                            (e.g., 11/01/2023 10:00 AM).
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Data Format:</b>The first row of your data should be
                        the column titles (headers). Your data should consist
                        solely of energy data—no total rows or summary sections
                        should be included. Each row should represent a single
                        data entry for a specific site and time period.
                      </li>
                    </ol>
                    <p>
                      By following these steps, you'll ensure that your energy
                      data is properly formatted and ready for upload.{" "}
                      <span
                        className="basicEnergy-span3333t"
                        onClick={handleSampleDownload}
                      >
                        See a sample file
                      </span>
                    </p>
                  </div>
                </Collapse>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button className="faq-btn9" onClick={() => setloadMore(!loadMore)}>
          {loadMore ? "Load less" : "Load more"}
        </button>
      </div>
    </div>
  );
};

export default Glossary;
