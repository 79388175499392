import Spinner from "react-bootstrap/Spinner";

export function Loader({ text }) {
  return (
    // <Spinner
    //   animation="border"
    //   role="status"
    //   style={{
    //     height: "100px",
    //     width: "100px",
    //     margin: "auto",
    //     display: "block",
    //   }}
    // ></Spinner>
    <>
      <div className="loader" style={{height:'20vh',color:'#030303'}}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
        {text && <span style={{ fontWeight: 600 }}>{text}</span>}
      </div>
      <div className="spinner"></div>
    </>
  );
}
