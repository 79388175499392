import React, { createContext, useContext, useEffect, useState } from 'react';
import { surveyPageService } from '../services/survey.service';

// Create a context for managing the survey state
const SurveyContext = createContext();

// Create a custom hook to access the context
export function useSurvey() {
  return useContext(SurveyContext);
}

// Create a provider component to manage the survey state
export function SurveyProvider({ children }) {
  const [currentStep, setCurrentStep] = useState(0); // Initialize the current step
  const [surveyData, setSurveyData] = useState({});
  const [surveyAnswers, setSurveyAnswers] = useState({});

  const fetchSurveyQuestions = async () => {
    try {
      const results = await surveyPageService.getSurveyQuestions();
      setSurveyData(results || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSurveyQuestions();
  }, []);

  // Function to go to the next step
  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  // Function to go to the previous step
  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  // Function to save answers to the global state
  const saveAnswer = (questionId, answer) => {
    setSurveyAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  return (
    <SurveyContext.Provider
      value={{
        currentStep,
        nextStep,
        prevStep,
        surveyData,
        surveyAnswers,
        saveAnswer,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
}
