import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { VerifyEmailAction } from "../Redux/actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { validations } from "../utils";
import { Link } from "react-router-dom";
import swal from "sweetalert";
function VerifyEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { decodedID, tokenId } = useParams();
  const [render, setRerender] = useState(true);

  const { success, error, message } = useSelector(
    (state) => state.VerifyEmailReducer
  );
  const shouldLog = useRef(true);

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;
      dispatch(VerifyEmailAction(decodedID, tokenId));
      setRerender(true);
    }
  }, []);

  useEffect(() => {
    if (success && render) {
      swal({
        title: " Complete Successfully",
        text: message || "success",
        className: "successAlert",
        icon: "/img/logo.svg",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
    }

    if (error && render) {
      swal({
        title: "Error",
        text: error || "something went wrong",
        className: "errorAlert",
        icon: "/img/logo.svg",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
    }
  }, [success, error]);

  return (
    <div>
      <div className="forgotdiv">
        <div className="forgotsec">
          <div className="login-logoimg">
            <img src="/img/logo.svg" />
          </div>
          <div className="forgot">
            <div className="flex  justify-content-center">
              <h2>Email Verified </h2>
            </div>
            <div className="forgotbtn">
              <button className=" loginbtn" onClick={() => navigate("/")}>
                Login
              </button>
              <br />
              <Link className="cancel" to="/">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default VerifyEmail;
