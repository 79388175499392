import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { IconContext } from "react-icons";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/js/bootstrap.bundle";
import { SurveyProvider } from "./store/Store";
import { Provider } from "react-redux";
import store from "./Redux/store";
// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {" "}
    {/* <SurveyProvider> */}
      <IconContext.Provider value={{ className: "react-icons" }}>
        <ToastContainer
          style={{
            width: "300px",
            height: "auto",
            marginTop: "5%",
            marginLeft: "2%",
          }}
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <App />
      </IconContext.Provider>
    {/* </SurveyProvider> */}
  </Provider>

  // </React.StrictMode>
);
