import "./assets/styles/style.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { PAGE_URLS } from "./constants";
//components
import Header from "./components/Layout/Header";
import NotFound from "./components/NotFound";
import InputPage from "./pages/InputPage";
import OutputPage from "./pages/OutputPage";
import Dashbord from "./pages/Dashbord";
import OutputPageOne from "./pages/OutputPageOne";
import FreeEnergy from "./pages/FreeEnergy";
import PremiumEnergy from "./pages/PremiumEnergy";
import UpgradePlan from "./pages/UpgradePlan";
import Profile from "./pages/Profile";
import FreeOutput from "./pages/FreeOutput";
import FeasibilityTools from "./pages/FeasibilityTools";
import Pricing from "./pages/Pricing";
import PlanInput from "./pages/PlanInput";
import PremiumOutput from "./pages/PremiumOutput";
import Glossary from "./pages/Glossary";
import FAQPage from "./pages/FAQPage";
import MoreTrials from "./pages/MoreTrials";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import MoreTrialsContactUs from "./pages/MoreTrialsContactUs";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import SurveyForms from "./pages/SurveyForms";
import TermsAndCondition from "./pages/TermsAndCondition";
import Connections from "./pages/Connections";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import Forgot from "./Auth/Forgot";
import ResetPassword from "./Auth/ResetPassword";
import UpdatePassword from "./Auth/UpdatePassword";
import VerifyEmail from "./Auth/Verify-Email";
import ProtectedRoute from "./Routing/ProtectedRoute";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={PAGE_URLS.LOGIN_PAGE} element={<Login />} />
        <Route path={PAGE_URLS.REGISTER_PAGE} element={<Register />} />
        <Route path={PAGE_URLS.FORGOT_PAGE} element={<Forgot />} />
        <Route
          path={PAGE_URLS.RESETPASSWORD_PAGE}
          element={<ResetPassword />}
        />
        <Route
          path={PAGE_URLS.UPDATEPASSWORD_PAGE}
          element={
            <ProtectedRoute>
              <UpdatePassword />
            </ProtectedRoute>
          }
        />
        <Route path={PAGE_URLS.VERIFYEMAIL_PAGE} element={<VerifyEmail />} />

        <Route element={<Header />}>
          <Route
            exact
            path={PAGE_URLS.INPUT_PAGE}
            element={
              <ProtectedRoute>
                <InputPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={PAGE_URLS.OUTPUT_PAGE}
            element={
              <ProtectedRoute>
                <OutputPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={PAGE_URLS.INPUT_PAGE}
            element={<Navigate to={PAGE_URLS.INPUT_PAGE} replace />}
          />
          <Route
            exact
            path={PAGE_URLS.FEASIBILITY_PAGE}
            element={
              <ProtectedRoute>
                <FeasibilityTools />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path={PAGE_URLS.PROFILE_PAGE}
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route exact path={PAGE_URLS.DASHBOARD} element={<Dashbord />} />

          <Route
            exact
            path={PAGE_URLS.OUTPUT_PAGE_ONE}
            element={
              <ProtectedRoute>
                <OutputPageOne />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path={PAGE_URLS.PREMIUM_ENERGY}
            element={
              <ProtectedRoute>
                <PremiumEnergy />
              </ProtectedRoute>
            }
          />
          <Route
            path={PAGE_URLS.PREMIUMOUTPUT_PAGE}
            element={
              <ProtectedRoute>
                <PremiumOutput />
              </ProtectedRoute>
            }
          />
          <Route
            path={PAGE_URLS.SURVEY_FORMS}
            element={
              <ProtectedRoute>
                <SurveyForms />
              </ProtectedRoute>
            }
          />
          <Route path={PAGE_URLS.PRICING} element={<Pricing />} />
          <Route path={PAGE_URLS.PLANINPUT_PAGE} element={<PlanInput />} />
          <Route exact path={PAGE_URLS.GLOSSARY_PAGE} element={<Glossary />} />
          <Route exact path={PAGE_URLS.FAQ_PAGE} element={<FAQPage />} />
          <Route path={PAGE_URLS.CONTACTUS_PAGE} element={<ContactUs />} />
          <Route path={PAGE_URLS.ABOUTUS_PAGE} element={<AboutUs />} />
          <Route
            path={PAGE_URLS.TERMS_CONDITION}
            element={<TermsAndCondition />}
          />
          <Route
            exact
            path={PAGE_URLS.PRIVACY_POLICY}
            element={<PrivacyPolicy />}
          />
          <Route path={PAGE_URLS.CONNECTIONS_PAGE} element={<Connections />} />

          <Route exact path={PAGE_URLS.FREE_ENERGY} element={<FreeEnergy />} />
          <Route path={PAGE_URLS.FREEOUTPUT_PAGE} element={<FreeOutput />} />
          <Route exact path={PAGE_URLS.MORE_TRIALS} element={<MoreTrials />} />
          <Route
            exact
            path={PAGE_URLS.MORE_TRIALS_CONTACTUS}
            element={<MoreTrialsContactUs />}
          />

          <Route
            exact
            path={PAGE_URLS.UPGRADE_PLAN}
            element={<UpgradePlan />}
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
