import React, { useState, useEffect } from "react";
import StripePaymentWrapper from "./StripePayment"
import Swal from "sweetalert2";


const Payment = ({ submitHandlerSecond }) => {
  const [tab, setTabs] = useState(1);
  const [couponCode, setCouponCode] = useState("");
  const [isCouponValid, setIsCouponValid] = useState(false);

  const handleCouponChange = (e) => {
    const code = e.target.value;
    setCouponCode(code);
    if (code === "FREE100") {
      console.log("true")
      setIsCouponValid(true);
    } else {
      console.log("false")
      setIsCouponValid(false);
    }
  };

  const handleSubmit = async () => {
    try {
      await submitHandlerSecond();
      console.log('Form submitted');
      localStorage.setItem('formSubmitted', 'BillingDetails');
      console.log('Local storage set:', localStorage.getItem('formSubmitted'));
    } catch (error) {
      console.error("Submit handler failed:", error);
    }
  };
  

  return (
    <div className="paymentContainer">
      <div>
        <div className="payment-flex">
          <div className="payment-Flex11">
            <div>
              <h3 className="payment-heading8i ">Payment Overview</h3>
              <p className="paymentText-111 mt-3">Products</p>
              <div className="payment-Div66">
                <p className="paymentText-111 ">Premium</p>
                <p className="paymentText-111 mt-2">$400</p>
                <p className="textPayment444">
                  Unlock more detailed assessments and improve the return on
                  Investment by over 20%.
                </p>
              </div>

              <div>
                <p className="paymentText-111 ">Payment Details</p>
                <StripePaymentWrapper submitHandlerSecond={submitHandlerSecond} isCouponValid={isCouponValid} />
              </div>
            </div>
          </div>

          <div className="payment-Flex12">
            <div className="payment-div11">
              <h3 className="payment-heading8i">Billing Details</h3>
              <p className="payment-ytext323">
                Enter your payment details and complete your solar system order.
              </p>
              <div className="mb-3">
                <label className="feasibLabel mb-0">Email Address</label>
                <input
                  className="paymentInput5"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mb-3">
                <label className="feasibLabel mb-0">Full Name</label>
                <input
                  className="paymentInput5"
                  placeholder="Enter your name"
                />
              </div>
              <div className="mb-3">
                <label className="feasibLabel mb-0">Address</label>
                <input
                  className="paymentInput5"
                  placeholder="Enter your address"
                />
              </div>
              <div className="mb-3">
                <label className="feasibLabel mb-0">City</label>
                <input
                  className="paymentInput5"
                  placeholder="Enter your city"
                />
              </div>
              <div className="mb-3">
                <label className="feasibLabel mb-0">Postcode</label>
                <input
                  className="paymentInput5"
                  placeholder="Enter your postcode"
                />
              </div>
              <div className="mb-3">
                <label className="feasibLabel mb-0">Coupon code</label>
                <input
                  className="paymentInput5"
                  placeholder="Enter coupon code"
                  value={couponCode}
                  onChange={handleCouponChange}
                />
              </div>
              <div className="mb-3">
                <label className="feasibLabel mb-0">ABN</label>
                <input
                  className="paymentInput5"
                  placeholder="Enter your ABN"
                />
              </div>
              <button className="payment-btn77" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
