export const PAGE_URLS = {
  DASHBOARD: "/",
  LOGIN_PAGE: "/login",
  REGISTER_PAGE: "/register",
  FORGOT_PAGE: "/forgot-password",
  RESETPASSWORD_PAGE: "/reset-password/:token/:uid",
  UPDATEPASSWORD_PAGE: "/update-password",
  VERIFYEMAIL_PAGE: "/verify-email/:decodedID/:tokenId",
  INPUT_PAGE: "/input",
  OUTPUT_PAGE: "/output",
  FREEOUTPUT_PAGE: "/free-output",
  PRICING: "/pricing",
  PLANINPUT_PAGE: "/plan-input",
  PREMIUMOUTPUT_PAGE: "/premium-output",
  PROFILE_PAGE: "/profile",
  FEASIBILITY_PAGE: "/feasibility-tools",
  OUTPUT_PAGE_ONE: "/output-one",
  PREMIUM_ENERGY: "/premium-energy",
  FREE_ENERGY: "/free-energy",
  UPGRADE_PLAN: "/upgrade-plan",
  GLOSSARY_PAGE: "/glossary",
  FAQ_PAGE: "/faq",
  MORE_TRIALS: "/more-trials",
  MORE_TRIALS_CONTACTUS: "/more-trails-contactUs",
  CONTACTUS_PAGE: "/contact-us",
  PRIVACY_POLICY:"/privacy-policy",
  CONNECTIONS_PAGE:"/connection",
  ABOUTUS_PAGE: "/about-us",
  TERMS_CONDITION: "/terms-and-conditions",
  SURVEY_FORMS: "/survey",
};
