import { urls } from "../constants";
import { apiService } from "./api.service";

async function inputPage(value){
  return apiService.post(`${urls.INPUTPAGE}`,value);
}


export const inputPageService = {
    inputPage
}
