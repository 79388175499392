export const fullName = (value) => {
  if (!value) return "Name is required";

  if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
    return "Name only contain characters";

  return null;
};

export const firstName = (value) => {
  if (!value) return "First name is required";

  if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
    return "First Name can only contain characters ";

  return null;
};

export const lastName = (value) => {
  if (!value) return "Last name is required";

  if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
    return "Last Name can only contain characters ";

  return null;
};

export const username = (value) => {
  if (!value) return "Username is required";

  // if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
  //   return "Username can only contain characters";

  return null;
};

export const organization = (value) => {
  if (!value) return "Organization is required";

  if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
    return "Organization only contain characters";

  return null;
};

export const role = (value) => {
  if (!value) return "Account type is required";

  return null;
};
export const condition = (value) => {
  if (!value) return " Term & condition is required";

  return null;
};
export const phoneNumber = (value) => {
  if (!value) return "Phone number is required";

  return null;
};
export const email = (value) => {
  if (!value) return "Email is required";

  if (
    !value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  )
    return "Email is not valid";

  return null;
};
export const emailOrUsername = (value) => {
  if (!value) return "Email or username is required";

  // Regular expression for email validation
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Check if the input matches the email regex
  if (value.toLowerCase().match(emailRegex)) {
    return null; // It's a valid email
  } else {
    // You can define your own regex for username validation
    // For example, allow letters, numbers, and underscores for usernames
    const usernameRegex = /^[a-zA-Z0-9_]+$/;

    if (value.match(usernameRegex)) {
      return null; // It's a valid username
    } else {
      return "Email or username is not valid";
    }
  }
};
export const mobileNumber = (mobile) => {
  // const mobileRegex2 = /^(?:\+?1)?[2-9]\d{0,12}$/;
  // const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{9,}$/;

  const australiaRegex = /^\+61\s?\d{9}$/;
  const internationalRegex =
    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

  if (!mobile) {
    return "Phone Number is required";
  }
  if (!australiaRegex.test(mobile)) {
    return "Phone Number is not valid";
  }
  // if (!internationalRegex.test(mobile)) {
  //   return "Phone Number is not valid";
  // }

  return null;
};

export const telePhoneNumber = (mobile) => {
  const mobileRegex = /^123\d{8}$/;

  if (!mobile) {
    return "Phone Number is required";
  }

  if (!mobileRegex.test(mobile)) {
    return "Phone Number is not valid";
  }

  return null;
};

export const socialMediaLink = (value) => {
  if (value) return;

  if (
    !String(value).match(
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9]+\.[a-zA-Z]{2,}(\/\S*)?$/
    )
  )
    return "Please enter a valid social media URL";

  return null;
};
export const oldpassword = (value) => {
  if (!value) return "Password is required";

  return null;
};
export const password = (value) => {
  if (!value) return "Password is required";
  // if (value.length < 7) return "Atleast 7 characters required";
  return null;
};

export const confirmPassword = (password, confirmPassword) => {
  if (!password) return "Confirm Password is required";
  if (password !== confirmPassword) return "Passwords do not match";
  return null;
};

export const title = (value) => {
  if (!value) return "Title is required";

  // if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
  //   return "First Name can only contain characters";

  return null;
};
