import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import image1 from "../assets/images/Singup55Image.svg";
import image2 from "../assets/images/Logo3333Image.svg";
import { useDispatch, useSelector } from "react-redux";
import { ContactusPostData } from "../Redux/actions/PublicActions";
import swal from "sweetalert";
import { validations } from "../utils";

const ContactUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, error } = useSelector((state) => state.ContactusPostReducer);
  const [name, setName] = useState("");
  const [preferredWay, setPreferredWay] = useState("");
  const [preferredTime, setPreferredTime] = useState("");
  const [notes, setNotes] = useState("");
  const [render, setRender] = useState(false);
  // ---------------phone and email state---------------------
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({
    nameError: "",
    preferredWayError: "",
    emailError: "",
    phoneNumberError: "",
  });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);


  useEffect(() => {
    if (data && render) {
      swal({
        title: "",
        text: "Successfully Submit!",
        className: "successAlert",
        icon: "img/logo.svg",
        buttons: false,
        timer: 3000,
        open: true,
      });
      setName("");
      setNotes("");
      setPreferredTime("");
      setPreferredWay("");
      setRender(false);
      setTimeout(() => {
        navigate("/");
      }, [3000]);
    }

    if (error && render) {
      swal({
        title: "",
        text: error,
        className: "successAlert",
        icon: "img/logo.svg",
        buttons: false,
        timer: 3000,
        open: true,
      });
      setRender(false);
    }
  }, [data, error]);

  // const handleSubmit = () => {
  //   const tempErrors = {
  //     nameError: validations.fullName(name),
  //     preferredWayError: !preferredWay && "Required",
  //     emailError: validations.email(email),
  //     phoneNumberError:validations.mobileNumber(phone),

  //   };
  //   setErrors(tempErrors);
  //   if (Object.values(tempErrors).filter((value) => value).length) {
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("name", name);
  //   formData.append("preferred_way", preferredWay);
  //   formData.append("preferredTime", preferredTime);
  //   formData.append("notes", notes);
  //   setRender(true);
  //   dispatch(ContactusPostData(formData));
  // };

  const handleSubmit = () => {
    let tempErrors = {
      nameError: validations.fullName(name),
      preferredWayError: !preferredWay && "Required",
      emailError: "",
      phoneNumberError: "",
    };

    // Perform email validation if preferred way is email
    if (preferredWay === "email") {
      tempErrors.emailError = validations.email(email);
    }

    // Perform phone number validation if preferred way is phone
    if (preferredWay === "phone") {
      tempErrors.phoneNumberError = validations.mobileNumber(phone);
    }

    setErrors(tempErrors);

    // Check if there are any errors
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("preferred_way", preferredWay);
    formData.append("preferredTime", preferredTime);
    if (preferredWay === "email") {
      formData.append("preferred_value", email);
    }

    if (preferredWay === "phone") {
      formData.append("preferred_value", phone);
    }

    formData.append("notes", notes);

    setRender(true);
    dispatch(ContactusPostData(formData));
  };

  return (
    <div className="contactContainer">
      <div class="login-flex-div1">
        <img className="login-img122" src={image1} alt="img" />
        <div className="login-div12">
          <h2 className="login-heading12e">
            The journey to Renewable transition can be simple
          </h2>
          <p className="login-paraText11">
            Find out how renewable options can help reduce your bills and carbon
            footprint
          </p>
        </div>
      </div>
      <div className="login-flex-div2">
        {/* <div className="login-div12t" style={{ justifyContent: "end" }}>
          <img className="" src={image2} alt="home img" />
        </div> */}
        <div className="login-flex-div33">
          <h2 className="login-heading33">Contact Us</h2>
          <div className="login-labelInput-Div position-relative">
            <label className="login-label" htmlFor="nameLabel">
              Name
            </label>
            <input
              value={name}
              id="nameLabel"
              onChange={(e) => {
                setName(e.target.value);
                setErrors({ ...errors, nameError: "" });
              }}
              type="text"
              className="login-input "
            />
            <p
              style={{
                color: "red",
                fontSize: "13px",
                opacity: errors.nameError ? 1 : 0,
                position:'absolute'
              }}
            >
              {errors.nameError ?? "valid"}
            </p>
          </div>
          <div className="login-labelInput-Div position-relative">
            <label className="login-label" htmlFor="preferWay">
              Prefered way to connect
            </label>
            <select
              id="preferWay"
              value={preferredWay}
              onChange={(e) => {
                setPreferredWay(e.target.value);
                setErrors({ ...errors, preferredWayError: "" });
              }}
              className="login-input"
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </select>
            <p
              style={{
                color: "red",
                fontSize: "13px",
                opacity: errors.preferredWayError ? 1 : 0,
                position:'absolute'
              }}
            >
              {errors.preferredWayError ?? "valid"}
            </p>
          </div>
          {preferredWay == "email" && (
            <div className="login-labelInput-Div">
              <label className="login-label" htmlFor="EmailLabel">
                Enter Your Email
              </label>
              <input
                value={email}
                id="EmailLabel"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors({ ...errors, emailError: "" });
                }}
                type="email"
                className="login-input "
              />
              <p
                style={{
                  color: "red",
                  fontSize: "13px",
                  opacity: errors.emailError ? 1 : 0,
                  position:'absolute'
                }}
              >
                {errors.emailError ?? "valid"}
              </p>
            </div>
          )}

          {preferredWay == "phone" && (
            <div className="login-labelInput-Div">
              <label className="login-label" htmlFor="PhoneLabel">
                Enter Your Phone Number
              </label>
              <input
                value={phone}
                id="PhoneLabel"
                // onChange={(e) => {
                //   const phoneNumber = e.target.value.replace(/\D/g, ''); // Remove any non-numeric characters
                //   setPhone(phoneNumber);
                //   setErrors({ ...errors, phoneNumberError: null });
                // }}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(
                    /[^\d+]/g,
                    ""
                  ); // Remove non-numeric characters except '+'
                  setPhone(numericValue);
                  setErrors({ ...error, phoneNumberError: "" });
                }}
                type="tel"
                className="login-input "
              />
              <p
                style={{
                  color: "red",
                  fontSize: "13px",
                  opacity: errors.phoneNumberError ? 1 : 0,
                  position:'absolute'
                }}
              >
                {errors.phoneNumberError ?? "valid"}
              </p>
            </div>
          )}

          <div className="login-labelInput-Div" htmlFor="preferTime">
            <label className="login-label">Preferred time to connect</label>
            <input
              id="preferTime"
              type="datetime-local"
              value={preferredTime}
              onChange={(e) => setPreferredTime(e.target.value)}
              className="login-input"
            />
          </div>

          <div className="login-labelInput-Div">
            <label className="login-label" htmlFor="otherNotes">
              Other notes
            </label>
            <textarea
              id="otherNotes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="login-input"
              rows={5}
            ></textarea>
          </div>
          <button className="login-btn11" type="button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
