import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";

const PrivacyPolicy = () => {
  const [openCollapse1, setOpenCollapse1] = useState(false);
  const [openCollapse2, setOpenCollapse2] = useState(false);
  const [openCollapse3, setOpenCollapse3] = useState(false);
  const [openCollapse4, setOpenCollapse4] = useState(false);
  const [openCollapse5, setOpenCollapse5] = useState(false);
  const [openCollapse6, setOpenCollapse6] = useState(false);
  const [openCollapse7, setOpenCollapse7] = useState(false);
  const [openCollapse8, setOpenCollapse8] = useState(false);
  const [openCollapse9, setOpenCollapse9] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="privacy-conatiner ">
      <div className="privacy-section1">
        <p className="faq-para11 mb-0">
          Protecting the privacy of our customers' data is not just a policy;
          it's a core value that influences every decision we make.
        </p>
      </div>
      <h2 className="faq-para124">Nature Electric Privacy Policy</h2>

      <div className="faq-section2">
        <div className="faq-section2-flex">
          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse1(!openCollapse1)}
            >
              <span>Importance of Your Privacy</span>
              {openCollapse1 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse1}>
              <div>
                <p className="faq-answerSection">
                  Nature Electric are committed to protecting the privacy of our
                  users. We are committed to protecting your privacy and
                  complying with the Privacy Act 1988 (Cth) and other applicable
                  privacy laws and regulations. This Privacy Policy outlines our
                  ongoing obligation to you regarding how we collect, use,
                  disclose, and safeguard your personal information across our
                  website,{" "}
                  <a href="http://natureelectric.com.au.">
                    http://natureelectric.com.au.
                  </a>
                </p>
              </div>
            </Collapse>
          </div>

      

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse3(!openCollapse3)}
            >
              <span>How We Collect Information</span>
              {openCollapse3 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse3}>
              <div>
                <div className="faq-answerSection">
                  <ol>
                    <li>
                      {" "}
                      Information You Provide
                      <br />
                      When you create an account or use our Services, we may
                      collect personal information. If you contact us for
                      support or other inquiries, we may collect the content of
                      your communications and any additional information you
                      provide.
                    </li>
                    <li>
                      Automatic Collection
                      <br />
                      We may collect certain information automatically when you
                      use our Services, including your IP address, device type,
                      operating system, browser type, and usage data. We may use
                      cookies, web beacons, and similar tracking technologies to
                      collect information about your interactions with our
                      Services.
                    </li>
                  </ol>
                </div>
              </div>
            </Collapse>
          </div>
       

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse5(!openCollapse5)}
            >
              <span>Sharing of Your Information</span>
              {openCollapse5 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse5}>
              <div>
                <p className="faq-answerSection">
                  We may share your personal information with third parties in
                  the following circumstances: With your consent. With our
                  service providers and partners who assist us in providing and
                  improving our Services. In response to a legal request, court
                  order, or government investigation. To protect our rights,
                  privacy, safety, or property, and/or that of you or others.
                </p>
              </div>
            </Collapse>
          </div>


          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse7(!openCollapse7)}
            >
              <span>Your Choices </span>
              {openCollapse7 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse7}>
              <div>
                <p className="faq-answerSection">
                  You have the following choices regarding your personal
                  information: You can update your account information through
                  the Services. You can unsubscribe from our marketing
                  communications by following the instructions provided. You can
                  request access, correction, or deletion of your personal
                  information by contacting us.
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse9(!openCollapse9)}
            >
              <span>‍Contact Us</span>
              {openCollapse9 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse9}>
              <div>
                <p className="faq-answerSection">
                  If you have any questions, concerns, or requests related to
                  this Privacy Policy or our data practices, please contact us
                  at <a href="mailto:info@natureelectric.com.au.">info@natureelectric.com.au.</a> 
                </p>
              </div>
            </Collapse>
          </div>
        </div>

        <div className="faq-section2-flex">
        <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse2(!openCollapse2)}
            >
              <span>Personal Information We Collect</span>
              {openCollapse2 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse2}>
              <div>
                <p className="faq-answerSection">
                  We may request personal information, such as name, mailing or
                  street address, contact details and billing information. We
                  only request personal information that is relevant to
                  providing service and you can decline our request, but it may
                  limit our ability to provide certain services you want. We do
                  not share your personal information with third parties, except
                  under the legal obligation to do so. We shall retain personal
                  information solely for the duration required to fulfil the
                  provision of the requested service.
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse4(!openCollapse4)}
            >
              <span>How We Use Your Information</span>
              {openCollapse4 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse4}>
              <div>
                <p className="faq-answerSection">
                  We may use your personal information for the following
                  purposes: To provide, maintain, and improve our Services. To
                  respond to your requests, comments, or questions. To send you
                  important updates, newsletters, and promotional materials. To
                  monitor and analyse usage patterns and trends. To protect our
                  rights, privacy, safety, or property, and/or that of you or
                  others. To comply with legal obligations.
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse6(!openCollapse6)}
            >
              <span>Security</span>
              {openCollapse6 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse6}>
              <div>
                <p className="faq-answerSection">
                  We take reasonable measures to protect your personal
                  information from unauthorised access, disclosure, alteration,
                  or destruction. However, no data transmission over the
                  Internet or electronic storage is entirely secure, and we
                  cannot guarantee absolute security.
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse8(!openCollapse8)}
            >
              <span> Changes to this Privacy Policy</span>
              {openCollapse8 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse8}>
              <div>
                <p className="faq-answerSection">
                  We may update this Privacy Policy to reflect changes in our
                  practices or for other operational, legal, or regulatory
                  reasons. Any changes will be posted on this page, and the date
                  of the latest revision will be updated.
                </p>
              </div>
            </Collapse>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
