import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChangePasswordAction } from "../Redux/actions/AuthAction";
import { validations } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.authReducer);
  const { message, success, error } = useSelector(
    (state) => state.ChangePasswordReducer
  );
  console.log("++++++++", message, success);

  const [oldpassword, setOldPassword] = useState();
  const [render, setRender] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [cNewpassword, setCnewPassword] = useState();
  const [errors, setErrors] = useState({
    oldpassword: "",
    newPassword: "",
    cNewpassword: "",
  });
  const handleUpdateSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("password", newPassword);
    formData.append("current_password", oldpassword);
    formData.append("confirm_password", cNewpassword);
    formData.append("email", userData?.user?.email);

    dispatch(ChangePasswordAction(formData));
    setRender(true);
  };
  const validateSubmit = (e) => {
    e.preventDefault();
    const tempErrors = {
      oldpassword: validations.oldpassword(oldpassword),
      newPassword: validations.password(newPassword),
      cNewpassword: validations.confirmPassword(newPassword, cNewpassword),
    };
    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    handleUpdateSubmit(e);
  };

  useEffect(() => {
    if (message && render) {
      swal({
        title: "",
        text: message,
        className: "successAlert",
        icon: "/img/logo.svg",
        buttons: false,
        timer: 3000,
        open: true,
      });
      navigate("/Dashbord");
    }
    if (error && render) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "/img/logo.svg",
        buttons: false,
        timer: 3000,
      });
      setRender(false);
    }
  }, [message, error]);
  return (
    <div className="login">
      <div className="login_sec">
        <div className="login-logoimg">
          <img src="/img/logo.svg" />
        </div>
        <form>
          <div className="login-input">
            <div className="loginuser">
              <input
                type="Old Password"
                placeholder="Old Password"
                name="oname"
                onChange={(e) => {
                  setOldPassword(e.target.value);
                  setErrors({ ...errors, oldpassword: null });
                }}
              />
              <span
                style={{
                  color: "red",
                  fontSize: "15px",
                  opacity: errors.oldpassword ? 1 : 0,
                }}
              >
                {errors.oldpassword ?? "valid"}
              </span>
            </div>
            <div className="loginuser">
              <input
                type="Password"
                placeholder="Password"
                name="uname"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setErrors({ ...errors, newPassword: null });
                }}
              />
              <span
                style={{
                  color: "red",
                  fontSize: "15px",
                  opacity: errors.newPassword ? 1 : 0,
                }}
              >
                {errors.newPassword ?? "valid"}
              </span>
            </div>
            <div className="loginuser">
              <input
                type="password"
                placeholder="Confirm Password"
                name="psw"
                onChange={(e) => {
                  setCnewPassword(e.target.value);
                  setErrors({ ...errors, cNewpassword: null });
                }}
              />
              <span
                style={{
                  color: "red",
                  fontSize: "15px",
                  opacity: errors.cNewpassword ? 1 : 0,
                }}
              >
                {errors.cNewpassword ?? "valid"}
              </span>
            </div>

            <button className="loginbtn" type="button" onClick={validateSubmit}>
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePassword;
