import { urls } from '../constants';
import { apiService } from './api.service';

async function getSurveyQuestions() {
  return apiService.get(`${urls.SURVEY_QUESTIONS}`);
}

async function postSurveyAnswers(value) {
  return apiService.post(`${urls.SURVEY_ANSWERS}`, value);
}

export const surveyPageService = {
  getSurveyQuestions,
  postSurveyAnswers,
};
