import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginAction } from "../Redux/actions/AuthAction";
// import Loader from "../Loader/Loader";
import { Loader } from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { validations } from "../utils";
import "./style.css";
import image1 from "../assets/images/Singup55Image.svg";
import image2 from "../assets/images/Logo3333Image.svg";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, userData } = useSelector((state) => state.authReducer);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [render, setRender] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const handleLogIn = async () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    dispatch(LoginAction(formData));

    setIsLoading(false);
    setRender(true);
  };

  const validateSubmit = (e) => {
    e.preventDefault();

    const tempErrors = {
      email: validations.email(email),
      password: validations.password(password),
    };
    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    handleLogIn();
  };

  useEffect(() => {
    if (userData && render) {
      swal({
        title: "",
        text: "Login Successfully!",
        className: "successAlert",
        icon: "img/logo.svg",
        buttons: false,
        timer: 3000,
        open: true,
      });
      navigate("/");
      setRender(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
    if (error && render) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "img/logo.svg",
        buttons: false,
        timer: 3000,
      });
      setRender(false);
      setIsLoading(false);
    }
  }, [userData, error]);

  return (
    <>
      {isLoading ? (
        <div style={{ marginTop: "10rem" }}>
          <Loader text="Loading..."/>
        </div>
      ) : (
        <>
          {/* <div className="login">
            <Link
              to="free-energy"
              style={{ position: "absolute", top: "12%", right: "10%" }}
            >
              Try for Free
            </Link>
            <div className="login_sec">
              <div className="login-logoimg">
                <img src="img/logo.svg" />
              </div>
              <form onSubmit={(e) => validateSubmit(e)}>
                <div className="login-inpu">
                  <div className="loginuser">
                    <input
                      type="email"
                      onChange={(e) => {
                        setErrors({ ...errors, email: null });
                        setEmail(e.target.value);
                      }}
                      placeholder="Enter Email"
                      name="uname"
                    />
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        opacity: errors.email ? 1 : 0,
                      }}
                    >
                      {errors.email ?? "valid"}
                    </span>
                  </div>
                  <div className="loginuser">
                    <input
                      type="password"
                      placeholder="Enter Password"
                      name="psw"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setErrors({ ...errors, password: null });
                      }}
                    />
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        opacity: errors.password ? 1 : 0,
                      }}
                    >
                      {errors.password ?? "valid"}
                    </span>
                  </div>
                  <div className="Forgottext">
                    <Link className="forgotpasswordtext" to="/forgot-password">
                      Forgot Password ?
                    </Link>
                  </div>
                  <button className="loginbtn" type="submit">
                    Login
                  </button>
                  <Link className="createbtn" to="/register">
                    Create Account
                  </Link>
                </div>
              </form>
            </div>
          </div> */}
          <div className="login-container">
            <div class="login-flex-div1">
              <img className="login-img122" src={image1} alt="img" />
              <div className="login-div12">
                <h2 className="login-heading12e">
                  The journey to Renewable transition can be simple
                </h2>
                <p className="login-paraText11">
                  Find out how renewable options can help reduce your bills and
                  carbon footprint
                </p>
              </div>
            </div>
            <div className="login-flex-div2">
              <div className="login-flex-div33">
                <h2 className="login-heading33">Sign in</h2>

                <div className="login-labelInput-Div">
                  <label className="login-label">Email address</label>
                  <input
                    type="email"
                    className="login-input "
                    onChange={(e) => {
                      setErrors({ ...errors, email: null });
                      setEmail(e.target.value);
                    }}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors.email ? 1 : 0,
                      position:'absolute'
                    }}
                  >
                    {errors.email ?? "valid"}
                  </p>
                </div>
                <div className="login-labelInput-Div">
                  <label className="login-label">Password</label>
                  <input
                    type="password"
                    className="login-input "
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrors({ ...errors, password: null });
                    }}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors.password ? 1 : 0,
                      position:'absolute'
                    }}
                  >
                    {errors.password ?? "valid"}
                  </p>
                </div>
                <p className="Login-forgetPassword">
                  <Link to="/forgot-password">Forget password?</Link>
                </p>
                <button
                  className="login-btn11"
                  onClick={(e) => validateSubmit(e)}
                >
                  Next
                </button>
                {/* <p className="login-Orpara">or</p>
                <p className="login-SocialDiv ">Continue with Linkeldn</p>
                <p className="login-SocialDiv ">Continue with Linkeldn</p>
                <p className="login-SocialDiv ">Continue with Facebook</p> */}
                <p className="Login-LinkText">
                  Need an account? <Link to="/register">Sign up</Link>
                </p>
               <button className="Login-btn222" onClick={()=>navigate("/")}>Back</button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Login;
