import React from "react";
import { Link } from "react-router-dom"
const sidebar = () => {
  return (
    <div className="sidebar-inner">
      <div className="link">
    helg;sjg;sj;dklsf dsfkdsfk;dlsfkd;lsk
      </div>
    </div>
  );
};

export default sidebar;
