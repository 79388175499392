import * as Auth from "../types/AuthType";
import axios from "axios";
import { BACKEND_URL } from "../../environment";


export const FeasibilityTab1PostData =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: Auth.FEASIBILITY_TAB1_POST_REQUEST,
      });
      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };
      const { data } = await axios.post(
        `${BACKEND_URL}api/v1/common/nmi-upload/`,
        params,
        config
      );

      dispatch({
        type: Auth.FEASIBILITY_TAB1_POST_SUCCESS,
        payload: data,
      });

      return true;
    } catch (error) {
      dispatch({
        type: Auth.FEASIBILITY_TAB1_POST_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };


  export const ContactusPostData =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: Auth.CONTACT_POST_REQUEST,
      });
  
      const { data } = await axios.post(
        `${BACKEND_URL}contact-us/`,
        params,
      
      );

      dispatch({
        type: Auth.CONTACT_POST_SUCCESS,
        payload: data,
      });

      return true;
    } catch (error) {
      dispatch({
        type: Auth.CONTACT_POST_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };

  
  
  
  export const ConnectionPostData =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: Auth.CONNECTION_POST_REQUEST,
      });
      
      const { data } = await axios.post(
        `${BACKEND_URL}api/v1/common/connection-details/`,
        params,
      
      );

      dispatch({
        type: Auth.CONNECTION_POST_SUCCESS,
        payload: data,
      });

      return true;
    } catch (error) {
      dispatch({
        type: Auth.CONNECTION_POST_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const FeasibilityPostData = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: Auth.FEASIBILITY_POST_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    const { data } = await axios.post(
      `${BACKEND_URL}api/v1/common/free_output/`,
      params,
      config
    );

    dispatch({
      type: Auth.FEASIBILITY_POST_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.FEASIBILITY_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const EmptyReducer = () => ({
  type: Auth.FREE_OUTPUT_EMPTY_REDUCER,
});
export const FreeOutputGetDataAction = (params, userD) => async (dispatch) => {
  try {
    dispatch({
      type: Auth.FREE_OUTPUT_GET_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const { data } = await axios.get(
      `${BACKEND_URL}api/v1/common/free_energy_output/?id=${params}`,
      config,
      userD
    );

    dispatch({
      type: Auth.FREE_OUTPUT_GET_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.FREE_OUTPUT_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const PlanInputPostData = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: Auth.PLANINPUT_POST_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    const { data } = await axios.post(
      `${BACKEND_URL}api/v1/common/basic_output/`,
      params,
      config
    );

    dispatch({
      type: Auth.PLANINPUT_POST_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.PLANINPUT_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const BasicOutputGetData =
  (params, userD) => async (dispatch, getState) => {
    try {
      dispatch({
        type: Auth.BASIC_OUTPUT_GET_REQUEST,
      });
      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };
      const { data } = await axios.get(
        `${BACKEND_URL}api/v1/common/basic_energy_output/?id=${params}`,
        config,
        userD
      );

      dispatch({
        type: Auth.BASIC_OUTPUT_GET_SUCCESS,
        payload: data,
      });

      return true;
    } catch (error) {
      dispatch({
        type: Auth.BASIC_OUTPUT_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const BasicOutputEmptyReducer = () => ({
  type: Auth.BASIC_OUTPUT_EMPTY_REDUCER,
});

export const UploadHistoryGetData = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: Auth.UPLOAD_HISTORY_GET_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    const { data } = await axios.get(
      `${BACKEND_URL}api/v1/common/user-upload-history/`,
      config
    );

    dispatch({
      type: Auth.UPLOAD_HISTORY_GET_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.UPLOAD_HISTORY_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UploadHistoryDelete = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: Auth.UPLOAD_HISTORY_DELETE_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    const { data } = await axios.delete(
      `${BACKEND_URL}api/v1/common/user-upload-history/delete/${params}/`,
      config
    );
    dispatch({
      type: Auth.UPLOAD_HISTORY_DELETE_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.UPLOAD_HISTORY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteProfile = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: Auth.DELETE_PROFILE_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    const { data } = await axios.delete(
      `${BACKEND_URL}api/v1/common/delete-user-profile`,
      config
    );
    dispatch({
      type: Auth.DELETE_PROFILE_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.DELETE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const EmailDataFetch = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: Auth.EMAIL_DATA_GET_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    const { data } = await axios.get(
      `${BACKEND_URL}api/v1/common/nmi-redirect-data/?email=${params}`,
      config
    );
    dispatch({
      type: Auth.EMAIL_DATA_GET_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.EMAIL_DATA_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const RedirectFetchData = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: Auth.REDIRECT_FETCH_GET_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    const { data } = await axios.get(
      `${BACKEND_URL}api/v1/common/nmi-fetch-data/?email=${params?.emailData}&state=${params?.state}&postcode=${params?.pinCode}`,
      config
    );
    dispatch({
      type: Auth.REDIRECT_FETCH_GET_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.REDIRECT_FETCH_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FreeOutPutPostDataAction = (params) => async (dispatch) => {
  try {
    dispatch({
      type: Auth.FREE_OUTPUT_POST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const { data } = await axios.post(
      `${BACKEND_URL}api/v1/common/free_energy_output/`,
      params,
      config
    );

    dispatch({
      type: Auth.FREE_OUTPUT_POST_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.FREE_OUTPUT_POST_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const FreeEnergyFetchAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: Auth.ENERGY_FETCH_DATA_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    const { data } = await axios.post(
      `${BACKEND_URL}api/v1/common/nmi-basic-upload/`,
      params,
      config
    );

    dispatch({
      type: Auth.ENERGY_FETCH_DATA_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.ENERGY_FETCH_DATA_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const FreeEnergyPostAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: Auth.FREE_ENERGY_POST_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    const { data } = await axios.post(
      `${BACKEND_URL}api/v1/common/basic_energy_output/`,
      params,
      config
    );

    dispatch({
      type: Auth.FREE_ENERGY_POST_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.FREE_ENERGY_POST_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const MoreTrailsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: Auth.TRAILS_CONTACTUS_POST_REQUEST,
      });
      
      const { data } = await axios.post(
        `${BACKEND_URL}api/v1/common/interest/`,
        params,
      
      );

      dispatch({
        type: Auth.TRAILS_CONTACTUS_POST_SUCCESS,
        payload: data,
      });

      return true;
    } catch (error) {
      dispatch({
        type: Auth.TRAILS_CONTACTUS_POST_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };


  export const SurveyFormsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: Auth.SURVEY_FORMS_GET_REQUEST,
      });
      
      const { data } = await axios.get(
        `${BACKEND_URL}api/v1/common/survey-questions/`,
        params,
      
      );

      dispatch({
        type: Auth.SURVEY_FORMS_GET_SUCCESS,
        payload: data,
      });

      return true;
    } catch (error) {
      dispatch({
        type: Auth.SURVEY_FORMS_GET_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };

  export const SurveyFormsPostAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({
        type: Auth.SURVEY_FORMS_POST_REQUEST,
      });
      const {
        authReducer: { userData },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": " application/json",
          Authorization: `Bearer ${userData.token}`,
        },
      };
      
      const { data } = await axios.post(
        `${BACKEND_URL}api/v1/common/survey-answers/`,
        params,
        config
      );

      dispatch({
        type: Auth.SURVEY_FORMS_POST_SUCCESS,
        payload: data,
      });

      return true;
    } catch (error) {
      dispatch({
        type: Auth.SURVEY_FORMS_POST_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
