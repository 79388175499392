import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { outputPageService } from "../services/outputPage.service";
import { Loader } from "../components/Loader";

const OutputPageOne = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [solarSize, setSolarSize] = useState(null);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchOutputOneResults();
  }, []);

  const handleNavigate = () => {
    handleSubmitForm();
    navigate("/output");
  };

  const fetchOutputOneResults = async () => {
    try {
      const inputId = localStorage.getItem("nature_electric_input_id");
      if (inputId) {
        const results = await outputPageService.outputPageOne(inputId);
        // const results = {
        //   Table: [
        //     {
        //       name: "System Size",
        //       "Battery Capacity": [
        //         23.838571582933888, 33.34985991274931, 43.94031071880716,
        //         55.37042489067769, 67.61762573606336,
        //       ],
        //       "Solar Size": [40, 45, 50, 55, 60],
        //     },
        //     {
        //       name: "Return Of Investment",
        //       Solar: [35.59, 33.74, 31.88, 30.09, 28.42],
        //       System: [23.22, 20.43, 18.06, 16.01, 14.21],
        //     },
        //     {
        //       name: "IRR",
        //       Solar: [36.7, 34.88, 33.1, 31.45, 29.9],
        //       System: [25.95, 23.28, 20.97, 18.95, 17.16],
        //     },
        //     {
        //       name: "NPV",
        //       Solar: [113931.25, 119939.03, 124173.85, 127118.47, 128997.29],
        //       System: [107697.07, 111104.39, 111686.2, 109305.73, 104292.72],
        //     },
        //     {
        //       name: "Electricity Cost Reduction",
        //       Solar: [0.52, 0.49, 0.46, 0.43, 0.41],
        //       System: [0.47, 0.41, 0.36, 0.32, 0.28],
        //     },
        //     {
        //       name: "Electricity Independence",
        //       Solar: [49.82, 53.47, 56.54, 59.17, 61.47],
        //       System: [55.31, 61.23, 66.68, 71.57, 75.87],
        //     },
        //     {
        //       name: "CO2 Reductions",
        //       Solar: [50.04, 53.67, 56.73, 59.36, 61.64],
        //       System: [55.61, 61.53, 66.99, 71.87, 76.15],
        //     },
        //   ],
        //   Indicators: [40, 45, 50, 55, 60],
        // };
        setSolarSize(results?.Indicators?.[0]);
        setData(results || {});
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSubmitForm = async () => {
    try {
      const localId = localStorage.getItem("nature_electric_input_id");
      const formData = new FormData();
      formData.append("input_id", localId ? localId : null);
      formData.append("solar_size", solarSize);
      const result = await outputPageService.outputPageOnePost(formData);
    } catch (error) {
      console.log(error);
    }
  };

  const commasAfterThreeDigits = (value) => {
    if (typeof value === "string") {
      return value;
    }
    if (value === 0) {
      return "0";
    } else if (!isNaN(value)) {
      return value.toLocaleString();
    } else {
      return "N/A";
    }
  };

  // function multiplyBy100AndRound(input, decimalPlaces = 2) {
  //   if (typeof input !== "number" || isNaN(input)) {
  //     return "N/A";
  //   }

  //   const result = (input * 100).toFixed(decimalPlaces);

  //   return parseFloat(result);
  // }

  function multiplyBy100AndRound(input, decimalPlaces = 2) {
    if (typeof input !== "number" || isNaN(input)) {
      return "N/A";
    }

    const multiplier = Math.pow(10, decimalPlaces);
    const roundedResult = Math.round(input * 100 * multiplier) / multiplier;

    return roundedResult.toFixed(decimalPlaces);
  }

  const handleRadioChange = (e) => {
    setSolarSize(e.target.value);
  };

  function formatNumberWithTwoDecimals(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      return "N/A";
    }

    // Use toFixed to round the number to 2 decimal places
    const formattedNumber = number.toFixed(2);

    return parseFloat(formattedNumber); // Convert the result back to a number
  }

  return (
    <>
      <div className="container">
        <div className="Outputarea output1">
          <h2 className="">Solar & Battery</h2>
          {/* {/           <div className="pageheading"> /}
{/             <h3>Solar & Battery</h3> /}
{/           </div> /} */}
          <div className="accordianBox">
            <div class="accordion" id="myAccordion">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    type="button"
                    class="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Solar"
                  >
                    Result comparison
                  </button>
                </h2>
                {loading ? (
                  <div className="loader">
                    <Loader />
                  </div>
                ) : (
                  <div
                    id="Solar"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#myAccordion"
                  >
                    <div class="card-body">
                      <div className="resultTable">
                        {data ? (
                          <table class="table table-borderless">
                            <thead>
                              <tr>
                                <th>Indicators</th>
                                {data?.Indicators?.map?.((item, index) => (
                                  <th key={index}>{item}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {data?.Table?.map((section, sectionIndex) => (
                                <React.Fragment key={sectionIndex}>
                                  <tr>
                                    <td colSpan={6} className="borderBottom">
                                      {section.name}
                                    </td>
                                  </tr>
                                  {section["Battery Capacity"] && (
                                    <tr>
                                      <td>Battery Capacity(kWh)</td>
                                      {section["Battery Capacity"].map(
                                        (value, index) => (
                                          <td key={index}>
                                            {formatNumberWithTwoDecimals(value)}
                                          </td>
                                        )
                                      )}
                                    </tr>
                                  )}
                                  {section["Solar Size"] && (
                                    <tr className="mp-5">
                                      <td>Solar Size(kW)</td>
                                      {section["Solar Size"].map(
                                        (value, index) => (
                                          <td key={index}>{value}</td>
                                        )
                                      )}
                                    </tr>
                                  )}
                                  {section["Solar"] && (
                                    <tr className="mp-5">
                                      <td>Solar</td>
                                      {section["Solar"].map((value, index) => (
                                        <td key={index}>
                                          {section.name === "NPV" && "$"}
                                          {section.name === "NPV"
                                            ? commasAfterThreeDigits(value)
                                            : section.name ===
                                              "Electricity Cost Reduction"
                                            ? multiplyBy100AndRound(value)
                                            : value}
                                          {(section.name === "IRR" ||
                                            section.name ===
                                              "Return Of Investment" ||
                                            section.name ===
                                              "Electricity Cost Reduction" ||
                                            section.name === "CO2 Reductions" ||
                                            section.name ===
                                              "Electricity Independence") &&
                                            "%"}
                                        </td>
                                      ))}
                                    </tr>
                                  )}
                                  {section["System"] && (
                                    <tr className="mp-5">
                                      <td>System</td>
                                      {section["System"].map((value, index) => (
                                        <td key={index}>
                                          {section.name === "NPV" && "$"}
                                          {section.name === "NPV"
                                            ? commasAfterThreeDigits(value)
                                            : section.name ===
                                              "Electricity Cost Reduction"
                                            ? multiplyBy100AndRound(value)
                                            : value}
                                          {(section.name === "IRR" ||
                                            section.name ===
                                              "Return Of Investment" ||
                                            section.name ===
                                              "Electricity Cost Reduction" ||
                                            section.name === "CO2 Reductions" ||
                                            section.name ===
                                              "Electricity Independence") &&
                                            "%"}
                                        </td>
                                      ))}
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                              <td className="radioBox">
                                <h3>Select Options</h3>
                              </td>
                              {data?.Indicators?.map((item, index) => (
                                <td key={index} className="radioBox">
                                  <p>
                                    <input
                                      type="radio"
                                      id={`test${index + 1}`}
                                      name="radio-group"
                                      value={item}
                                      checked={solarSize == item}
                                      onChange={handleRadioChange}
                                    />
                                    <label htmlFor={`test${index + 1}`}></label>
                                  </p>
                                </td>
                              ))}

                              {/* <td className="radioBox">
                                <p>
                                  <input
                                    type="radio"
                                    id="test2"
                                    name="radio-group"
                                  />
                                  <label for="test2"></label>
                                </p>
                              </td>
                              <td className="radioBox">
                                <p>
                                  <input
                                    type="radio"
                                    id="test3"
                                    name="radio-group"
                                  />
                                  <label for="test3"></label>
                                </p>
                              </td>
                              <td className="radioBox">
                                <p>
                                  <input
                                    type="radio"
                                    id="test4"
                                    name="radio-group"
                                  />
                                  <label for="test4"></label>
                                </p>
                              </td>
                              <td className="radioBox">
                                <p>
                                  <input
                                    type="radio"
                                    id="test5"
                                    name="radio-group"
                                  />
                                  <label for="test5"></label>
                                </p>
                              </td> */}
                            </tbody>
                          </table>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="surveyArea outputFooter">
        <hr />
        <div class="surveyfooter">
          <div class="container-fluid">
            <div class="surveyin">
              <button disabled="">Previous</button>
              <button disabled={loading || !solarSize} onClick={handleNavigate}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OutputPageOne;
