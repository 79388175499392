import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Frontend_URL } from "../environment";
import GoogleMap from "../components/GoogleMapFreeEnergy";
import { FreeOutPutPostDataAction } from "../Redux/actions/PublicActions";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

const FreeEnergy = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tabs, settabs] = useState(1);
  const [fullAddress, setFullAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [roofArea, setRoofArea] = useState(null);
  const [state, setstate] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [mapYourRoof, setMapYourRoof] = useState(true);
  const [isSolarChecked, setIsSolarChecked] = useState(true); //for solar
  const [isSolarBatteryChecked, setIsSolarBatteryChecked] = useState(false); // for solar and battery
  const [selectedIndustry, setSelectedIndustry] = useState("Aged Care");
  const [averageEnergy, setaverageEnergy] = useState(""); //for average_energy
  const [energyContract, setEnergyContract] = useState(false);
  const [annualContract, setAnnualContract] = useState(false);
  const [penalties, setPenalties] = useState(false);
  const [render, setRender] = useState(false);
  const [weekend, setweekend] = useState("true");
  const [finaceSolar, setFinaceSolar] = useState("Outright Purchase");
  const [errors, setErrors] = useState({
    ShowErrorArea: null,
    ShowErrorAddress: null,
    avgEnergyError: null,
  });

  const { data: tab1Respose, error: errorTab1 } = useSelector(
    (state) => state.FeeOutPutPostReducer
  );
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (tab1Respose && render) {
      Swal.fire({
        title: "Submit",
        text: "Form Submitted",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
      localStorage.setItem("id", tab1Respose?.id);
      setRender(false);
      setTimeout(() => {
        navigate(`/free-output`);
      }, [3000]);
    }
    if (errorTab1 && render) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong.",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
      setRender(false);
    }
  }, [tab1Respose, errorTab1]);

  const handleSolarChange = (bool) => {
    if (!bool) {
      setIsSolarBatteryChecked(false);
      setIsSolarChecked(true);
    } else if (bool) {
      setIsSolarBatteryChecked(true);
      setIsSolarChecked(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("address", fullAddress);
    formData.append("longitude", longitude);
    formData.append("latitude", latitude);
    formData.append("roof_size", roofArea);
    formData.append("postcode", pinCode);
    formData.append("solar", true);
    formData.append("battrey", isSolarBatteryChecked);
    formData.append("average_energy", averageEnergy);
    formData.append("is_energy_contract", energyContract);
    if (weekend == "true") {
      formData.append("weekend", true);
    } else {
      formData.append("weekend", false);
    }
    if (energyContract) {
      formData.append("is_annual_contract", annualContract);
      formData.append("is_penalties", penalties);
    }
    formData.append("finace_solar", finaceSolar);
    formData.append("state", state);
    formData.append("industry", selectedIndustry);
    setRender(true);
    dispatch(FreeOutPutPostDataAction(formData));
  };

  const HandleFirstTab = (e) => {
    e.preventDefault();
    const tempErrors = {
      area: !fullAddress && "*Please Enter Address",
      address: mapYourRoof && !roofArea && "*Please select roof area",
    };
    setErrors((prevErrors) => ({
      ...prevErrors,
      ShowErrorArea: tempErrors.address,
      ShowErrorAddress: tempErrors.area,
    }));
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    settabs(2);
  };

  const handleTabSecond = () => {
    const tempErrors = {
      energyErr: !averageEnergy && "*Value should be greater than 0",
    };
    setErrors((prevErrors) => ({
      ...prevErrors,
      avgEnergyError: tempErrors.energyErr,
    }));
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }

    settabs(3);
  };

  const handleAvgEnergy = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      setaverageEnergy(inputValue);
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      avgEnergyError: null,
    }));
  };
  console.log("avaerageEneegy.......", averageEnergy);
  const handleReachOut = () => {
    window.open(`${Frontend_URL}contact-us`, "_blank");
  };

  return (
    <div className="basicEnergy-container">
      <div className="basicEnergy-section1 ">
        <p className="basicEnergy-para11 mb-0">
          Realise the benefits of adopting solar & battery with a simple 4 steps
          estimation
        </p>
      </div>
      <div className="divFeasib7546">
        {tabs === 1 && (
          <>
            <div className="divFeasiv555">
              <GoogleMap
                setFullAddress={setFullAddress}
                setLongitude={setLongitude}
                setLatitude={setLatitude}
                roofArea={roofArea}
                setRoofArea={setRoofArea}
                setstate={setstate}
                setPinCode={setPinCode}
                mapYourRoof={mapYourRoof}
                setMapYourRoof={setMapYourRoof}
                errors={errors}
                setErrors={setErrors}
              />
            </div>

            <div className="d-flex justify-content-end">
              <button
                className="btn77Feasib"
                onClick={(e) => HandleFirstTab(e)}
              >
                Next
              </button>
            </div>
          </>
        )}

        {tabs === 2 && (
          <>
            <div className="divFeasiv555">
              <div className="mb-5 mt-4">
                <label className="feasibLabel mb-2">
                  What are you interested in
                </label>
                <div className="d-flex">
                  <div className="d-flex align-items-start">
                    <input
                      type="radio"
                      className="input-Topmargin"
                      id="value8"
                      checked={isSolarChecked}
                      onChange={() => handleSolarChange(false)}
                    />
                    <label className="label55" htmlFor="value8">
                      Solar
                    </label>
                  </div>
                  <div className="d-flex align-items-start">
                    <input
                      type="radio"
                      className="input-Topmargin"
                      id="value7"
                      checked={isSolarBatteryChecked}
                      onChange={() => handleSolarChange(true)}
                    />
                    <label className="label55" htmlFor="value7">
                      Solar + Battery
                    </label>
                  </div>
                </div>
              </div>

              <div className="mb-5 ">
                <label className="feasibLabel mb-2">
                  What industry do you operate in (If your industry isn't
                  listed, choose one that's similar in how it uses energy.)
                </label>
                <select
                  className="feasibInput"
                  value={selectedIndustry}
                  onChange={(e) => setSelectedIndustry(e.target.value)}
                >
                  <option value="Aged Care">Aged Care Center</option>
                  <option value="Agriculture">Agricultural Site</option>
                  <option value="Bakery">Bakery Producer</option>
                  <option value="Beverage Manufacturer">
                    Beverage Producer
                  </option>
                  <option value="Child care">Child Care Center</option>
                  <option value="Cool Storage">Cold Storage Warehouse</option>
                  <option value="Community Centre">Community Centre</option>
                  <option value="Gym">Community Gym</option>
                  <option value="Coporate Body Commercial">
                    Coporate Headquarters
                  </option>
                  <option value="Data Center">Data Center</option>
                  <option value="Distribution Center">
                    Distribution Center
                  </option>
                  <option value="Fastfood Chain">Fastfood Shop</option>
                  <option value="Hospital">Hospital</option>
                  <option value="Medium Hotel">Hotel</option>
                  <option value="Logistics Centre">Logistics Warehouse</option>
                  <option value="Manufacturing">Manufacturing Complex</option>
                  <option value="Metal Fabrication">Metal Workshop</option>
                  <option value="Office Small">Office</option>
                  
                  <option value="Retail Pharmacist">Pharmaceutical Shop</option>
                  
                  <option value="Printing Press">Printing Center</option>
                  <option value="Residential House">Residential House</option>
                  <option value="Restaurant Bar n Grill">Restaurant</option>
                  <option value="Hardware store">Retail Hardware Shop</option>
                  <option value="Retail 7 days">Retail Shop</option>
                  <option value="Timbermill">Sawmill</option>
                  <option value="Shopping Center Large">Shopping Center</option>
                  <option value="Supermarket">Supermarket</option>
                </select>
              </div>
              <div className="mb-5">
                <label className="feasibLabel mb-2">
                  Do you operate on weekends
                </label>
                <div className="d-flex">
                  <div className="d-flex align-items-start">
                    <input
                      type="radio"
                      className="input-Topmargin"
                      id="value9"
                      name="grouph4"
                      checked={weekend == "true" && true}
                      onChange={() => setweekend("true")}
                    />
                    <label className="label55" htmlFor="value9">
                      Yes/Frequently
                    </label>
                  </div>
                  <div className="d-flex align-items-start">
                    <input
                      type="radio"
                      className="input-Topmargin"
                      id="value9"
                      name="grouph4"
                      checked={weekend == "false1" && true}
                      onChange={() => setweekend("false1")}
                    />
                    <label className="label55" htmlFor="value9">
                      Occasionally
                    </label>
                  </div>
                  <div className="d-flex align-items-start">
                    <input
                      type="radio"
                      className="input-Topmargin"
                      id="value10"
                      name="grouph4"
                      checked={weekend == "false2" && true}
                      onChange={() => setweekend("false2")}
                    />
                    <label className="label55" htmlFor="value10">
                      No/Rarely
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-5 ">
                <label className="feasibLabel mb-2">
                  Average daily consumption in kWh (See your bill)
                </label>
                <input
                  className="feasibInput"
                  type="text"
                  value={averageEnergy}
                  placeholder="0"
                  onChange={handleAvgEnergy}
                />
                <span
                  style={{
                    color: "red",
                    textAlign: "end",
                    fontSize: "13px",
                    opacity: errors?.avgEnergyError ? 1 : 0,
                  }}
                >
                  {errors?.avgEnergyError ?? "valid"}
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <button className="btn77Feasib" onClick={() => settabs(1)}>
                Back
              </button>
              <button
                className="btn77Feasib btn44-reachOut"
                onClick={handleReachOut}
              >
                Feeling Stuck, Reach Out!
              </button>
              <button className="btn77Feasib" onClick={handleTabSecond}>
                Next
              </button>
            </div>
          </>
        )}

        {tabs === 3 && (
          <>
            <div className="divFeasiv555">
              <div className="mb-5 mt-4">
                <label className="feasibLabel mb-2">
                  Do you have an fixed term energy contract with your retailer
                </label>
                <div className="d-flex">
                  <div className="d-flex align-items-start">
                    <input
                      type="radio"
                      className="input-Topmargin"
                      id="value8"
                      name="yesNoRadio"
                      checked={energyContract}
                      onChange={() => {
                        setEnergyContract(true);
                        setAnnualContract(false);
                      }}
                    />
                    <label className="label55" htmlFor="value8">
                      Yes
                    </label>
                  </div>
                  <div className="d-flex align-items-start">
                    <input
                      type="radio"
                      className="input-Topmargin"
                      id="value7"
                      name="yesNoRadio"
                      checked={!energyContract}
                      onChange={() => {
                        setEnergyContract(false);
                        setAnnualContract(false);
                      }}
                    />
                    <label className="label55" htmlFor="value7">
                      No
                    </label>
                  </div>
                </div>
              </div>
              {energyContract && (
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    Is the contract tied to your annual load
                  </label>
                  <div className="d-flex">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value8_"
                        name="yesNoRadio_"
                        checked={annualContract}
                        onChange={() => setAnnualContract(true)}
                      />
                      <label className="label55" htmlFor="value8_">
                        Yes
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value7_"
                        name="yesNoRadio_"
                        checked={!annualContract}
                        onChange={() => setAnnualContract(false)}
                      />
                      <label className="label55" htmlFor="value7_">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {annualContract && (
                <div className="mb-5">
                  <label className="feasibLabel mb-2">
                    Are there penalties applicable
                  </label>
                  <div className="d-flex">
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value9"
                        name="yesNoRadio_pan"
                        checked={penalties}
                        onChange={() => setPenalties(true)}
                      />
                      <label className="label55" htmlFor="value9">
                        Yes
                      </label>
                    </div>
                    <div className="d-flex align-items-start">
                      <input
                        type="radio"
                        className="input-Topmargin"
                        id="value7"
                        name="yesNoRadio_pan"
                        checked={!penalties}
                        onChange={() => setPenalties(false)}
                      />
                      <label className="label55" htmlFor="value7">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-between">
              <button className="btn77Feasib" onClick={() => settabs(2)}>
                Back
              </button>
              <button
                className="btn77Feasib btn44-reachOut"
                onClick={handleReachOut}
              >
                Feeling Stuck, Reach Out!
              </button>
              <button className="btn77Feasib" onClick={() => settabs(4)}>
                Next
              </button>
            </div>
          </>
        )}

        {tabs === 4 && (
          <>
            <div className="divFeasiv555">
              <div className="mt-4" style={{ marginBottom: "8rem" }}>
                <label className="feasibLabel mb-2">
                  How do you plan to finance the solar system
                </label>
                <select
                  className="feasibInput"
                  value={finaceSolar}
                  onChange={(e) => setFinaceSolar(e.target.value)}
                >
                  <option value="Outright Purchase">Outright Purchase</option>
                  <option value="Lease">Lease</option>
                  <option value="Loan">Loan</option>
                  <option value="Power Purchase Agreement (PPA)">
                    Power Purchase Agreement (PPA)
                  </option>
                </select>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <button className="btn77Feasib" onClick={(e) => settabs(3)}>
                Back
              </button>
              <button
                className="btn77Feasib btn44-reachOut"
                onClick={handleReachOut}
              >
                Feeling Stuck, Reach Out!
              </button>
              <button className="btn77Feasib" onClick={(e) => handleSubmit(e)}>
                Submit
              </button>
            </div>
          </>
        )}
        {/* 
        {tabs === 5 && (
          <>
            <p className="mb-4">Why Dont You Upgrade</p>
            <p className="mb-4 text-center">Save Up To 80%</p>
            <p className="mb-5">No TO Upselling</p>
            <p className="mb-5">Personalised Recommendatuion</p>
            <p className="mb-5">
              Enhanced Capabilities such as battery charging, Demand Charging
              and savings from government incentives
            </p>
            <div className="d-flex justify-content-between">
              <button
                className="btn77Feasib"
                onClick={() => navigate("/upgrade-plan")}
              >
                Upgrade
              </button>
              <button
                className=" ms-3 btn78Addresss"
                onClick={() => navigate("/free-output")}
              >
                Connect to solar & battery supplier
              </button>
            </div>
          </>
        )} */}
      </div>
    </div>
  );
};

export default FreeEnergy;
