import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";

const FAQPage = () => {
  const [openCollapse1, setOpenCollapse1] = useState(false);
  const [openCollapse2, setOpenCollapse2] = useState(false);
  const [openCollapse3, setOpenCollapse3] = useState(false);
  const [openCollapse4, setOpenCollapse4] = useState(false);
  const [openCollapse5, setOpenCollapse5] = useState(false);
  const [openCollapse6, setOpenCollapse6] = useState(false);
  const [openCollapse7, setOpenCollapse7] = useState(false);
  const [openCollapse8, setOpenCollapse8] = useState(false);
  const [openCollapse9, setOpenCollapse9] = useState(false);
  const [openCollapse10, setOpenCollapse10] = useState(false);
  const [openCollapse11, setOpenCollapse11] = useState(false);
  const [openCollapse12, setOpenCollapse12] = useState(false);
  const [openCollapse13, setOpenCollapse13] = useState(false);
  const [openCollapse14, setOpenCollapse14] = useState(false);
  const [openCollapse15, setOpenCollapse15] = useState(false);
  const [openCollapse16, setOpenCollapse16] = useState(false);
  const [openCollapse17, setOpenCollapse17] = useState(false);
  const [openCollapse18, setOpenCollapse18] = useState(false);
  const [openCollapse19, setOpenCollapse19] = useState(false);
  const [openCollapse20, setOpenCollapse20] = useState(false);
  const [openCollapse21, setOpenCollapse21] = useState(false);
  const [openCollapse22, setOpenCollapse22] = useState(false);
  const [openCollapse23, setOpenCollapse23] = useState(false);
  const [openCollapse24, setOpenCollapse24] = useState(false);
  const [openCollapse25, setOpenCollapse25] = useState(false);
  const [openCollapse26, setOpenCollapse26] = useState(false);
  const [openCollapse27, setOpenCollapse27] = useState(false);
  const [loadMore, setloadMore] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="faq-container">
      <div className="faq-section1">
        <p className="faq-para11 mb-0">
          Below you'll find answers to the most common questions on Nature
          Electric. Also, please contact us if you would like a further
          discussion.
        </p>
      </div>
      <h2 className="faq-para124">Frequently Asked Questions</h2>

      <div className="faq-section2">
        <div className="faq-section2-flex">
          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse1(!openCollapse1)}
              // aria-controls="collapse1"
            >
              <span>
                {" "}
                What is Nature Electric Intelligent Renewables Estimator Platform?
              </span>
              {openCollapse1 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse1}>
              <div>
                <p className="faq-answerSection">
                  {" "}
                  This service is an online tool designed to provide businesses
                  with an estimate of the costs and benefits of installing a
                  solar panel system with battery storage. It takes into account
                  factors like location, energy needs, and local solar
                  incentives to generate personalized estimates. 
                  The outputs are estimates and a professional evaluation and financial consultation is required.
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse2(!openCollapse2)}
            >
              <span>How accurate are the estimates provided? </span>
              {openCollapse2 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse2}>
              <div>
                <p className="faq-answerSection">
                While we aim for accuracy, our estimates rely on your input and general solar energy data. 
                They provide a realistic starting point for decision-making and discussions. 
                For a precise estimate, we suggest a professional onsite evaluation through our partners or your chosen solar installer.
                 {" "}
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse5(!openCollapse5)}
            >
              <span>
                {" "}
                Is my personal information secure when using the service?{" "}
              </span>
              {openCollapse5 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse5}>
              <div>
                <p className="faq-answerSection">
                  We prioritize user privacy and data security. Your personal
                  information is used only for the purpose of providing you with
                  an estimate and is not shared without your consent.{" "}
                </p>
              </div>
            </Collapse>
          </div>
          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse6(!openCollapse6)}
            >
              <span>
                {" "}
                Why is 20 years considered as the solar installation lifetime?{" "}
              </span>
              {openCollapse6 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse6}>
              <div>
                <p className="faq-answerSection">
                  The 20-year lifespan is a standard industry assumption based
                  on the typical warranty and performance guarantee period
                  provided by most solar panel manufacturers. It is also the
                  duration given by the australian tax office (ATO). While solar
                  panels can last longer, their efficiency usually decreases
                  over time, and 20 years is considered a realistic period for
                  financial and performance calculations.{" "}
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse4(!openCollapse4)}
            >
              <span>
                {" "}
                How do I get started with installing a solar and battery system
                after receiving my estimate?
              </span>
              {openCollapse4 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse4}>
              <div>
                <p className="faq-answerSection">
                  After receiving your estimate, you can request a connection to
                  receive offers from our trusted installers to begin the
                  installation process.{" "}
                </p>
              </div>
            </Collapse>
          </div>

          <Collapse in={loadMore}>
            <div>
              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse8(!openCollapse8)}
                >
                  <span> How is the potential savings estimated? </span>
                  {openCollapse8 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse8}>
                  <div>
                    <p className="faq-answerSection">
                    We estimate potential savings by comparing your current electricity expenses with those after installing a solar and battery system. 
                We factor in system size, efficiency, expected energy output, and your usage patterns. 
                Savings also depend on feed-in tariffs for surplus energy sent to the grid. 
                We also consider future changes in electricity rates for a more holistic savings projection. 
                These are estimates and a professional evaluation and financial consultation is required.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse9(!openCollapse9)}
                >
                  <span>How are the LGCs, STCs, and VEECs estimated? </span>
                  {openCollapse9 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse9}>
                  <div>
                    <div className="faq-answerSection">
                      <ul>
                        <li>
                          <b>LGCs (Large-scale Generation Certificates)</b> are
                          estimated based on the expected annual energy
                          production of your system and the current market value
                          of LGCs.
                        </li>
                        <li>
                          <b>STCs (Small-scale Technology Certificates)</b> are
                          calculated by considering the expected electricity
                          generation or displacement over a set period (usually
                          deeming period is up to 15 years) and current STC
                          prices.
                        </li>
                        <li>
                          <b>
                            VEECs (Victorian Energy Efficiency Certificates)
                          </b>{" "}
                          are exclusive to Victoria and are calculated using
                          your system's energy consumption data. These
                          certificates are based on 12 months of energy
                          consumption and solar generation information, and they
                          are usually claimed between 14 to 20 months after
                          commissioning. With an approval rate averaging 80% to
                          90%, VEECs incentivize and reward energy-efficient
                          practices in Victoria.
                        </li>
                      </ul>
                      <p>
                        These certificates and credits form part of the
                        financial incentives for solar installations in
                        Australia and can significantly affect the overall
                        economics of your solar investment. 
                        These values are estimates and a professional evaluation and financial consultation is required.
                      </p>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse7(!openCollapse7)}
                >
                  <span>
                    {" "}
                    How is the demand charge calculated using different
                    electricity network providers in Australia?{" "}
                  </span>
                  {openCollapse7 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse7}>
                  <div>
                    <p className="faq-answerSection">
                      The demand charge in Australia varies depending on the
                      electricity network provider. It is typically calculated
                      based on the highest amount of power (in kW/kVA) drawn
                      from the grid during a specified period (often a month or
                      an annual rolling period), during peak hours. Each
                      provider has its own method for measuring this peak demand
                      and the specific time frames it applies to. Our service
                      takes into account the specific demand charge structure of
                      your network provider when estimating your potential costs
                      and savings. 
                      {" "}
                    </p>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse10(!openCollapse10)}
                >
                  <span>How is battery selection carried out? </span>
                  {openCollapse10 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse10}>
                  <div>
                    <div className="faq-answerSection">
                      <p>
                        Battery selection is carried out based on your
                        preferences and needs. Our service considers three main
                        factors:
                      </p>{" "}
                      <ul>
                        <li>
                          <b>Independence</b>If your goal is to maximize energy
                          independence, we recommend batteries with higher
                          capacity and durability.
                        </li>
                        <li>
                          <b>Cost</b>If cost is a primary concern, we focus on
                          cost-effective solutions that balance initial
                          investment with long-term savings.
                        </li>
                        <li>
                          <b>Peak Demand Shaving</b>If your objective is to
                          reduce peak demand charges, we recommend batteries
                          that are efficient in storing excess solar energy and
                          providing power during peak demand periods.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse11(!openCollapse11)}
                >
                  <span>How do you get the prices used in simulation? </span>
                  {openCollapse11 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse11}>
                  <div>
                    <p className="faq-answerSection">
                      {" "}
                      The electricity prices used are estimated averages based on the
                      electricity distributor area. The numbers can be changed
                      for the paid version.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse12(!openCollapse12)}
                >
                  <span>Why do I have a demand charge on my bill? </span>
                  {openCollapse12 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse12}>
                  <div>
                    <p className="faq-answerSection">
                      Demand charges on your electricity bill are based on the
                      highest amount of power (kW/kVA) you use during the slate
                      hours by your electricity provider. Even if you selected a
                      plan without a demand charge, if your electricity usage
                      pattern triggers a demand charge according to your
                      electricity supplier's policies, it will be reflected in
                      your bill. Our service's calculations take into account
                      these demand charges when estimating your potential costs
                      and savings.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse13(!openCollapse13)}
                >
                  <span>
                    I am the primary holder of my electricity account and I am
                    not receiving the one-time password. What should I do?
                  </span>
                  {openCollapse13 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse13}>
                  <div>
                    <p className="faq-answerSection">
                      This issue has occurred with some clients. Please contact
                      your electricity retailer directly to confirm that your
                      email is correctly set up for Consumer Data Right (CDR)
                      requests. They will be able to assist you in ensuring you
                      receive the one-time password and can access your account
                      details as needed.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse23(!openCollapse23)}
                >
                  <span>
                    {" "}
                    I selected battery but there is no battery option in the
                    output given?{" "}
                  </span>
                  {openCollapse23 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse23}>
                  <div>
                    <div className="faq-answerSection">
                      <p>
                        There are two reasons why you might not have seen the
                        battery show up.
                      </p>{" "}
                      <ol>
                        <li>
                          The amount of excess energy generated by the system is
                          too small that the use of battery is prohibitively
                          expensive and there is no point
                        </li>
                        <li>
                          The rooftop size is small to accommodate enough panels
                          that will generate enough energy required for the need
                          to have batteries
                        </li>
                      </ol>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse14(!openCollapse14)}
                >
                  <span>
                    What method is used to perform solar system sizing, and how
                    do you avoid the likelihood of the solar system being too
                    large, thus not delivering optimal economic return?{" "}
                  </span>
                  {openCollapse14 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse14}>
                  <div>
                    <p className="faq-answerSection">
                      Solar system sizing is performed using a combination of
                      your property's available space, energy usage, and local
                      solar irradiance data. We aim to balance the system size
                      with your energy needs, ensuring that it's neither too
                      large (which could be economically inefficient) nor too
                      small (which wouldn't meet your energy demands). We
                      consider roof size and local weather patterns to optimize
                      system size for economic returns.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>
            </div>
          </Collapse>
        </div>

        <div className="faq-section2-flex">
          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse3(!openCollapse3)}
            >
              <span>
                Can the service suggest the best solar panel and battery options
                for me?
              </span>
              {openCollapse3 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse3}>
              <div>
                <p className="faq-answerSection">
                  The service provides general recommendations based on your
                  energy needs and budget. For specific product suggestions,
                  it's best to consult with a solar energy professional.{" "}
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse15(!openCollapse15)}
            >
              <span>How does the service work? </span>
              {openCollapse15 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse15}>
              <div>
                <p className="faq-answerSection">
                  To use the service, simply enter some basic information about
                  your property, such as location, roof size,average daily
                  electricity usage, or better still for the paid version, fetch
                  your data automatically or upload a an energy file. Our
                  algorithm will then use this data, along with solar irradiance
                  data and average local electricity rates (that can be edited),
                  to provide an estimate for system size, costs, and potential
                  savings.{" "}
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse16(!openCollapse16)}
            >
              <span> Is the service free to use?</span>
              {openCollapse16 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse16}>
              <div>
                <p className="faq-answerSection">
                  {" "}
                  Yes, the free version allows estimation based on your industry
                  and average daily energy use. The paid version which is more
                  detailed and more features is for a fee.{" "}
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse17(!openCollapse17)}
            >
              <span>
                Does the service take local solar incentives into account?
              </span>
              {openCollapse17 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse17}>
              <div>
                <p className="faq-answerSection">
                  Yes, our tool includes information on local solar incentives
                  and rebates to provide a more comprehensive estimate.{" "}
                </p>
              </div>
            </Collapse>
          </div>

          <div className="collapse-section">
            <button
              className="faq-quesBtn"
              variant="primary"
              onClick={() => setOpenCollapse18(!openCollapse18)}
            >
              <span>
                {" "}
                Can you provide detailed cash flow data and assumptions around
                electricity price changes?{" "}
              </span>
              {openCollapse18 ? (
                <i class="fa-regular fa-minus"></i>
              ) : (
                <i class="fa-regular fa-plus"></i>
              )}
            </button>
            <Collapse in={openCollapse18}>
              <div>
                <p className="faq-answerSection">
                  Our service features an all estimated financial values including cashflow,
                  Net Present Value (NPV), Internal Rate of Return (IRR), and
                  payback period calculations. We use standard financial
                  formulas, factoring in initial investment, ongoing maintenance
                  costs, and projected savings. Assumptions about electricity
                  price fluctuations are based on historical data and current
                  market trends. We provide a conservative estimate to ensure an approximate
                  financial projection. 
                  These are estimates and a professional evaluation and financial consultation is required.{" "}
                </p>
              </div>
            </Collapse>
          </div>

          <Collapse in={loadMore}>
            <div>
              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse19(!openCollapse19)}
                >
                  <span>
                    {" "}
                    Can the service estimate the payback period for my
                    investment?
                  </span>
                  {openCollapse19 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse19}>
                  <div>
                    <p className="faq-answerSection">
                    he tool offers an estimate of financial metrics such as the payback period, 
                    considering factors like initial investment, ongoing savings, and local incentives. 
                    It's important to note that this is just an approximation. 
                    We are not qualified financial advisors, so for a more accurate estimate, 
                    it's advisable to consult with your financial advisor.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse20(!openCollapse20)}
                >
                  <span>
                    Who can I contact for support or more detailed questions?{" "}
                  </span>
                  {openCollapse20 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse20}>
                  <div>
                    <p className="faq-answerSection">
                      For additional support or specific inquiries, you can
                      contact our customer service team through the contact
                      details provided on our website.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse21(!openCollapse21)}
                >
                  <span>
                    {" "}
                    How is the amount of sunlight received at each site
                    estimated, and what source data is used?
                  </span>
                  {openCollapse21 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse21}>
                  <div>
                    <p className="faq-answerSection">
                      The amount of sunlight received at each site is estimated
                      using solar irradiance data, which includes historical
                      weather patterns and geographical location data. We use
                      databases from reputable sources like national
                      meteorological agencies and solar energy research
                      institutions to gather up-to-date solar
                      irradiance information.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse24(!openCollapse24)}
                >
                  <span> How is the energy charge calculated? </span>
                  {openCollapse24 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse24}>
                  <div>
                    <p className="faq-answerSection">
                      The energy charge is calculated based on the amount of
                      electricity (in kWh) that you consume from the grid. It's
                      a variable charge, depending on your usage. Rates can vary
                      by time of day (peak, shoulder, and off-peak rates) and
                      are set by your electricity provider. Our service
                      estimates energy charges by analyzing your historical
                      electricity usage patterns and applying the current rates
                      provided by your electricity supplier.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse25(!openCollapse25)}
                >
                  <span>How is the feed-in tariff estimated? </span>
                  {openCollapse25 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse25}>
                  <div>
                    <p className="faq-answerSection">
                      The feed-in tariff, which is the rate paid for electricity
                      fed back into the grid from your solar system, is
                      estimated based on current rates offered by your local
                      electricity provider or government incentives. These rates
                      can vary by region and over time. Our service checks the
                      latest available feed-in tariff rates for your area to
                      provide an estimate of potential earnings from
                      excess solar energy production.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse26(!openCollapse26)}
                >
                  <span>
                    {" "}
                    How are future carbon emission impacts measured?{" "}
                  </span>
                  {openCollapse26 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse26}>
                  <div>
                    <p className="faq-answerSection">
                      The future carbon emission impact is measured by
                      estimating the amount of electricity your solar system is
                      expected to generate and calculating the equivalent
                      reduction in carbon emissions. It also takes into account
                      that the grid will be getting greener by the year, thus
                      carbon abated will be reducing. This calculation is based
                      on the average emissions factor for grid electricity in
                      your area. By offsetting grid electricity, your solar
                      system reduces the overall carbon footprint, contributing
                      to environmental sustainability.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse22(!openCollapse22)}
                >
                  <span>
                    Can you provide annual service estimates of the proposed
                    systems?{" "}
                  </span>
                  {openCollapse22 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse22}>
                  <div>
                    <p className="faq-answerSection">
                    We provide annual estimates which include
                      projected energy production, expected savings, maintenance
                      costs, and overall estimated financial benefits. These estimates are
                      based on current energy prices, typical weather, rebates,
                      and estimated maintenance needs. 
                      These are estimates and a professional evaluation and financial consultation is required.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section">
                <button
                  className="faq-quesBtn"
                  variant="primary"
                  onClick={() => setOpenCollapse27(!openCollapse27)}
                >
                  <span>
                    I have demand charge on my bill, selected a demand charge on
                    the service, but there is no demand charge on the output?{" "}
                  </span>
                  {openCollapse27 ? (
                    <i class="fa-regular fa-minus"></i>
                  ) : (
                    <i class="fa-regular fa-plus"></i>
                  )}
                </button>
                <Collapse in={openCollapse27}>
                  <div>
                    <p className="faq-answerSection">
                      As the platform matches your energy use with your
                      electricity provider, if your current energy profile does
                      not match a tariff that has a demand charge, it won’t show
                      on the output page.{" "}
                    </p>
                  </div>
                </Collapse>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button className="faq-btn9" onClick={() => setloadMore(!loadMore)}>
          {loadMore ? "Load less" : "Load more"}
        </button>
      </div>
    </div>
  );
};

export default FAQPage;
