import React, { useState, useEffect } from "react";
import { Loader } from "../Loader";
import { CanvasJSChart } from "canvasjs-react-charts";

const SelectedCase = ({ loadingData, outputData }) => {
  const [graphDataOne, setGraphDataOne] = useState({});
  const [graphDataTwo, setGraphDataTwo] = useState({});
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      if (outputData) {
        let chartData = [];
        let chartData2 = [];

        const myData1 = outputData?.ca_graph1 || {};
        const myData2 = outputData?.sel_ca_graph2 || {};

        if (myData1) {
          const yearMonthData = outputData?.ca_graph1?.year_month || [];
          const formattedDates = yearMonthData;

          chartData = [
            {
              type: "stackedArea",
              name: "Battery to Grid",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.cleanest_graph1?.battery_to_grid?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
            {
              type: "stackedArea",
              name: "Grid kW",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.cleanest_graph1?.grid_kw?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
            {
              type: "stackedArea",
              name: "Renewable kW",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.cleanest_graph1?.renewable_kw?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
            {
              type: "stackedArea",
              name: "Solar to Grid",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.cleanest_graph1?.solar_to_grid?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
          ];
          if (
            chartData.some(
              (item) => item.dataPoints && item.dataPoints.length > 0
            )
          ) {
            setGraphDataOne(chartData);
          }
        }
        if (myData2) {
          const yearMonthData = outputData?.sel_ca_graph2?.new_date_time || [];
          const formattedDates = yearMonthData;

          chartData2 = [
            {
              type: "stackedArea",
              name: "Grid kW",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.sel_ca_graph2?.grid_kw?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
            {
              type: "stackedArea",
              name: "Battery to Grid",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.sel_ca_graph2?.battery_to_grid?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
            {
              type: "stackedArea",
              name: "Renewable kW",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.sel_ca_graph2?.renewable_kw?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
            {
              type: "stackedArea",
              name: "Solar to Grid",
              showInLegend: true,
              xValueFormatString: "MMM YYYY",
              dataPoints: outputData?.sel_ca_graph2?.solar_to_grid?.map?.(
                (value, index) => ({
                  x: new Date(formattedDates[index]),
                  y: value,
                })
              ),
            },
          ];
          if (
            chartData2.some(
              (item) => item.dataPoints && item.dataPoints.length > 0
            )
          ) {
            setGraphDataTwo(chartData2);
          }
        }
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      console.log("Something went wrong while getting data", error);
      setIsLoading(false);
    }
  }, [outputData]);

  const toggleDataSeries = (e) => {
    const prevData = [...graphDataOne];
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    setGraphDataOne(prevData);
  };

  const toggleDataSeries2 = (e) => {
    const prevData = [...graphDataTwo];
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    setGraphDataTwo(prevData);
  };

  const options1 = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    zoomEnabled: true,
    panEnabled: true,
    axisY: {
      title: "Energy Used",
    },
    axisX: {
      title: "Month",
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    data: graphDataOne,
  };

  const options2 = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    zoomEnabled: true,
    panEnabled: true,
    axisY: {
      title: "Energy Used",
    },
    axisX: {
      title: "Month",
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries2,
    },
    data: graphDataTwo,
  };

  return (
    <>
      <div className="container">
        <div className="Outputarea output1">
          {loading || loadingData ? (
            <Loader />
          ) : (
            <>
              <div className="accordianBox">
                <div className="accordion" id="myAccordion-selectedCase-one">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        className="accordion-button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Graph-selectedCase-one"
                      >
                        Graphical Summary
                      </button>
                    </h2>
                    <div
                      id="Graph-selectedCase-one"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#myAccordion-selectedCase-one"
                    >
                      <div className="chartBox">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="Perhour">
                              {graphDataOne && (
                                <>
                                  <h2>Daily Energy Use </h2>
                                  <CanvasJSChart options={options1} />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 mt-5 mb-5">
                            <div className="Perhour">
                              {graphDataTwo && (
                                <>
                                  <h2>Average Hourly Energy Use </h2>
                                  <CanvasJSChart options={options2} />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectedCase;
