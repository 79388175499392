import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import sidebar from "./sidebar";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import "./header.scss";
import { icons } from "../../../constants/imageContants";
import swal from "sweetalert";
import { LogoutAction } from "../../../Redux/actions/AuthAction";
import { useDispatch } from "react-redux";
import { PAGE_URLS } from "../../../constants";
import Footer from "./Footer";

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isHidden, setIsHidden] = useState(false);
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [userLogin, setuserLogin] = useState(false);

  const toggleVisibility = () => {
    setIsHidden(true);
  };

  const handleLogout = () => {
    dispatch(LogoutAction());
    swal({
      title: "",
      text: "Log out successfully!  ",
      className: "successAlert",
      icon: "/img/logo.svg",
      buttons: false,
      timer: 3000,
      open: true,
    });
    setTimeout(() => {
      window.location.reload();
      window.location.href = "/";
    }, 2000);
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data?.token) {
      setuserLogin(true);
      return;
    }
    setuserLogin(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".profilelinkto")) return;
      setIsHidden(false);
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".mobile-sidebar")) {
        setMobileSidebar(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth > 767) {
        setMobileSidebar(false);
      }
    };
    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const dropdownRefhome = useRef(null);

  useEffect(() => {
    dropdownRefhome.current.addEventListener("click", handleSelect);

    return () => {
      if (dropdownRefhome.current) {
        dropdownRefhome.current.removeEventListener("click", handleSelect);
      }
    };
  }, []);

  function handleSelect(event) {
    const selectedOption = event.target.value;
    navigate(selectedOption);
  }

  return (
    <div className="layoutdiv">
      {mobileSidebar && (
        <div className="mobile-sidebar">
          <div className="sidebar-closeIcon">
            <i
              className="fa-regular fa-xmark"
              onClick={() => setMobileSidebar(false)}
            ></i>
          </div>
          <div className="navbar11">
            <Link
              to={PAGE_URLS.DASHBOARD}
              onClick={() => setMobileSidebar(false)}
            >
              Landing Page
            </Link>

            <Link
              to={PAGE_URLS.PREMIUM_ENERGY}
              onClick={() => setMobileSidebar(false)}
            >
              Premium Energy
            </Link>

            <Link
              to={PAGE_URLS.GLOSSARY_PAGE}
              onClick={() => setMobileSidebar(false)}
            >
              Glossary
            </Link>

            <Link
              to={PAGE_URLS.FAQ_PAGE}
              onClick={() => setMobileSidebar(false)}
            >
              FAQ
            </Link>

            <Link
              to={PAGE_URLS.MORE_TRIALS}
              onClick={() => setMobileSidebar(false)}
            >
              More Trials
            </Link>

            <Link
              to={PAGE_URLS.PRICING}
              onClick={() => setMobileSidebar(false)}
            >
              Pricing
            </Link>

            <Link
              to={PAGE_URLS.PAYMENT_PAGE}
              onClick={() => setMobileSidebar(false)}
            >
              Payment
            </Link>
          </div>
        </div>
      )}

      <div className="header">
        <div className="headersec">
          <i
            className="fa fa-bars menuBars"
            aria-hidden="true"
            onClick={() => setMobileSidebar(true)}
          ></i>
          <div className="logo ms-2">
            <Link to={PAGE_URLS.DASHBOARD}>
              <img src={icons.Logo} alt="logo" />
            </Link>
          </div>
          <div className="desktop-nav">
            <div className="navbar11">
              <select id="dropdown1" ref={dropdownRefhome}>
                <option value="/">Homepage</option>
                <option value="/contact-us">Contact us</option>
                <option value="/pricing">Pricing</option>
              </select>
              <select id="dropdown2" onChange={handleSelect}>
                <option value="" selected disabled>
                  Solutions
                </option>
                <option value="/free-energy">
                  Solar & Battery Feasibility Tool
                </option>
                <option value="/more-trials">Smart Meter Analytics</option>
                <option value="/more-trails-contactUs">
                  100% Renewable Pathway
                </option>
              </select>
              <select id="dropdown3" onChange={handleSelect}>
                <option value="" selected disabled>
                  Resources
                </option>
                <option value="/faq">FAQs</option>
                <option value="/glossary">Glossary</option>
              </select>
              {userLogin && <Link to="/premium-energy">Premium Energy</Link>}
            </div>
          </div>
          <div className="profilesec">
            {userLogin ? (
              <>
                <button className="iconbtn" onClick={toggleVisibility}>
                  <i class="fa-solid fa-user Profileicontop"></i>
                </button>
                <button className="header-button12" onClick={handleLogout}>
                  Logout
                </button>
                {isHidden ? (
                  <div className="profilelinkto">
                    <li>
                      <Link
                        to={PAGE_URLS.PROFILE_PAGE}
                        onClick={() => setIsHidden(false)}
                      >
                        <i className="fa fa-user-circle-o Profileicon"></i>{" "}
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={PAGE_URLS.UPDATEPASSWORD_PAGE}
                        onClick={() => setIsHidden(false)}
                      >
                        <i className="fa fa-sign-out Profileicon"></i> Update
                        password
                      </Link>
                    </li>
                    {/* <li>
                      <Link onClick={handleLogout}>
                        <i className="fa fa-sign-out Profileicon"></i> Logout
                      </Link>
                    </li> */}
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <Link className="header-button11" to="/login">
                  Log in
                </Link>
                <Link className="header-button12" to="/register">
                  Sign up
                </Link>
              </>
            )}
          </div>
        </div>
      </div>

      <Outlet />

      <Footer />
    </div>
  );
};

export default Header;
