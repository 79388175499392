import React, { useEffect, useState, useRef } from "react";

const GoogleMap = ({
  fullAddress,
  setFullAddress,
  longitude,
  setLongitude,
  latitude,
  setLatitude,
  roofArea,
  setRoofArea,
  state,
  setstate,
  errors,
  setErrors,
}) => {
  const [searchInput, setSearchInput] = useState("");
  // const [fullAddress, setFullAddress] = useState("");
  // const [longitude, setLongitude] = useState("");
  // const [latitude, setLatitude] = useState("");
  // const [roofArea, setRoofArea] = useState(null); // Use null instead of an empty string for numerical values

  const mapRef = useRef(null); // Create a ref to hold the map instance
  let drawingManager;

  const handlePlaceChange = (place) => {
    if (!place.geometry || !mapRef.current) {
      // Handle cases where no place was selected or map is not initialized
      return;
    }

    const map = mapRef.current;
    setSearchInput(place.formatted_address);
    setFullAddress(place.formatted_address);
    setLongitude(place.geometry.location.lng());
    setLatitude(place.geometry.location.lat());
    const stateComponent = place.address_components?.find((component) => {
      return component?.types?.includes("administrative_area_level_1");
    });
    setstate && setstate(stateComponent?.short_name);
    // Update the map's center to the selected location
    map.setCenter(place.geometry.location);
  };

  useEffect(() => {
    // Load the Google Maps API with your API key
    const script = document.createElement("script");
    // script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCBxQbrVABSO6ZpjQ3TR1be7zJRPay_xzk&libraries=places,drawing,geometry&callback=initMap&libraries=places,drawing,geometry`;
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCBxQbrVABSO6ZpjQ3TR1be7zJRPay_xzk&libraries=places,drawing,geometry&libraries=places,drawing,geometry`;

    script.async = true;
    script.defer = true;

    script.onload = () => {
      // Initialize the map here
      const gmap = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: -37.840935, lng: 144.946457 },
        zoom: 19,
        mapTypeControl: false,
        mapTypeId: window.google.maps.MapTypeId.HYBRID,
      });

      // Store the map instance in the ref
      mapRef.current = gmap;

      // eslint-disable-next-line react-hooks/exhaustive-deps
      drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ["polygon"],
        },
        polygonOptions: {
          fillColor: "#85c192",
          strokeColor: "#85c192",
          fillOpacity: 0.5,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      });

      drawingManager.setMap(gmap);

      window.google.maps.event.addListener(
        drawingManager,
        "polygoncomplete",
        (polygon) => {
          // eslint-disable-next-line no-unused-vars
          const coordinates = polygon.getPath().getArray();
          const area = window.google.maps.geometry.spherical.computeArea(
            polygon.getPath()
          );

          // Update state with the area
          setRoofArea(area);
        }
      );

      // Initialize the Autocomplete
      initAutocomplete(gmap);

      // Initialize the Drawing Manager
      // drawingManager = new window.google.maps.drawing.DrawingManager({
      //   drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      //   drawingControl: true,
      //   drawingControlOptions: {
      //     position: window.google.maps.ControlPosition.TOP_CENTER,
      //     drawingModes: ["polygon"],
      //   },
      //   polygonOptions: {
      //     fillColor: "#85c192",
      //     strokeColor: "#85c192",
      //     fillOpacity: 0.5,
      //     strokeWeight: 5,
      //     clickable: false,
      //     editable: true,
      //     zIndex: 1,
      //   },
      // });

      // drawingManager.setMap(gmap);

      // Set up the event listener for when a polygon is completed
      window.google.maps.event.addListener(
        drawingManager,
        "polygoncomplete",
        (polygon) => {
          // eslint-disable-next-line no-unused-vars
          const coordinates = polygon.getPath().getArray();
          const area = window.google.maps.geometry.spherical.computeArea(
            polygon.getPath()
          );

          // Update state with the area
          setRoofArea(area);
        }
      );
    };

    document.head.appendChild(script);

    return () => {
      // Clean up the script tag to prevent memory leaks
      document.head.removeChild(script);
    };
  }, []);

  const initAutocomplete = (map) => {
    const input = document.getElementById("pac-input");
    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      types: ["geocode"],
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      handlePlaceChange(place);
    });
  };

  return (
    <div>
      <div id="addre">
        <label className="form-label">Site Address</label>
        <div id="pac-container">
          <input
            id="pac-input"
            className="form-control"
            type="text"
            placeholder="Enter a location"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
              setErrors &&
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  longlatitude: null,
                }));
            }}
          />
          {errors?.longlatitude && (
            <span className="errormsg55">{errors?.longlatitude}</span>
          )}
        </div>
      </div>
      <div id="infowindow-content">
        <span id="place-name" className="title"></span>
        <br />
        <span id="place-address"></span>
      </div>
      <div
        id="map"
        style={{ display: "block", width: "100%", height: "300px" }}
      ></div>
      <div className="mb-3 mt-3">
        <label className="form-label">Full Address</label>
        <input
          id="full_add"
          type="text"
          placeholder="Address"
          className="form-control"
          disabled={true}
          value={fullAddress}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Longitude</label>
        <input
          id="longitude"
          type="text"
          placeholder="Longitude"
          className="form-control"
          disabled={true}
          value={longitude}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Latitude</label>
        <input
          id="latitude"
          type="text"
          placeholder="Latitude"
          className="form-control"
          disabled={true}
          value={latitude}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Roof Size</label>
        <input
          id="roof_area"
          type="text"
          placeholder="Roof Area"
          className="form-control"
          disabled={true}
          value={roofArea === null ? "" : roofArea?.toFixed(2)} // Format the roof area as a number with two decimal places
        />
      </div>
    </div>
  );
};

export default GoogleMap;
