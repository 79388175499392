import React, { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import image1 from "../assets/images/AboutusImagee (1).svg";

const AboutUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="aboutus-container">
      <div className="aboutus-section1">
        <h2 className="aboutus-heading11">
          Our end-to-end solution simplifies the transition to renewable energy
          and achieving net-zero
        </h2>{" "}
        <p className="aboutus-para33">
          At Nature Electric, we offer a comprehensive end-to-end solution for
          your renewable energy needs.Harnessing advanced technology, we automate
          renewable energy transition strategies faster at low-cost with
          tailored solutions. With immediate quotes from a range of installers
          and insightful monthly reports on energy usage, environmental
          footprint, and savings right on our dashboard, we've simplified and
          enriched every step of your renewable journey. Embrace the future of
          energy seamlessly with us.
        </p>
      </div>
      <div className="aboutus-section2">
        <div className="aboutus-flexchild">
          <div className="aboutusdiv33">
            <div>
              <h2 className="aboutus-heading12">Our Mission</h2>
              <p className="aboutus-parat66 ">
                Enable our next generation experience nature in its full beauty
              </p>
            </div>
            <div>
              <h2 className="aboutus-heading12">Our Vision</h2>
              <p className="aboutus-parat66 ">
                Support organisations in making informed decisions in their
                Renewable and net-zero journey
              </p>
            </div>
          </div>
        </div>
        <div className="aboutus-flexchild2">
          <img src={image1} alt="image" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
