import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import video1 from "../../assets/videos/MappedRoof.mp4";

const GoogleMap = ({
  setFullAddress,
  setLongitude,
  setLatitude,
  roofArea,
  setRoofArea,
  setstate,
  setPinCode,
  mapYourRoof,
  setMapYourRoof,
  errors,
  setErrors,
}) => {

  const [searchInput, setSearchInput] = useState("");
  const [showSampledemo, setshowSampledemo] = useState(false);

  const mapRef = useRef(null); // Create a ref to hold the map instance
  let drawingManager;
  let totalArea = 0;

  const handlePlaceChange = (place) => {
    if (!place.geometry || !mapRef.current) {
      // Handle cases where no place was selected or map is not initialized
      return;
    }

    const map = mapRef.current;
    setSearchInput(place.formatted_address);
    setFullAddress && setFullAddress(place.formatted_address);
    errors &&
      setErrors((prevErrors) => ({
        ...prevErrors,
        ShowErrorAddress: null,
      }));
    setLongitude && setLongitude(place.geometry.location.lng());
    setLatitude && setLatitude(place.geometry.location.lat());

    const stateComponent = place.address_components?.find((component) => {
      return component?.types?.includes("administrative_area_level_1");
    });
    setstate && setstate(stateComponent?.short_name);

    // Extract the PIN code from the address components
    const pinCodeComponent = place.address_components?.find((component) => {
      return component?.types?.includes("postal_code");
    });
    setPinCode && setPinCode(pinCodeComponent?.short_name);
    // Update the map's center to the selected location
    map.setCenter(place.geometry.location);
  };

  useEffect(() => {
    // Load the Google Maps API with your API key
    const script = document.createElement("script");
    // script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCBxQbrVABSO6ZpjQ3TR1be7zJRPay_xzk&libraries=places,drawing,geometry&callback=initMap&libraries=places,drawing,geometry`;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places,drawing,geometry`;

    script.async = true;
    script.defer = true;

    script.onload = () => {
      // Initialize the map here
      const gmap = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: -37.8179789, lng: 144.9690576 },
        zoom: 19,
        mapTypeControl: false,
        mapTypeId: window.google.maps.MapTypeId.HYBRID,
      });

      // Store the map instance in the ref
      mapRef.current = gmap;

      // eslint-disable-next-line react-hooks/exhaustive-deps
      drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ["polygon"],
        },
        polygonOptions: {
          fillColor: "#85c192",
          strokeColor: "#85c192",
          fillOpacity: 0.5,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      });

      mapYourRoof && drawingManager.setMap(gmap);

      // Initialize the Autocomplete
      // initAutocomplete(gmap);
      if (document.getElementById("pac-input")) {
        initAutocomplete(gmap);
      }
      window.google.maps.event.addListener(
        drawingManager,
        "polygoncomplete",
        (polygon) => {
          // eslint-disable-next-line no-unused-vars
          const coordinates = polygon.getPath().getArray();
          const area = window.google.maps.geometry.spherical.computeArea(
            polygon.getPath()
          );

          totalArea += area;
          setRoofArea(totalArea);

          errors &&
            setErrors((prevErrors) => ({
              ...prevErrors,
              ShowErrorArea: null,
            }));
        }
      );
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [mapYourRoof]);

  const initAutocomplete = (map) => {
    const input = document.getElementById("pac-input");
    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      types: ["geocode"],
      componentRestrictions: { country: "AU" },
      fields: ["formatted_address", "geometry", "address_components"],
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      handlePlaceChange(place);
    });
  };
  const handleYesNo = (bool) => {
    setMapYourRoof(bool);
    setSearchInput("");
    setFullAddress("");
  };

  return (
    <>
      <div className="mb-5">
        <label className="feasibLabel mb-2">
          Do you want to map your roof for appropriate solar system
          recommendation
        </label>
        <div className="d-flex">
          <div className="address-div44">
            <input
              type="radio"
              className="input-Topmargin"
              id="value8"
              checked={mapYourRoof}
              // onClick={() => setMapYourRoof(true)}
              onChange={() => handleYesNo(true)}
            />
            <label className="Address-label66" htmlFor="value8">
              Yes (Map my roof)
            </label>
          </div>
          <div className="address-div44">
            <input
              type="radio"
              className="input-Topmargin"
              id="value7"
              checked={!mapYourRoof}
              // onChange={() => setMapYourRoof(false)}
              onChange={() => handleYesNo(false)}
            />
            <label className="Address-label66" htmlFor="value7">
              No (Give the recommendation regardless of how big is the roof)
            </label>
          </div>
        </div>
      </div>
      <div id="addre" className="mb-5">
        <label className="feasibLabel mb-2">Please provide your address</label>
        <div id="pac-container">
          <input
            id="pac-input"
            className="feasibInput"
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
        <span
          style={{
            color: "red",
            textAlign: "end",
            fontSize: "13px",
            opacity: errors?.ShowErrorAddress ? 1 : 0,
          }}
        >
          {errors?.ShowErrorAddress ?? "valid"}
        </span>
      </div>

      <label className="feasibLabel">
        Outline your rooftop on the satellite image by clicking to map its
        shape. {" "}
        <span
          className="basicEnergy-span3333t"
          onClick={() => setshowSampledemo(true)}
        >
          This sample demo will help you map the roof
        </span>
      </label>
      <div id="infowindow-content">
        <span id="place-name" className="title"></span>
        <br />
        <span id="place-address"></span>
      </div>
      <div
        id="map"
        style={{ display: "block", width: "100%", height: "360px" }}
        className="mb-3"
      ></div>

      <div className="mb-3">
        <label className="feasibLabel">Roof Size</label>
        <input
          id="roof_area"
          type="text"
          className="feasibInput"
          disabled={true}
          value={roofArea ? roofArea?.toFixed(2) : ""}
        />
        <span
          style={{
            color: "red",
            textAlign: "end",
            fontSize: "13px",
            opacity: errors?.ShowErrorArea ? 1 : 0,
          }}
        >
          {errors?.ShowErrorArea ?? "valid"}
        </span>
      </div>
      <Modal show={showSampledemo} class="ravi" id="modal-sampleDemo">
        <div className="basicEnergy-modalDiv333">
          <div className="d-flex justify-content-end">
            <i
              class="fa-regular fa-xmark mb-2 text-white"
              style={{ cursor: "pointer" }}
              onClick={() => setshowSampledemo(false)}
            ></i>
          </div>
          <div className="sampledemoDiv99">
            <ReactPlayer
              url={video1}
              type="video/mp4"
              controls={true}
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GoogleMap;
