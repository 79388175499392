import { urls } from "../constants";
import { apiService } from "./api.service";

async function oututPage(inputId) {
  return apiService.get(`${urls.OUTPUTPAGE}?input_id=${inputId}`);
}

async function oututPageData(inputId) {
  return apiService.get(`${urls.OUTPUTPAGEDATA}?input_id=${inputId}`);
}

async function outputPageOne(inputId) {
  return apiService.get(`${urls.OUTPUTPAGEONE}?input_id=${inputId}`);
}

async function outputPageOnePost(payload) {
  return apiService.post(`${urls.OUTPUTPAGEONE}`, payload);
}


export const outputPageService = {
  oututPage,
  outputPageOne,
  oututPageData,
  outputPageOnePost
};
