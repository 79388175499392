import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk"; // Correctly import thunk as a named export
import { combineReducers } from "redux";
import {
  LoginReducer,
  RegisterReducer,
  VerifyEmailReducer,
  ChangePasswordReducer,
  ForgotPasswordReducer,
  SetForgotPasswordAction,
  EditRegisterDataReducer,
  ProfileDetailsGetReducer,
  GoogleReducer,
} from "./reducers/AuthReducer";
import {
  FreeOutputGetReducer,
  BasicOutputGetReducer,
  FeasibilityPostReducer,
  PlanInputPostReducer,
  FeasibilityTab1PostReducer,
  UploadHistoryGetReducer,
  UploadHistoryDeleteReducer,
  DeleteProfileReducer,
  EmailDataGetReducer,
  RedirectFetchGetReducer,
  FeeOutPutPostReducer,
  FreeEnergyPostReducer,
  FreeEnergyFetchReducer,
  ContactusPostReducer,
  ConnectionPostReducer,
  MoreTrailsPostReducer,
  SurveyFormsGetReducer,
  SurveyFormsPostReducer,
} from "./reducers/PublicReducer";

const reducer = combineReducers({
  authReducer: LoginReducer,
  RegisterReducer,
  VerifyEmailReducer,
  ChangePasswordReducer,
  ForgotPasswordReducer,
  SetForgotPasswordAction,
  EditRegisterDataReducer,
  ProfileDetailsGetReducer,
  GoogleReducer,
  FeasibilityPostReducer,
  FreeOutputGetReducer,
  BasicOutputGetReducer,
  PlanInputPostReducer,
  FeasibilityTab1PostReducer,
  UploadHistoryGetReducer,
  UploadHistoryDeleteReducer,
  DeleteProfileReducer,
  EmailDataGetReducer,
  RedirectFetchGetReducer,
  FeeOutPutPostReducer,
  FreeEnergyPostReducer,
  FreeEnergyFetchReducer,
  ContactusPostReducer,
  ConnectionPostReducer,
  MoreTrailsPostReducer,
  SurveyFormsGetReducer,
  SurveyFormsPostReducer,
});

const userDataLocal = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : null;

const initialState = {
  authReducer: { userData: userDataLocal },
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  preloadedState: initialState,
});

export default store;
