import React, { useEffect, useState } from "react";
import { icons } from "../../constants/imageContants";
import { outputPageService } from "../../services/outputPage.service";
import { CanvasJSChart } from "canvasjs-react-charts";
import { Loader } from "../Loader";

const BaseLead = ({ loadingData, outputData }) => {
  const [data, setData] = useState([]);
  const [graphDataOne, setGraphDataOne] = useState({});
  const [graphDataTwo, setGraphDataTwo] = useState({});
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      if (outputData) {
        let options1;
        const myData1 = outputData?.usual?.graph1 || {};
        const myData2 = outputData?.usual?.graph2 || {};

        if (myData1) {
          const dates = outputData?.usual?.graph1?.date;
          const energyUse = outputData?.usual?.graph1?.energy_use;

          const dataPoints = dates?.map((date, index) => ({
            y: energyUse[index],
            label: date,
          }));

          options1 = {
            animationEnabled: true,
            exportEnabled: true,
            theme: "light2",
            zoomEnabled: true,
            panEnabled: true,
            axisY: {
              title: "Energy Used",
            },
            axisX: {
              title: "Date",
            },
            data: [
              {
                type: "line",
                name: "Energy Used (kWh)",
                dataPoints: dataPoints,
              },
            ],
          };
        }
        setGraphDataOne(options1);

        let options2;

        if (myData2) {
          const dates = outputData?.usual?.graph2?.date;
          const energyUse = outputData?.usual?.graph2?.energy_use;

          const dataPoints = dates?.map((date, index) => ({
            y: energyUse[index],
            label: date,
          }));

          options2 = {
            animationEnabled: true,
            exportEnabled: true,
            theme: "light2",
            zoomEnabled: true,
            panEnabled: true,
            axisY: {
              title: "Energy Used",
            },
            axisX: {
              title: "Hours",
            },
            data: [
              {
                type: "line",
                name: "Energy Used (kWh)",
                dataPoints: dataPoints,
              },
            ],
          };
        }
        setGraphDataTwo(options2);
      }
    } catch (error) {
      // console.log("Something went wrong while getting data", error);
    }
  }, [outputData]);

  useEffect(() => {
    fetchBaseLeadCards();
  }, []);

  const fetchBaseLeadCards = async () => {
    try {
      const inputId = localStorage.getItem("nature_electric_input_id");
      if (inputId) {
        const results = await outputPageService.oututPage(inputId);
        setData(results || []);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const commasAfterThreeDigits = (value) => {
    if (typeof value === "string") {
      return value;
    }
    if (value === 0) {
      return "0";
    } else if (!isNaN(value)) {
      return value.toLocaleString();
    } else {
      return "N/A";
    }
  };

  const yearDiff = (value) => {
    const currentYear = new Date().getFullYear();
    const yearDifference = value - currentYear;
    return commasAfterThreeDigits(yearDifference);
  };

  return (
    <>
      {loading || loadingData ? (
        <Loader />
      ) : (
        <div className="row">
          {data?.map((item, index) => {
            if (index == 0) {
              return (
                <div key={index} className="col-md-3">
                  <div
                    style={{ backgroundColor: `${item.color}` }}
                    className="cardBox"
                  >
                    <p>{item.description}</p>
                    <div className="expenceBox">
                      <div>
                        <h2>{item.value}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}

          <div className="col-md-3">
            <div style={{ backgroundColor: `#F5FACD` }} className="cardBox">
              <p>Overall electricity expense</p>
              <div className="expenceBox">
                <div>
                  <h2>${outputData?.cleanest?.[0]?.[13]}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div style={{ backgroundColor: `#E5F7FF` }} className="cardBox">
              <p>Carbon emission analysis period</p>
              <div className="expenceBox">
                <div>
                  <h2>{yearDiff(data?.[0]?.value)}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="chartBox">
            <div className="row">
              <div className="col-md-12">
                <div className="Perhour">
                  {graphDataOne && (
                    <>
                      <h2>Daily Energy Use </h2>
                      <CanvasJSChart options={graphDataOne} />
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-5 mb-5">
                <div className="Perhour">
                  {graphDataTwo && (
                    <>
                      <h2>Average Hourly Energy Use </h2>
                      <CanvasJSChart options={graphDataTwo} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BaseLead;
