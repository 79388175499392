import React, { useState } from "react";
import { Loader } from "../Loader";

const Comparison = ({ loadingData, outputData }) => {
  const [loading, setIsLoading] = useState(false);

  const tableDataProps = outputData?.comparison;

  const commasAfterThreeDigits = (value) => {
    if (typeof value === "string") {
      return value;
    }
    if (value === 0) {
      return "0";
    } else if (!isNaN(value)) {
      return value.toLocaleString();
    } else {
      return "N/A";
    }
  };

  return (
    <>
      {loading || loadingData ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="Outputarea output1">
            <h2 className="">Output Page 1</h2>
            <div className="pageheading">
              <h3>Solar & Battery</h3>
            </div>

            <div className="accordianBox">
              <div className="accordion" id="myAccordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      type="button"
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Graph"
                    >
                      Graphical Summary
                    </button>
                  </h2>
                  <div
                    id="Graph"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#myAccordion"
                  >
                    <div className="chartBox">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="Perhour">
                            <h2>Energy Usage Per hour </h2>
                            {/* <img
                            style={{ width: "100%" }}
                            src={`${process.env.REACT_APP_API_URL}common/generate-energy-use-plot-by-month/ `}
                            alt="chart1"
                          /> */}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="Perhour">
                            <h2>Energy Usage Per Day </h2>
                            {/* <img
                              style={{ width: '100%' }}
                              src={`${process.env.REACT_APP_API_URL}common/generate-energy-use-plot-by-hour/ `}
                              alt="chart2"
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordianBox">
              <div className="accordion" id="myAccordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      type="button"
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Solar"
                    >
                      Result comparison
                    </button>
                  </h2>
                  <div
                    id="Solar"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#myAccordion"
                  >
                    <div className="card-body">
                      <div className="resultTable">
                        {tableDataProps ? (
                          <table className="table table-borderless">
                            <thead>
                              <tr>
                                <th>Information</th>
                                <th>Business As Usual</th>
                                <th>Cheapest Case</th>
                                <th>Cleanest Case</th>
                                <th>Selected Case</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={6} className="borderBottom">
                                  System Size
                                </td>
                              </tr>
                              <tr>
                                <td>PV Size</td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][0])}{" "}
                                  kW
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][0])}{" "}
                                  kW
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][0])}{" "}
                                  kW
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[3][0])}{" "}
                                  kW
                                </td>
                              </tr>
                              <tr className="mp-5">
                                <td>Battery Capacity</td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][1])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][1])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][1])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[3][1])}{" "}
                                  kWh
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={6} className="borderBottom">
                                  Energy Production
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Average Annual PV Energy Production to Site
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][2])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][2])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][2])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[3][2])}{" "}
                                  kWh
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Average Annual Energy Supplied from Grid
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][3])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][3])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][3])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[3][3])}{" "}
                                  kWh
                                </td>
                              </tr>
                              <tr>
                                <td>Average Annual Energy Supplied from PPA</td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][4])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][4])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][4])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[3][4])}{" "}
                                  kWh
                                </td>
                              </tr>
                              <tr>
                                <td>Average Annual Clean Energy Supplied </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][0])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][5])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][5])}{" "}
                                  kWh
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[3][5])}{" "}
                                  kWh
                                </td>
                              </tr>
                              {/* <tr>
                                <td colSpan={6} className="borderBottom">
                                  Renewable Energy Metrics
                                </td>
                              </tr>
                              <tr>
                                <td>Annual Renewable Electricity</td>
                                <td>{tableDataProps[0][6]}%</td>
                                <td>{tableDataProps[1][6]}%</td>
                                <td>{tableDataProps[2][6]}%</td>
                                <td>{tableDataProps[3][6]}%</td>
                              </tr> */}
                              <tr>
                                <td colSpan={6} className="borderBottom">
                                  Carbon Footprint
                                </td>
                              </tr>
                              <tr>
                                <td>CO₂ Emissions in Year 1 </td>
                                <td>{tableDataProps[0][7]} tons</td>
                                <td>{tableDataProps[1][7]} tons</td>
                                <td>{tableDataProps[2][7]} tons</td>
                                <td>{tableDataProps[3][7]} tons</td>
                              </tr>
                              <tr>
                                <td>Percent Reduction in CO₂ Emissions</td>
                                <td>{tableDataProps[0][8]}%</td>
                                <td>{tableDataProps[1][8]}%</td>
                                <td>{tableDataProps[2][8]}%</td>
                                <td>{tableDataProps[3][8]}%</td>
                              </tr>
                              <tr>
                                <td>
                                  Total CO₂ Emissions in the transistion period{" "}
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[0][9])}{" "}
                                  tons
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[1][9])}{" "}
                                  tons
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[2][9])}{" "}
                                  tons
                                </td>
                                <td>
                                  {commasAfterThreeDigits(tableDataProps[3][9])}{" "}
                                  tons
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={6} className="borderBottom">
                                  Year 1 Utility Electricity Cost — Before Tax
                                </td>
                              </tr>
                              <tr>
                                <td>Utility Energy Cost </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][10]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][10]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][10]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[3][10]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Utility Demand Cost </td>
                                <td>${tableDataProps[0][11]}</td>
                                <td>${tableDataProps[1][11]}</td>
                                <td>${tableDataProps[2][11]}</td>
                                <td>${tableDataProps[3][11]}</td>
                              </tr>
                              <tr>
                                <td>Utility Fixed Cost </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][12]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][12]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][12]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[3][12]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Total Year 1 Utility Cost - Before Tax</td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][13]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][13]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][13]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[3][13]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={6} className="borderBottom">
                                  Life Cycle Cost Breakdown
                                </td>
                              </tr>
                              <tr>
                                <td>Technology Capital Costs </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][14]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][14]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][14]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[3][14]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>O&M Costs </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][15]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][15]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][15]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[3][15]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Total Utility Electricity Cost (Transition){" "}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][16]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][16]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][16]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[3][16]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Total Utility Electricity Cost </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][17]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][17]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][17]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[3][17]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Total Life Cycle Cost </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][18]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][18]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][18]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[3][18]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={6} className="borderBottom">
                                  Summary Financial Metrics
                                </td>
                              </tr>
                              <tr>
                                <td>Net Present Value </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[0][21]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[1][21]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[2][21]
                                  )}
                                </td>
                                <td>
                                  $
                                  {commasAfterThreeDigits(
                                    tableDataProps[3][21]
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Payback Period </td>
                                <td>{tableDataProps[0][19]} years</td>
                                <td>{tableDataProps[1][19]} years</td>
                                <td>{tableDataProps[2][19]} years</td>
                                <td>{tableDataProps[3][19]} years</td>
                              </tr>
                              <tr>
                                <td>Discounted Payback Period </td>
                                <td>{tableDataProps[0][20]} years</td>
                                <td>{tableDataProps[1][20]} years</td>
                                <td>{tableDataProps[2][20]} years</td>
                                <td>{tableDataProps[3][20]} years</td>
                              </tr>
                              <tr>
                                <td>Internal Rate of Return </td>
                                <td>{tableDataProps[0][22]}%</td>
                                <td>{tableDataProps[1][22]}%</td>
                                <td>{tableDataProps[2][22]}%</td>
                                <td>{tableDataProps[3][22]}%</td>
                              </tr>
                            </tbody>
                          </table>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Comparison;
