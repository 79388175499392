import React, { useState } from "react";
import { Link } from "react-router-dom";
import image1 from "../assets/images/ImageDash11.jpg";
import image2 from "../assets/images/Image (144).jpg";
import image3 from "../assets/images/Image123e.jpg";
import ReactPlayer from "react-player";
import video1 from "../assets/videos/SmartMeterAnalytics.mp4";
import video2 from "../assets/videos/RenewablePathway.mp4";

const MoreTrials = () => {
  return (
    <div className="moreTrails-container">
      <div className="moretrails-flex1">
        <div className="moretrails-flex22-a">
          <h2 className="moretrails-heading1">
            Start your journey to renewable energy with more of our services
          </h2>
          <div className="moreTr-btnDiv">
            <Link to={`/more-trails-contactUs?value=Smart Meter Analytics`}>
              <button className="moreTrials-btn22">
                Smart Meter Analytics
              </button>
            </Link>
            <Link to="/more-trails-contactUs?value=100% Renewable Pathway">
              <button className="moreTrials-btn22">
                100% renewable pathway
              </button>
            </Link>
          </div>
        </div>
        <div className="moretrails-flex22-b ">
          <img className="dashboard-img11" src={image1} alt="trials img" />
          <img className="dashboard-img12" src={image2} alt="trials img" />
          <img className="moretrails-img13" src={image3} alt="trials img" />
        </div>
      </div>

      <div className="moretrail-section2">
        <div className="moretrails-section2flex1">
          <div className="moretrailsDiv-444">
            <ReactPlayer
              url={video1}
              type="video/mp4"
              controls={true}
              playing={false}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className="moretrails-section2flex2">
          <h2 className="moretrails-heading2">Smart Meter Analytics</h2>
          <p className="moretrails-para27">
            Manage accounts, cards, and energy savings effortlessly.
          </p>
          <Link to={`/more-trails-contactUs?value=Smart Meter Analytics`}>
            <button className="moreTrials-btn12">Register your interest</button>
          </Link>
        </div>
      </div>

      <div className="moretrails-section3flex">
        <div className="moretrails-section3flex1">
          <h2 className="moretrails-heading2 text323Color">
            100% Renewable Pathway
          </h2>
          <p className="moretrails-para29">
            Our streamlined approach to energy management allows you to focus on
            what matters most. No more hassle, just results.
          </p>

          <Link to="/more-trails-contactUs?value=100% Renewable Pathway">
            <button className="moreTrials-btn12 btn555color">
              {" "}
              Register for 100% Renewable Pathway
            </button>
          </Link>
        </div>
        <div className="moretrails-section2flex1">
          <div className="moretrailsDiv-444">
            <ReactPlayer
              url={video2}
              type="video/mp4"
              controls={true}
              playing={false}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreTrials;
