// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 320px) and (max-width: 767px) {
  .header {
    width: 100% !important;
  }
}
@media only screen and (min-width: 767px) and (max-width: 992px) {
  .header {
    width: 100% !important;
  }
}
.header {
  height: 76px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.01), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
}
.header .profile img {
  width: 45px;
  border-radius: 10px;
}

@media only screen and (min-width: 1420px) and (max-width: 2200px) {
  .header {
    height: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Header/header.scss"],"names":[],"mappings":"AAAA;EACE;IACE,sBAAA;EACF;AACF;AACA;EACE;IACE,sBAAA;EACF;AACF;AAGA;EACE,YAAA;EACA,yBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,6EAAA;EACA,eAAA;EACA,WAAA;EACA,MAAA;EACA,aAAA;AADF;AAII;EACE,WAAA;EACA,mBAAA;AAFN;;AAQA;EACE;IACE,aAAA;EALF;AACF","sourcesContent":["@media only screen and (min-width: 320px) and (max-width: 767px) {\n  .header {\n    width: 100% !important;\n  }\n}\n@media only screen and (min-width: 767px) and (max-width: 992px) {\n  .header {\n    width: 100% !important;\n  }\n}\n\n\n.header {\n  height: 76px;\n  background-color: #ffffff;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 1.5rem;\n  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 1%), 0 6px 20px 0 rgb(0 0 0 / 5%);\n  position: fixed;\n  width: 100%;\n  top: 0;\n  z-index: 9999;\n\n  .profile {\n    img {\n      width: 45px;\n      border-radius: 10px;\n    }\n  }\n}\n\n\n@media only screen and (min-width: 1420px) and (max-width: 2200px) {\n  .header {\n    height: 100px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
