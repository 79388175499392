import React, { useState, useEffect, useCallback } from "react";
import { BACKEND_URL } from "../environment";
import axios from "axios";
import {
  getRegisterDataAction,
  EditRegisterDataAction,
  LogoutAction,
} from "../Redux/actions/AuthAction";
import {
  UploadHistoryGetData,
  UploadHistoryDelete,
  DeleteProfile,
} from "../Redux/actions/PublicActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validations } from "../utils";
import { Link } from "react-router-dom";
import swal from "sweetalert";
// import Loader from "../Loader/Loader";
import { Loader } from "../components/Loader";
import { useDropzone } from "react-dropzone";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { saveAs } from "file-saver";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.authReducer);
  const { ProfileDetailsGet } = useSelector(
    (state) => state.ProfileDetailsGetReducer
  );
  const { data: historyData } = useSelector(
    (state) => state.UploadHistoryGetReducer
  );
  const { data: deleteMsg, error: deleteError } = useSelector(
    (state) => state.UploadHistoryDeleteReducer
  );
  const { data: deleteProfileMsg, error: deleteProfileError } = useSelector(
    (state) => state.DeleteProfileReducer
  );
  const { success, error, message, loading } = useSelector(
    (state) => state.EditRegisterDataReducer
  );

  const [isLoading, setIsLoading] = useState(true);
  const [rerender, setRerender] = useState(0);
  const [activetab, setactivetab] = useState(1);
  const [eventImage, setEventImage] = useState([]);
  const [imageChanged, setImageChanged] = useState(false);
  const [registerData, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
  });
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    minSize: 0,
    multiple: false,
    onDrop: useCallback(
      (acceptedFiles) => {
        setEventImage(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              title: file?.name,
            })
          )
        );
        setImageChanged(true);
      },
      [eventImage]
    ),
  });

  useEffect(() => {
    dispatch(UploadHistoryGetData());
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (deleteMsg?.message && rerender == 2) {
        swal({
          text: deleteMsg?.message,
          className: "successAlert",
          icon: "success",
          buttons: false,
          timer: 3000,
        });
        setRerender(0);
        await dispatch(UploadHistoryGetData());
      }
      if (deleteProfileMsg?.message && rerender == 3) {
        setRerender(0);
        await dispatch(LogoutAction());
        navigate("/register");
      }
    };
    getData();
  }, [deleteMsg, deleteProfileMsg]);

  useEffect(() => {
    if (deleteError && rerender == 2) {
      swal({
        text: deleteError,
        // className: "successAlert",
        icon: "error",
        buttons: false,
        timer: 3000,
      });
      setRerender(0);
    }
    if (deleteProfileError && rerender == 3) {
      swal({
        text: deleteProfileError,
        icon: "error",
        buttons: false,
        timer: 3000,
      });
      setRerender(0);
    }
  }, [deleteError, deleteProfileError]);

  const handleStateChange = (e, fieldName) => {
    const { value } = e.target;

    setRegisterData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    setErrors(() => ({
      ...errors,
      [fieldName]: null,
    }));
  };

  const validateSubmit = (e) => {
    e?.preventDefault();
    const tempErrors = {
      firstName: validations.firstName(registerData.firstName),
      lastName: validations.lastName(registerData.lastName),
      phoneNumber: validations.mobileNumber(registerData.phoneNumber),
      email: validations.email(registerData.email),
    };

    setErrors(tempErrors);

    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    handleSubmitData();
  };

  const handleSubmitData = async () => {
    const formData = new FormData();
    formData.append("first_name", registerData?.firstName);
    formData.append("last_name", registerData?.lastName);
    formData.append("email", registerData?.email);
    formData.append("phone_number", registerData?.phoneNumber);
    formData.append("address", registerData?.address);

    if (imageChanged) {
      formData.append("user_profile.image", eventImage[0]);
    }
    dispatch(EditRegisterDataAction(formData));
    setIsLoading(false);
    setRerender(1);
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (userData) {
      dispatch(getRegisterDataAction());
    }
  }, [success]);

  useEffect(() => {
    if (ProfileDetailsGet) {
      const dataGet = {
        firstName: ProfileDetailsGet?.first_name,
        lastName: ProfileDetailsGet?.last_name,
        email: ProfileDetailsGet?.email,
        phoneNumber: ProfileDetailsGet?.phone_number,
        address: ProfileDetailsGet?.address,
      };
      setEventImage(ProfileDetailsGet?.user_profile?.image);
      setRegisterData(dataGet);
    }
  }, [ProfileDetailsGet]);
  useEffect(() => {
    if (success && rerender == 1) {
      swal({
        title: "Successfully",
        text: "Update Successfully",
        className: "successAlert",
        icon: "/img/logo.svg",
        buttons: false,
        timer: 3000,
      });
      setRerender(0);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      // navigate("/profile");
    }
    if (error && rerender == 1) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "/img/logo.svg",
        buttons: false,
        timer: 2000,
      });
      setIsLoading(false);
      setRerender(0);
    }
  }, [success, error, rerender]);

  const spreadsheetName = (data) => {
    const regex = /excel_[a-zA-Z0-9-]+-\d+/;
    const match = data?.match(regex);
    return match ? match[0] : null;
  };
  function reportName(data) {
    const regex = /([a-zA-Z0-9-]+-\d+)_\d{4}-\d{2}-\d{2}/;
    const match = data?.match(regex);
    return match ? match[1] : null;
  }
  const handleSpreadsheet = async (reportFile) => {
    try {
      const response = await axios.post(
        `${BACKEND_URL}api/v1/common/download-excel/`,
        {
          file_path: reportFile,
        },
        {
          responseType: "blob",
        }
      );
      saveAs(
        response.data,
        reportFile.split("/")[1].split("_")[1].split(".")[0] +
          "_spreadsheet.xlsx"
      );
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handlePrintReport = (link) => {
    window.open(link, "_blank");
  };

  const handleDelete = async (id) => {
    const result = await swal({
      text: "Are you want to delete?",
      icon: "warning",
      buttons: ["No", "Yes"],
    });
    if (result) {
      setRerender(2);
      await dispatch(UploadHistoryDelete(id));
    }
  };
  const handleDeleteProfile = async () => {
    const result = await swal({
      // className: "successAlert",
      // title: "Are you sure?",
      text: "Are you want to delete Profile?",
      icon: "warning",
      buttons: ["No", "Yes"],
    });
    if (result) {
      setRerender(3);
      await dispatch(DeleteProfile());
    }
  };

  return (
    <>
      {isLoading ? (
        <div style={{ marginTop: "10rem" }}>
          {" "}
          <Loader />
        </div>
      ) : (
        <div className="container">
          <div className="inputpage">
            {/* <h1> Profile Page</h1> */}
            <div className="d-flex">
              <h4
                className={`profile-heading22 ${
                  activetab == 1 && "bordercls33"
                }`}
                onClick={() => setactivetab(1)}
              >
                Profile
              </h4>
              <h4
                className={`ms-5 profile-heading22 ${
                  activetab == 2 && "bordercls33"
                }`}
                onClick={() => setactivetab(2)}
              >
                Upload history
              </h4>
            </div>
          </div>

          {activetab == 1 && (
            <div className="profiletop">
              <div className="profiletop_left">
                <div className="imgsec">
                  <span {...getRootProps()}>
                    <input {...getInputProps()} />
                    {eventImage?.length > 0 ? (
                      <>
                        <img
                          className=" inline-block h-[100px] w-[100px] rounded-full ring-2 ring-white dark:ring-gray-800 cursor-pointer "
                          src={
                            eventImage?.[0]?.preview
                              ? eventImage?.[0]?.preview
                              : eventImage
                          }
                        />
                      </>
                    ) : (
                      <>
                        <img
                          className="inline-block h-[150px] w-[150px] rounded-full ring-2 ring-white dark:ring-gray-800 cursor-pointer "
                          src="/img/imgpro.png"
                        />
                      </>
                    )}
                  </span>
                </div>
                <div className="profilediv">
                  <h1>
                    {ProfileDetailsGet?.first_name}{" "}
                    {ProfileDetailsGet?.last_name}
                  </h1>
                  <p>{ProfileDetailsGet?.email}</p>
                  <p>{ProfileDetailsGet?.phone_number}</p>
                  <p>{ProfileDetailsGet?.address}</p>
                </div>
                <p className="deleteProfile" onClick={handleDeleteProfile}>
                  Delete Profile
                </p>
              </div>
              <div className="profiletop_right">
                <div className="proinpu">
                  <input
                    type="text"
                    placeholder="First Name"
                    onChange={(e) => handleStateChange(e, "firstName")}
                    value={registerData?.firstName}
                    name="uname"
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors.firstName ? 1 : 0,
                    }}
                  >
                    {errors.firstName ?? "valid"}
                  </span>
                </div>
                <div className="proinpu">
                  <input
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => handleStateChange(e, "lastName")}
                    value={registerData?.lastName}
                    name="uname"
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors.lastName ? 1 : 0,
                    }}
                  >
                    {errors.lastName ?? "valid"}
                  </span>
                </div>
                <div className="proinpu">
                  <input
                    type="text"
                    placeholder="Phone Numbar"
                    onChange={(e) => handleStateChange(e, "phoneNumber")}
                    value={registerData?.phoneNumber}
                    name="uname"
                  />

                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors.phoneNumber ? 1 : 0,
                    }}
                  >
                    {errors.phoneNumber ?? "valid"}
                  </span>
                </div>
                <div className="proinpu">
                  <input
                    type="text"
                    placeholder="Enter Email"
                    onChange={(e) => handleStateChange(e, "email")}
                    value={registerData?.email}
                    name="uname"
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors.email ? 1 : 0,
                    }}
                  >
                    {errors.email ?? "valid"}
                  </span>
                </div>
                <div className="proinpu">
                  <input
                    type="text"
                    placeholder=" Address"
                    onChange={(e) => handleStateChange(e, "address")}
                    value={registerData?.address}
                    name="uname"
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors.address ? 1 : 0,
                    }}
                  >
                    {errors.address ?? "valid"}
                  </span>
                </div>
                <button className="savebtn" onClick={validateSubmit}>
                  save
                </button>
              </div>
            </div>
          )}
          {activetab == 2 && (
            <div className="tabsecond22">
              {historyData?.data.length == 0 && (
                <p className="text-center">No Upload History</p>
              )}
              {historyData?.data?.map((items, i) => (
                <div key={i} className="historydiv">
                  <div className="d-flex justify-content-between">
                    <p className="fw-medium">Nmi Number : {items.nmi}</p>
                    <i
                      onClick={() => handleDelete(items.id)}
                      style={{ cursor: "pointer" }}
                      class="fa-solid fa-trash"
                    ></i>
                  </div>
                  <div className="">
                    <div className="d-flex">
                      <p className="nmidivWidth">
                        {spreadsheetName(items.nmi_spreadsheet)}{" "}
                      </p>
                      <p
                        className="para4347"
                        onClick={() => handleSpreadsheet(items.nmi_spreadsheet)}
                      >
                        Download Speadsheet
                      </p>
                    </div>
                    <div className="d-flex">
                      <p className="nmidivWidth">
                        {reportName(items.nmi_report)}{" "}
                      </p>
                      <p
                        className="para4347"
                        onClick={() => handlePrintReport(items.report_url)}
                      >
                        Download Report
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Profile;
