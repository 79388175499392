import React, { useState, useEffect } from "react";
import {
  RegisterAction,
  registerwithgoogle,
} from "../Redux/actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validations } from "../utils";
import { Link } from "react-router-dom";
import swal from "sweetalert";
// import Loader from "../Loader/Loader";
import { Loader } from "../components/Loader";
import image1 from "../assets/images/Singup55Image.svg";
import image2 from "../assets/images/Logo3333Image.svg";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { success, error, message, loading } = useSelector(
    (state) => state.RegisterReducer
  );

  const [isLoading, setIsLoading] = useState(true);
  const [rerender, setRerender] = useState(false);
  const [registerData, setRegisterData] = useState({
    name: "",
    organization: "",
    email: "",
    password: "",
    cPassword: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    organization: "",
    email: "",
    password: "",
    cpassword: "",
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const handleStateChange = (e, fieldName) => {
    const { value } = e.target;

    setRegisterData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    setErrors(() => ({
      ...errors,
      [fieldName]: null,
    }));
  };

  const validateSubmit = (e) => {
    e?.preventDefault();
    const tempErrors = {
      name: validations.fullName(registerData.name),
      organization: validations.organization(registerData.organization),
      email: validations.email(registerData.email),
      password: validations.password(registerData.password),
      cPassword: validations.confirmPassword(
        registerData.password,
        registerData.cPassword
      ),
    };

    setErrors(tempErrors);

    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    handleSubmitData();
  };

  const handleSubmitData = async () => {
    const formData = new FormData();
    formData.append("first_name", registerData?.name);
    formData.append("organisation", registerData?.organization);
    formData.append("email", registerData?.email);
    formData.append("password", registerData?.password);
    formData.append("confirm_password", registerData?.cPassword);

    dispatch(RegisterAction(formData));
    setIsLoading(true);
    setRerender(true);
  };
  useEffect(() => {
    if (success && rerender) {
      swal({
        title: "Successfully",
        text: "Register Successfully",
        className: "successAlert",
        icon: "img/logo.svg",
        buttons: false,
        timer: 3000,
      });
      setRerender(false);
      setTimeout(() => {
        setIsLoading(false);
        navigate("/login");
      }, 3000);
      
    }
    if (error && rerender) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "img/logo.svg",
        buttons: false,
        timer: 3000,
      });
      setIsLoading(false);
      setRerender(false);
    }
  }, [success, error, rerender]);
  return (
    <>
      {isLoading ? (
        <div style={{ marginTop: "10rem" }}>
          <Loader text="Loading..." />
        </div>
      ) : (
        <>
          {/* <div className="register">
          <div className="registersec">
            <div className="login-logoimg">
              <img src="/img/logo.svg" />
            </div>
            <form>
              <div className="registerformsec">
                <div className="cont-input">
                  <input
                    type="text"
                    placeholder="First Name"
                    onChange={(e) => handleStateChange(e, "firstName")}
                    className="input-1"
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "15px",
                      opacity: errors.firstName ? 1 : 0,
                    }}
                  >
                    {errors.firstName ?? "valid"}
                  </span>
                </div>
                <div className="cont-input">
                  <input
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => handleStateChange(e, "lastName")}
                    className="input-1"
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "15px",
                      opacity: errors.lastName ? 1 : 0,
                    }}
                  >
                    {errors.lastName ?? "valid"}
                  </span>
                </div>
              </div>
              <div className="registerformsec">
                <div className="cont-input">
                  <input
                    type="text"
                    placeholder="Enter Email"
                    onChange={(e) => handleStateChange(e, "email")}
                    className="input-1"
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "15px",
                      opacity: errors.email ? 1 : 0,
                    }}
                  >
                    {errors.email ?? "valid"}
                  </span>
                </div>
                <div className="cont-input">
                  <input
                    type="text"
                    placeholder="Phone Number"
                    className="input-1"
                    onChange={(e) => handleStateChange(e, "phoneNumber")}
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "15px",
                      opacity: errors.phoneNumber ? 1 : 0,
                    }}
                  >
                    {errors.phoneNumber ?? "valid"}
                  </span>
                </div>
              </div>
              <div className="registerformsec">
                <div className="cont-input">
                  <input
                    type="Password"
                    placeholder="Password"
                    onChange={(e) => handleStateChange(e, "password")}
                    className="input-1"
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "15px",
                      opacity: errors.password ? 1 : 0,
                    }}
                  >
                    {errors.password ?? "valid"}
                  </span>
                </div>
                <div className="cont-input">
                  <input
                    type="Password"
                    placeholder="Confirm Password"
                    onChange={(e) => handleStateChange(e, "cPassword")}
                    className="input-1"
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "15px",
                      opacity: errors.cPassword ? 1 : 0,
                    }}
                  >
                    {errors.cPassword ?? "valid"}
                  </span>
                </div>
              </div>
              <div className="Create-btn-r">
                <button className="Accountbtn1 " onClick={validateSubmit}>
                  Create Account
                </button>
                <Link to="/" className="Signbtn2">
                  Sign In{" "}
                </Link>
              </div>
            </form>
          </div>
        </div> */}
          <div className="login-container">
            <div class="login-flex-div1">
              <img className="login-img122" src={image1} alt="img" />
              <div className="login-div12">
                <h2 className="login-heading12e">
                  The journey to Renewable transition can be simple
                </h2>
                <p className="login-paraText11">
                  Find out how renewable options can help reduce your bills and
                  carbon footprint
                </p>
              </div>
            </div>
            <div className="login-flex-div2">
              {/* <div className="login-div12t">
             
                <img className="" src={image2} alt="home img" />
              </div> */}
              <div className="login-flex-div33">
                <h2 className="login-heading33">Sign up</h2>
                <div className="login-labelInput-Div">
                  <label className="login-label">Name</label>
                  <input
                    type="text"
                    className="login-input "
                    onChange={(e) => handleStateChange(e, "name")}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors.name ? 1 : 0,
                      position:'absolute'
                    }}
                  >
                    {errors.name ?? "valid"}
                  </p>
                </div>
                <div className="login-labelInput-Div">
                  <label className="login-label">Organisation</label>
                  <input
                    type="text"
                    className="login-input "
                    onChange={(e) => handleStateChange(e, "organization")}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors.organization ? 1 : 0,
                      position:'absolute'
                    }}
                  >
                    {errors.organization ?? "valid"}
                  </p>
                </div>
                <div className="login-labelInput-Div">
                  <label className="login-label">Email</label>
                  <input
                    type="email"
                    className="login-input "
                    onChange={(e) => handleStateChange(e, "email")}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors.email ? 1 : 0,
                      position:'absolute'
                    }}
                  >
                    {errors.email ?? "valid"}
                  </p>
                </div>
                <div className="login-labelInput-Div">
                  <label className="login-label">Password</label>
                  <input
                    type="password"
                    className="login-input "
                    onChange={(e) => handleStateChange(e, "password")}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors.password ? 1 : 0,
                      position:'absolute'
                    }}
                  >
                    {errors.password ?? "valid"}
                  </p>
                </div>
                <div className="login-labelInput-Div">
                  <label className="login-label">Confrim password</label>
                  <input
                    type="password"
                    className="login-input "
                    onChange={(e) => handleStateChange(e, "cPassword")}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "13px",
                      opacity: errors.cPassword ? 1 : 0,
                      position:'absolute'
                    }}
                  >
                    {errors.cPassword ?? "valid"}
                  </p>
                </div>
                <button className="login-btn11" onClick={validateSubmit}>
                  {" "}
                  Next
                </button>
                <p className="Login-LinkText">
                  Have an account? <Link to="/login">Sign in</Link>
                </p>
                <button className="Login-btn222" onClick={()=>navigate(-1)}>Back</button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Register;
