import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import image1 from "../assets/images/Singup55Image.svg";
import { useDispatch, useSelector } from "react-redux";
import { ConnectionPostData } from "../Redux/actions/PublicActions";
import swal from "sweetalert";
import { validations } from "../utils";

const Connections = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, error } = useSelector((state) => state.ConnectionPostReducer);

  const [name, setName] = useState("");
  const [preferredWay, setPreferredWay] = useState("");
  const [preferredTime, setPreferredTime] = useState("");
  const [notes, setNotes] = useState("");
  const [render, setRender] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+61 ");
  const [errors, setErrors] = useState({
    nameError: "",
    preferredWayError: "",
    emailError: "",
    phoneNumberError: "",
  });

  const id_for_freeout = localStorage.getItem("FreeOuputid");
  const premium_id = localStorage.getItem("PremiumOuputid");

  useEffect(() => {
    if (data && render) {
      swal({
        title: "",
        text: "Submitted",
        className: "successAlert",
        icon: "/img/logo.svg",
        buttons: false,
        timer: 3000,
        open: true,
      });
      setName("");
      setNotes("");
      setPreferredTime("");
      setPreferredWay("");
      setRender(false);
      localStorage.removeItem("FreeOuputid");
      localStorage.removeItem("PremiumOuputid");
      setTimeout(() => {
        navigate("/more-trials");
      }, [3000]);
    }

    if (error && render) {
      swal({
        title: "",
        text: error,
        className: "successAlert",
        icon: "img/logo.svg",
        buttons: false,
        timer: 3000,
        open: true,
      });
      setRender(false);
    }
  }, [data, error]);



  const handleSubmit = () => {
    let tempErrors = {
      nameError: validations.fullName(name),
      preferredWayError: !preferredWay && "Required",
      emailError: "",
      phoneNumberError: "",
    };

    if (preferredWay === "email") {
      tempErrors.emailError = validations.email(email);
    }
    if (preferredWay === "phone") {
      tempErrors.phoneNumberError = validations.mobileNumber(phone);
    }

    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }

    const formData = new FormData();
    if (id_for_freeout) {
      formData.append("free_output", id_for_freeout);
    }
    if (premium_id) {
      formData.append("basic_output", premium_id);
    }
    formData.append("name", name);
    formData.append("preferred_way", preferredWay);
    formData.append("preferredTime", preferredTime);
    if (preferredWay === "email") {
      formData.append("preferred_value", email);
    }
    if (preferredWay === "phone") {
      formData.append("preferred_value", phone);
    }
    formData.append("notes", notes);
    setRender(true);
    dispatch(ConnectionPostData(formData));
  };
  
  const handleChange = (e) => {
    const { value } = e.target;

    if (value == "+61") {
      return;
    }
    setPhone(value);
    setErrors({ ...error, phoneNumberError: null });
  };
  
  return (
    <div className="contactContainer">
      <div class="login-flex-div1">
        <img className="login-img122" src={image1} alt="img" />
        <div className="login-div12">
          <h2 className="login-heading12e">
            The journey to Renewable transition can be simple
          </h2>
          <p className="login-paraText11">
            Find out how renewable options can help reduce your bills and carbon
            footprint
          </p>
        </div>
      </div>
      <div className="login-flex-div2">
        {/* <div className="login-div12t" style={{ justifyContent: "end" }}>
          <img className="" src={image2} alt="home img" />
        </div> */}
        
        <div className="login-flex-div33 mt-0">
        <h2 className="login-heading33 mt-5">Get quotes from up to 3 installers now!</h2>
          <div className="login-labelInput-Div">
            <label className="login-label" htmlFor="nameLabel">
              Name
            </label>
            <input
              value={name}
              id="nameLabel"
              onChange={(e) => {
                setName(e.target.value);
                setErrors({ ...errors, nameError: null });
              }}
              type="text"
              className="login-input "
            />
            <p
              style={{
                color: "red",
                fontSize: "13px",
                opacity: errors.nameError ? 1 : 0,
                position: "absolute",
              }}
            >
              {errors.nameError ?? "valid"}
            </p>
          </div>
          <div className="login-labelInput-Div">
            <label className="login-label" htmlFor="preferWay">
              Prefered way to connect
            </label>
            <select
              id="preferWay"
              value={preferredWay}
              onChange={(e) => {
                setPreferredWay(e.target.value);
                setErrors({ ...errors, preferredWayError: null });
              }}
              className="login-input"
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </select>
            <p
              style={{
                color: "red",
                fontSize: "13px",
                opacity: errors.preferredWayError ? 1 : 0,
                position: "absolute",
              }}
            >
              {errors.preferredWayError ?? "valid"}
            </p>
          </div>
          {preferredWay == "email" && (
            <div className="login-labelInput-Div">
              <label className="login-label" htmlFor="EmailLabel">
                Enter Your Email
              </label>
              <input
                value={email}
                id="EmailLabel"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors({ ...errors, emailError: null });
                }}
                type="email"
                className="login-input "
              />
              <p
                style={{
                  color: "red",
                  fontSize: "13px",
                  opacity: errors.emailError ? 1 : 0,
                  position: "absolute",
                }}
              >
                {errors.emailError ?? "valid"}
              </p>
            </div>
          )}

          {preferredWay == "phone" && (
            <div className="login-labelInput-Div">
              <label className="login-label" htmlFor="PhoneLabel">
                Enter Your Phone Number
              </label>
              <input
                value={phone}
                id="PhoneLabel"
                onChange={handleChange}
                type="tel"
                className="login-input "
              />
              <p
                style={{
                  color: "red",
                  fontSize: "13px",
                  opacity: errors.phoneNumberError ? 1 : 0,
                  position: "absolute",
                }}
              >
                {errors.phoneNumberError ?? "valid"}
              </p>
            </div>
          )}

          <div className="login-labelInput-Div" htmlFor="preferTime">
            <label className="login-label">Preferred time to connect</label>
            <input
              id="preferTime"
              type="datetime-local"
              value={preferredTime}
              onChange={(e) => setPreferredTime(e.target.value)}
              className="login-input"
            />
          </div>

          <div className="login-labelInput-Div">
            <label className="login-label" htmlFor="otherNotes">
              Other notes
            </label>
            <textarea
              id="otherNotes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="login-input"
              rows={5}
            ></textarea>
          </div>
          <button className="login-btn11" type="button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Connections;
