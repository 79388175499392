import React from "react";
import image1 from "../assets/images/imgnew.png";

const UpgradePlan = () => {
  return (
    <div className="upgradeContainer">
      <div className="upgradeFlex11">
        <div style={{ width: "80%", textAlign: "center" }}>
          <img src={image1} alt="image" className="upgardeimg33" />
          <h4 className="my-3">
            The journey to Renewable transition can be simple
          </h4>
          <p>
            Find out how renewable options can help reduce your bills and carbon
            footprint
          </p>
        </div>
      </div>
      <div className="upgradeFlex12">
        <div style={{ width: "70%" }}>
          <h3 className="my-3 text-center">Receive Offers From Installers</h3>
          <input className="upgardeInput5" placeholder="Name" />
          <input className="upgardeInput5" placeholder="Organization" />
          <input className="upgardeInput5" placeholder="Email" />
          <input className="upgardeInput5" placeholder="Phone Number" />
          <button className="upgardeBtn455">Next</button>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlan;
