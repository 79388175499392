import React, { useState, useRef, useEffect } from "react";
import image1 from "../assets/images/imgnew.png";
import image2 from "../assets/images/img-1.png";
import image3 from "../assets/images/img-2.png";
import image4 from "../assets/images/img-3.png";
import image5 from "../assets/images/upwardArrow.svg";
import { useNavigate, useLocation } from "react-router-dom";
import GoogleMap from "../components/googleMap";
import { useDispatch, useSelector } from "react-redux";
import { Frontend_URL } from "../environment";
import {
  FeasibilityPostData,
  FeasibilityTab1PostData,
  EmailDataFetch,
  RedirectFetchData,
} from "../Redux/actions/PublicActions";
import { Loader } from "../components/Loader";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { saveAs } from "file-saver";

const FeasibilityTools = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const nmiFile = useRef(null);

  const queryParams = new URLSearchParams(location.search);
  const fromParam = queryParams.get("redirect");

  const { data: tab1Respose, error: errorTab1 } = useSelector(
    (state) => state.FeasibilityTab1PostReducer
  );
  const { data: tab2Respose, error: errorTab2 } = useSelector(
    (state) => state.FeasibilityPostReducer
  );
  const { data: NmiData, error: NmiError } = useSelector(
    (state) => state.EmailDataGetReducer
  );
  const { data: RedirectData, error: redirectError } = useSelector(
    (state) => state.RedirectFetchGetReducer
  );
  const [tabs, settabs] = useState(1);
  const [btnLoading, setbtnLoading] = useState(false);
  const [solarRoof, setsolarRoof] = useState(false);
  const [battery, setbattery] = useState(false);
  const [email, setemail] = useState("");
  const [shareNMIinfo, setshareNMIinfo] = useState(false);
  const [energyType, setenergyType] = useState(0);
  const [isFlatSelect, setisFlatSelect] = useState(null);
  const [nmifileName, setnmifileName] = useState("");
  const [flatRate, setflatRate] = useState("");
  const [peakusage, setpeakusage] = useState("");
  const [shoulderusage, setshoulderusage] = useState("");
  const [offpeakusage, setoffpeakusage] = useState("");
  const [deliverycharge, setdeliverycharge] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [roofArea, setRoofArea] = useState(null);
  const [render, setrender] = useState(0);
  const [state, setstate] = useState("");
  const [loadingData, setloadingData] = useState(false);
  const [responsefile, setresponsefile] = useState();
  const [errors, setErrors] = useState({
    solarRoofBattery: null,
    email: null,
    TimeFlatrate: null,
    peak: null,
    nmiFile: null,
    offpeak: null,
    deliveryCharge: null,
    flatRate: null,
    longlatitude: null,
  });

  useEffect(() => {
    const getData = async () => {
      if (fromParam) {
        const dataF = JSON.parse(localStorage.getItem("FeasiData"));
        const emailData = dataF?.email;
        if (emailData) {
          setemail(dataF?.email);
          setsolarRoof(dataF?.solarRoof);
          setbattery(dataF?.battery);
          setshareNMIinfo(true);
          setrender(4);
          setloadingData(true);
          await dispatch(RedirectFetchData(emailData));
        }
      }
    };
    getData();
  }, [fromParam]);

  useEffect(() => {
    if (tab1Respose?.data?.id && render == 1) {
      setloadingData(false);
      localStorage.setItem("id", tab1Respose?.data?.id);
      settabs(2);
      setrender(0);
    }
    if (tab2Respose?.id && render == 2) {
      setloadingData(false);
      localStorage.setItem("id", tab2Respose?.id);
      setrender(0);
      navigate("/free-output");
    }
    if (NmiData?.data && render == 3) {
      setbtnLoading(false);
      setrender(0);
      window.open(NmiData.data, "_blank");
    }
    if (RedirectData?.data && render == 4) {
      setrender(0);
      setloadingData(false);
      setisFlatSelect(2);
      setpeakusage(RedirectData?.data[0]);
      setshoulderusage(RedirectData?.data[3]);
      setoffpeakusage(RedirectData?.data[7]);
      setdeliverycharge(RedirectData?.data[8]);
      setresponsefile(RedirectData?.file_name);
    }
  }, [tab1Respose?.data?.id, tab2Respose?.id, NmiData, RedirectData]);

  useEffect(() => {
    if (errorTab1 && render == 1) {
      setloadingData(false);
      Swal.fire({
        text: errorTab1,
        icon: "error",
        confirmButtonText: "Got it!",
      });
      setrender(0);
    }
    if (errorTab2 && render == 2) {
      setloadingData(false);
      Swal.fire({
        text: errorTab2,
        icon: "error",
        confirmButtonText: "Got it!",
      });
      setrender(0);
    }
    if (NmiError && render == 3) {
      setloadingData(false);
      setrender(0);
      Swal.fire({
        text: NmiError,
        icon: "error",
        confirmButtonText: "Got it!",
      });
      setbtnLoading(false);
    }
    if (redirectError && render == 4) {
      setloadingData(false);
      Swal.fire({
        text: redirectError,
        icon: "error",
        confirmButtonText: "Got it!",
      });
      setrender(0);
    }
  }, [errorTab1, errorTab2, NmiError, redirectError]);

  const emailValiadte = (value) => {
    if (!value) return "Email is required";

    if (
      !value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    )
      return "Email is not valid";

    return null;
  };

  const handleFile = () => {
    const selectedFile = nmiFile?.current?.files[0];
    const allowedFormats = ["csv", "xls", "xlsx"];
    const fileNameArray = selectedFile?.name?.split(".");
    const fileExtension =
      fileNameArray[fileNameArray?.length - 1]?.toLowerCase();

    if (allowedFormats.includes(fileExtension)) {
      setnmifileName(selectedFile?.name);
      setErrors((prevErrors) => ({
        ...prevErrors,
        nmiFile: null,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nmiFile: "Upload csv or excel file ",
      }));
      setnmifileName("");
    }
  };

  const handleTab1Click = async () => {
    const temperrors = {
      solarRoofBattery:
        !solarRoof && !battery
          ? "*At least choose one"
          : !solarRoof && battery
          ? null
          : solarRoof && !battery
          ? null
          : null,
      email: shareNMIinfo && emailValiadte(email),
      errorTimeFlat: !isFlatSelect && "*At least choose one",
    };
    setErrors((prevErrors) => ({
      ...prevErrors,
      solarRoofBattery: temperrors.solarRoofBattery,
      email: temperrors.email,
      TimeFlatrate: temperrors.errorTimeFlat,
    }));

    if (isFlatSelect === 1) {
      const temperrors = {
        flatRate: !flatRate && "*Required",
        deliveryC: !deliverycharge && "*Required",
      };
      setErrors((prevErrors) => ({
        ...prevErrors,
        flatRate: temperrors.flatRate,
        deliveryCharge: temperrors.deliveryC,
      }));

      if (Object.values(temperrors).filter((value) => value).length) {
        return;
      }
      const userID = JSON.parse(localStorage.getItem("userData"));
      const data = new FormData();
      data.append("user", userID?.user?.user_id);
      data.append("solar", solarRoof);
      data.append("battrey", battery);
      email && data.append("nmi_email", email);
      data.append("is_agree", shareNMIinfo);
      data.append("energy_type", energyType);
      data.append("nmi_file", nmiFile?.current?.files[0] || null);
      data.append("is_fit_rate", true);
      data.append("fit_rate", flatRate);
      data.append("delivery_charge", deliverycharge);
      setrender(1);
      setloadingData(true);
      localStorage.removeItem("FeasiData");
      await dispatch(FeasibilityTab1PostData(data));
    }

    if (isFlatSelect === 2) {
      const temperrors = {
        peak: !peakusage && "*Required",
        offpeak: !offpeakusage && "*Required",
        deliveryC: !deliverycharge && "*Required",
      };
      setErrors((prevErrors) => ({
        ...prevErrors,
        peak: temperrors.peak,
        offpeak: temperrors.offpeak,
        deliveryCharge: temperrors.deliveryC,
      }));

      if (Object.values(temperrors).filter((value) => value).length) {
        return;
      }
      const userID = JSON.parse(localStorage.getItem("userData"));
      const data = new FormData();
      data.append("user", userID?.user?.user_id);
      data.append("solar", solarRoof);
      data.append("battrey", battery);
      email && data.append("nmi_email", email);
      data.append("is_agree", shareNMIinfo);
      data.append("energy_type", energyType);
      data.append("nmi_file", nmiFile?.current?.files[0] || null);
      data.append("is_fit_rate", false);
      data.append("peak", peakusage);
      data.append("shoulder", shoulderusage);
      data.append("off_peak", offpeakusage);
      data.append("delivery_charge", deliverycharge);
      responsefile && data.append("file_name", responsefile);
      setrender(1);
      setloadingData(true);
      localStorage.removeItem("FeasiData");
      await dispatch(FeasibilityTab1PostData(data));
    }
  };

  const handleTab2Click = async () => {
    const temperrors = {
      longlatitude: !longitude && !latitude && "*Required",
    };
    setErrors((prevErrors) => ({
      ...prevErrors,
      longlatitude: temperrors.longlatitude,
    }));

    if (Object.values(temperrors).filter((value) => value).length) {
      return;
    }
    const userID = JSON.parse(localStorage.getItem("userData"));
    const id = localStorage.getItem("id");
    const data = new FormData();
    data.append("user", userID?.user?.user_id);
    data.append("solar", solarRoof);
    data.append("battrey", battery);
    data.append("nmi", id);
    data.append("address", fullAddress);
    data.append("longitude", longitude);
    data.append("latitude", latitude);
    roofArea && data.append("roof_size", roofArea);
    data.append("state", state);
    setrender(2);
    setloadingData(true);
    await dispatch(FeasibilityPostData(data));
  };

  const handleFlatrate = (event) => {
    const inputValue = event.target.value;
    if (!isNaN(inputValue)) {
      setflatRate(inputValue);
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      flatRate: null,
    }));
  };
  const handlepeakusage = (event) => {
    const inputValue = event.target.value;
    if (!isNaN(inputValue)) {
      setpeakusage(inputValue);
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      peak: null,
    }));
  };
  const handleshoulderusage = (event) => {
    const inputValue = event.target.value;
    if (!isNaN(inputValue)) {
      setshoulderusage(inputValue);
    }
  };
  const handleoffpeakusage = (event) => {
    const inputValue = event.target.value;
    if (!isNaN(inputValue)) {
      setoffpeakusage(inputValue);
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      offpeak: null,
    }));
  };

  const handledeliverycharge = (event) => {
    const inputValue = event.target.value;
    if (!isNaN(inputValue)) {
      setdeliverycharge(inputValue);
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      deliveryCharge: null,
    }));
  };
  const handleSampleNMI = () => {
    const fileUrl = "/anonymised nem12.xlsx";
    saveAs(fileUrl, fileUrl);
  };

  const handleSampleFormatted = () => {
    const fileUrl = "/formatted energy data example.xlsx";
    saveAs(fileUrl, fileUrl);
  };
  const handleEmaildata = async () => {
    if (email) {
      const data = {
        email,
        solarRoof,
        battery,
      };
      localStorage.setItem("FeasiData", JSON.stringify(data));
      setbtnLoading(true);
      setrender(3);
      await dispatch(EmailDataFetch(email));
    }
  };
  return loadingData ? (
    <div style={{ marginTop: "10rem" }}>
      <Loader />
    </div>
  ) : (
    <div className="feasibility-Container">
      <div className="feasibDiv33">
        <div className="feasibSidebar">
          <h2 className="feasibHeading67">Feasibility Tool</h2>
          <img className="feasibilityImage33" src={image1} alt="image" />
          <div className="mt-4">
            {/* <p className={`feasibTest8p ${tabs === 1 && "fw-medium"}`}> */}
            <p className={`feasibTest8p`}>Electricity usage</p>
            <p className={`feasibTest8p`}>Address & rooftop</p>
            <p className={`feasibTest8p`}>Electricity bill</p>
            <p className="feasibTest8p">Your results</p>
            <p className="feasibTest8p">Next steps</p>
          </div>
        </div>
        <div className="divFeasib7546">
          <div className="d-flex justify-content-center">
            <img className="feasibilityima23" src={image3} alt="image" />
            <img className="feasibilityima22" src={image2} alt="image" />
            <img className="feasibilityima24" src={image4} alt="image" />
          </div>
          <p className="textFeasib11">
            With details from your electricity bill we can analyse your energy
            usage and give you personalised recommendation.
          </p>
          {tabs === 1 && (
            <div>
              <div className="mb-5 position-relative">
                <div className="d-flex">
                  <div className="d-flex align-items-start">
                    <input
                      type="checkbox"
                      className="input-Topmargin"
                      id="value8"
                      checked={solarRoof}
                      onChange={() => {
                        setsolarRoof(!solarRoof);
                        setbattery(false);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          solarRoofBattery: null,
                        }));
                      }}
                    />
                    <label className="label55" htmlFor="value8">
                      Solar Roof Top
                    </label>
                  </div>
                  <div className="d-flex align-items-start">
                    <input
                      type="checkbox"
                      className="input-Topmargin"
                      id="value7"
                      checked={battery}
                      onChange={() => {
                        setbattery(!battery);
                        setsolarRoof(true);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          solarRoofBattery: null,
                        }));
                      }}
                    />
                    <label className="label55" htmlFor="value7">
                      Battery
                    </label>
                  </div>
                </div>
                {errors.solarRoofBattery && (
                  <p className="errormsg55">{errors.solarRoofBattery}</p>
                )}
              </div>
              <div className="mb-5 position-relative">
                <label className="feasibLabel mb-2">
                  Please provide the email address when you initially registered
                  for NMI.
                </label>
                <input
                  className="feasibInput"
                  type="text"
                  onChange={(e) => {
                    setemail(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      email: null,
                    }));
                  }}
                  value={email}
                />
                <div className="d-flex justify-content-end">
                  <button
                    className={`emailBtn3 ${fromParam && "btnopacity8788"}`}
                    onClick={handleEmaildata}
                    disabled={fromParam}
                  >
                    {btnLoading ? (
                      <>
                        Loading..
                        <i class="fa fa-circle-o-notch fa-spin" style={{height:'11px',width:'20px'}}></i>
                      </>
                    ) : (
                      <>Fetch data</>
                    )}
                  </button>
                </div>
                {errors.email && <p className="errormsg55">{errors.email}</p>}
              </div>
              <div className="mb-5">
                <label className="feasibLabel mb-2">
                  By providing this initial email address, you agree to share
                  your NMI information to Nature Electric
                </label>
                <div className="d-flex">
                  <div className="d-flex align-items-start">
                    <input
                      type="checkbox"
                      className="input-Topmargin"
                      id="value8"
                      checked={shareNMIinfo}
                      onChange={() => setshareNMIinfo(true)}
                    />
                    <label className="label55" htmlFor="value8">
                      Yes
                    </label>
                  </div>
                  <div className="d-flex align-items-start">
                    <input
                      type="checkbox"
                      className="input-Topmargin"
                      id="value7"
                      checked={!shareNMIinfo}
                      onChange={() => setshareNMIinfo(false)}
                    />
                    <label className="label55" htmlFor="value7">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <label className="feasibLabel mb-2">Energy Data</label>
                <span className="ms-1 samplefiletext">
                  (Kindly download sample
                  <span
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={handleSampleNMI}
                  >
                    {" "}
                    NEM12{" "}
                  </span>{" "}
                  or
                  <span
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={handleSampleFormatted}
                  >
                    {" "}
                    Formatted Energy{" "}
                  </span>
                  Data for Reference)
                </span>
                <div>
                  <select
                    className="feasSelect11"
                    onChange={(e) => setenergyType(parseInt(e.target.value))}
                    value={energyType}
                  >
                    <option value="0">NEM12</option>
                    <option value="1">Formatted Energy Data</option>
                  </select>
                </div>
              </div>

              <div className="mb-5">
                <label className="feasibLabel mb-2">
                  If you don't have the email address, or don't agree to share
                  the email address, please upload the meter data
                </label>

                <div className="position-relative">
                  <div
                    className="divfeasiu8"
                    onClick={() => nmiFile.current.click()}
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={nmiFile}
                      onChange={handleFile}
                    />
                    <img className="imgFeas344h" src={image5} alt="image" />
                    {!nmifileName ? (
                      <p className="textFeas89k mb-0">Please upload file</p>
                    ) : (
                      <p className="textFeas89k mb-0">{nmifileName}</p>
                    )}
                  </div>
                  {errors.nmiFile && (
                    <p className="errormsg55">{errors.nmiFile}</p>
                  )}
                </div>
              </div>
              <div>
                <div class="position-relative">
                  <label class="feasibLabel mb-2">
                    Do you have a Time of Use or a Flat Rate electricity tariff?
                  </label>

                  <div class="d-flex align-items-start">
                    <input
                      type="checkbox"
                      class="input-Topmargin"
                      checked={isFlatSelect == 2 ? true : false}
                      onChange={(e) => {
                        setisFlatSelect(2);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          TimeFlatrate: null,
                        }));
                      }}
                    />
                    <label class="label55" htmlFor="value8">
                      Time of Use
                    </label>
                  </div>
                  <div class="d-flex align-items-start">
                    <input
                      type="checkbox"
                      class="input-Topmargin"
                      checked={isFlatSelect == 1 ? true : false}
                      onChange={(e) => {
                        setisFlatSelect(1);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          TimeFlatrate: null,
                        }));
                      }}
                    />
                    <label class="label55" htmlFor="value7">
                      Flat Rate
                    </label>
                  </div>
                  {errors.TimeFlatrate && (
                    <p className="errormsg55">{errors.TimeFlatrate}</p>
                  )}
                </div>
                {isFlatSelect && (
                  <p className="mt-3 feasibLabel ">
                    Please enter the unit price in cent/kwh on your bill
                  </p>
                )}

                {isFlatSelect == 1 && (
                  <>
                    <div className=" mb-5 position-relative">
                      <input
                        className="feasibInput"
                        type="text"
                        placeholder="e.g. 20"
                        onChange={handleFlatrate}
                        value={flatRate}
                      />
                      {errors.flatRate && (
                        <p className="errormsg55">{errors.flatRate}</p>
                      )}
                    </div>
                    <div class="position-relative">
                      <label class="feasibLabel mb-2">Delivery Charge</label>
                      <input
                        class="feasibInput"
                        type="text"
                        placeholder="e.g. 20"
                        onChange={handledeliverycharge}
                        value={deliverycharge}
                      />
                      {errors.deliveryCharge && (
                        <p className="errormsg55">{errors.deliveryCharge}</p>
                      )}
                    </div>
                  </>
                )}
                {isFlatSelect == 2 && (
                  <>
                    <div class="mb-5 position-relative">
                      <label class="feasibLabel mb-2">Peak Rate</label>
                      <input
                        class="feasibInput"
                        type="text"
                        placeholder="e.g. 20"
                        onChange={handlepeakusage}
                        value={peakusage}
                      />
                      {errors.peak && (
                        <p className="errormsg55">{errors.peak}</p>
                      )}
                    </div>
                    <div class="mb-5 position-relative">
                      <label class="feasibLabel mb-2">Shoulder Rate</label>
                      <input
                        class="feasibInput"
                        type="text"
                        placeholder="e.g. 20"
                        onChange={handleshoulderusage}
                        value={shoulderusage}
                      />
                    </div>
                    <div class="mb-5 position-relative">
                      <label class="feasibLabel mb-2">Off-peak Rate</label>
                      <input
                        class="feasibInput"
                        type="text"
                        placeholder="e.g. 20"
                        onChange={handleoffpeakusage}
                        value={offpeakusage}
                      />
                      {errors.offpeak && (
                        <p className="errormsg55">{errors.offpeak}</p>
                      )}
                    </div>
                    <div class="position-relative">
                      <label class="feasibLabel mb-2">Delivery Charge</label>
                      <input
                        class="feasibInput"
                        type="text"
                        placeholder="e.g. 20"
                        onChange={handledeliverycharge}
                        value={deliverycharge}
                      />
                      {errors.deliveryCharge && (
                        <p className="errormsg55">{errors.deliveryCharge}</p>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className="d-flex justify-content-center">
                <button className="btn77Feasib" onClick={handleTab1Click}>
                  Confirm
                </button>
              </div>
            </div>
          )}

          {tabs === 2 && (
            <div>
              <GoogleMap
                fullAddress={fullAddress}
                setFullAddress={setFullAddress}
                longitude={longitude}
                setLongitude={setLongitude}
                latitude={latitude}
                setLatitude={setLatitude}
                roofArea={roofArea}
                setRoofArea={setRoofArea}
                state={state}
                setstate={setstate}
                errors={errors}
                setErrors={setErrors}
              />
              <div className="d-flex justify-content-center">
                <button className="btn77Feasib" onClick={handleTab2Click}>
                  Confirm
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeasibilityTools;
