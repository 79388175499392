import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  SurveyFormsAction,
  SurveyFormsPostAction,
} from "../Redux/actions/PublicActions";
import swal from "sweetalert";

const SurveyForms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, error } = useSelector((state) => state.SurveyFormsGetReducer);
  const { data: Postdata, error: erro1 } = useSelector(
    (state) => state.SurveyFormsPostReducer
  );
  const [tabs, settabs] = useState(1);
  const [render, setrender] = useState(false);
  const [questionAnswers, setQuestionAnswers] = useState([]);
  useEffect(() => {
    window.scroll(0, 0);
    dispatch(SurveyFormsAction());
  }, []);

  useEffect(() => {
    if (Postdata && render) {
      swal({
        title: "",
        text: "Submitted",
        className: "successAlert",
        icon: "/img/logo.svg",
        buttons: false,
        timer: 3000,
        open: true,
      });
      setrender(false);
      setTimeout(() => {
        navigate("/");
      }, [3000]);
    }

    if (erro1 && render) {
      swal({
        title: "",
        text: erro1,
        className: "successAlert",
        icon: "img/logo.svg",
        buttons: false,
        timer: 3000,
        open: true,
      });
      setrender(false);
    }
  }, [Postdata, erro1]);

  const handleAnswerChange = (questionId, answer) => {
    const index = questionAnswers.findIndex(
      (item) => item.questionId === questionId
    );

    if (index === -1) {
      setQuestionAnswers((prevQuestionAnswers) => [
        ...prevQuestionAnswers,
        { questionId, answer },
      ]);
    } else {
      setQuestionAnswers((prevQuestionAnswers) => {
        const updatedQuestionAnswers = [...prevQuestionAnswers];
        updatedQuestionAnswers[index].answer = answer;
        return updatedQuestionAnswers;
      });
    }
  };

  const handleSubmit = () => {
    setrender(true);
    dispatch(SurveyFormsPostAction(JSON.stringify(questionAnswers)));
  };

  const LikelihoodToPurchase = ({
    item,
    questionAnswers,
    handleAnswerChange,
  }) => {
    const [rating, setRating] = useState(
      (questionAnswers.find((qa) => qa.questionId === item.id) || {}).answer ||
        0
    );

    const handleClick = (value) => {
      setRating(value);
      handleAnswerChange(item.id, value);
    };

    return (
      <div key={item.id}>
        <p className="survey-quesText">
          {item.id + 1}. {item.questions}
        </p>
        <p className="survey-quesText ms-3 mt-4">
          On a scale of 1 to 10, how likely are you to become a paying customer
          after the trial period ends?
        </p>
        <div>
          {[...Array(10)].map((_, index) => (
            <span
              key={index}
              style={{ color: `${index < rating ? "#14ee18" : "black"}` }}
              onClick={() => handleClick(index + 1)}
            >
              <i class="fa-solid fa-star survey-Stars"></i>
            </span>
          ))}
        </div>
      </div>
    );
  };

  const handleQues = (questionId, answer) => {
    const index = questionAnswers.findIndex(
      (item) => item.questionId === questionId
    );

    if (index === -1) {
      setQuestionAnswers((prevQuestionAnswers) => [
        ...prevQuestionAnswers,
        { questionId, answer },
      ]);
    } else {
      setQuestionAnswers((prevQuestionAnswers) => {
        const updatedQuestionAnswers = [...prevQuestionAnswers];
        updatedQuestionAnswers[index].answer = answer;
        return updatedQuestionAnswers;
      });
    }
  };
  return (
    <div className="survey-container">
      <h2 className="survey-heading1">Survey</h2>
      <div className="survey-Div1">
        {tabs == 1 && (
          <>
            <h2 className="survey-heading2">Deomgraphic Information</h2>
            {data?.map(
              (item, i) =>
                item?.form_type == "Demographic Information" &&
                item?.id != 15 && (
                  <div key={item.id}>
                    <p className="survey-quesText">
                      {item?.id}. {item?.questions}
                    </p>
                    <input
                      type="text"
                      placeholder="Write your answer"
                      className="survey-input32"
                      value={
                        (
                          questionAnswers.find(
                            (qa) => qa.questionId === item.id
                          ) || {}
                        ).answer || ""
                      }
                      onChange={(e) =>
                        handleAnswerChange(item.id, e.target.value)
                      }
                    />
                  </div>
                )
            )}
            {data?.map(
              (item, i) =>
                item?.form_type == "Demographic Information" &&
                item?.id == 15 && (
                  <div key={item.id}>
                    <p className="survey-quesText">
                      {item?.id == 15 ? item?.id - 10 : null}. {item?.questions}
                    </p>
                    <div className="d-flex mt-1">
                      <div className="d-flex align-items-start">
                        <input
                          type="radio"
                          className="input-Topmargin"
                          id="value8"
                          checked={
                            (
                              questionAnswers.find(
                                (qa) => qa.questionId === item.id
                              ) || {}
                            ).answer == "rent"
                              ? true
                              : false
                          }
                          onChange={() => handleQues(item.id, "rent")}
                        />
                        <label className="label55" htmlFor="value8">
                          Rent
                        </label>
                      </div>
                      <div className="d-flex align-items-start">
                        <input
                          type="radio"
                          className="input-Topmargin"
                          id="value7"
                          checked={
                            (
                              questionAnswers.find(
                                (qa) => qa.questionId === item.id
                              ) || {}
                            ).answer == "own"
                              ? true
                              : false
                          }
                          onChange={() => handleQues(item.id, "own")}
                        />
                        <label className="label55" htmlFor="value7">
                          Own
                        </label>
                      </div>
                    </div>
                  </div>
                )
            )}
          </>
        )}

        {tabs == 2 && (
          <>
            <h2 className="survey-heading2"> Product discovery and Sign-Up</h2>

            {data?.map(
              (item, i) =>
                item?.form_type == "Product discovery and Sign-Up" && (
                  <div key={item.id}>
                    <p className="survey-quesText">
                      {item?.id + 1}. {item?.questions}
                    </p>
                    <input
                      type="text"
                      placeholder="Write your answer"
                      className="survey-input32"
                      value={
                        (
                          questionAnswers.find(
                            (qa) => qa.questionId === item.id
                          ) || {}
                        ).answer || ""
                      }
                      onChange={(e) =>
                        handleAnswerChange(item.id, e.target.value)
                      }
                    />
                  </div>
                )
            )}
          </>
        )}

        {tabs == 3 && (
          <>
            <h2 className="survey-heading2"> Value and Benefits</h2>
            {data?.map(
              (item, i) =>
                item?.form_type == "Value and Benefits" && (
                  <div key={item.id}>
                    <p className="survey-quesText">
                      {item?.id + 1}. {item?.questions}
                    </p>
                    <input
                      type="text"
                      placeholder="Write your answer"
                      className="survey-input32"
                      value={
                        (
                          questionAnswers.find(
                            (qa) => qa.questionId === item.id
                          ) || {}
                        ).answer || ""
                      }
                      onChange={(e) =>
                        handleAnswerChange(item.id, e.target.value)
                      }
                    />
                  </div>
                )
            )}
          </>
        )}

        {tabs == 4 && (
          <>
            <h2 className="survey-heading2">Product usage</h2>
            {data?.map(
              (item, i) =>
                item?.form_type == "Product usage" && (
                  <div key={item.id}>
                    <p className="survey-quesText">
                      {item?.id + 1}. {item?.questions}
                    </p>
                    <input
                      type="text"
                      placeholder="Write your answer"
                      className="survey-input32"
                      value={
                        (
                          questionAnswers.find(
                            (qa) => qa.questionId === item.id
                          ) || {}
                        ).answer || ""
                      }
                      onChange={(e) =>
                        handleAnswerChange(item.id, e.target.value)
                      }
                    />
                  </div>
                )
            )}
          </>
        )}

        {tabs == 5 && (
          <>
            <h5></h5>
            <h2 className="survey-heading2"> Feedback and Improvements</h2>
            {data?.map(
              (item, i) =>
                item?.form_type == "Feedback and Improvements" && (
                  <div key={item.id}>
                    <p className="survey-quesText">
                      {item?.id + 1}. {item?.questions}
                    </p>
                    <input
                      type="text"
                      placeholder="Write your answer"
                      className="survey-input32"
                      value={
                        (
                          questionAnswers.find(
                            (qa) => qa.questionId === item.id
                          ) || {}
                        ).answer || ""
                      }
                      onChange={(e) =>
                        handleAnswerChange(item.id, e.target.value)
                      }
                    />
                  </div>
                )
            )}
          </>
        )}
        {tabs == 6 && (
          <>
            <h2 className="survey-heading2">Likelihood to Purchase</h2>
            {data?.map(
              (item) =>
                item?.form_type === "Likelihood to Purchase" && (
                  <LikelihoodToPurchase
                    key={item.id}
                    item={item}
                    questionAnswers={questionAnswers}
                    handleAnswerChange={handleAnswerChange}
                  />
                )
            )}
          </>
        )}

        {tabs == 7 && (
          <>
            <h2 className="survey-heading2">Additional Comments</h2>
            {data?.map(
              (item, i) =>
                item?.form_type == "Additional Comments?" && (
                  <div key={item.id}>
                    <p className="survey-quesText">
                      {item?.id + 1}. {item?.questions}
                    </p>
                    <input
                      type="text"
                      placeholder="Write your answer"
                      className="survey-input32"
                      value={
                        (
                          questionAnswers.find(
                            (qa) => qa.questionId === item.id
                          ) || {}
                        ).answer || ""
                      }
                      onChange={(e) =>
                        handleAnswerChange(item.id, e.target.value)
                      }
                    />
                  </div>
                )
            )}
          </>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <div>
          {tabs != 1 && (
            <button className="btn77Feasib" onClick={() => settabs(tabs - 1)}>
              Prev
            </button>
          )}
        </div>
        {tabs == 7 ? (
          <button className="btn77Feasib" onClick={handleSubmit}>
            Submit
          </button>
        ) : (
          <button className="btn77Feasib" onClick={() => settabs(tabs + 1)}>
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default SurveyForms;
