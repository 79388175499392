export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAIL = "VERIFY_EMAIL_FAIL";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const USER_GOOGLE_REQUEST = "USER_GOOGLE_REQUEST";
export const USER_GOOGLE_SUCCESS = "USER_GOOGLE_SUCCESS";
export const USER_GOOGLE_FAIL = "USER_GOOGLE_FAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const FORGOT_PASSWORD_SET_REQUEST = "FORGOT_PASSWORD_SET_REQUEST";
export const FORGOT_PASSWORD_SET_SUCCESS = "FORGOT_PASSWORD_SET_SUCCESS";
export const FORGOT_PASSWORD_SET_FAIL = "FORGOT_PASSWORD_SET_FAIL";

export const UPDATE_REGISTER_DATA_REQUEST = "UPDATE_REGISTER_DATA_REQUEST";
export const UPDATE_REGISTER_DATA_SUCCESS = "UPDATE_REGISTER_DATA_SUCCESS";
export const UPDATE_REGISTER_DATA_FAIL = "UPDATE_REGISTER_DATA_FAIL";

export const GET_REGISTER_DATA_REQUEST = "GET_REGISTER_DATA_REQUEST";
export const GET_REGISTER_DATA_SUCCESS = "GET_REGISTER_DATA_SUCCESS";
export const GET_REGISTER_DATA_FAIL = "GET_REGISTER_DATA_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";

export const FREE_OUTPUT_GET_REQUEST = "FREE_OUTPUT_REQUEST";
export const FREE_OUTPUT_GET_SUCCESS = "FREE_OUTPUT_GET_SUCCESS";
export const FREE_OUTPUT_GET_FAIL = "FREE_OUTPUT_GET_FAIL";
export const FREE_OUTPUT_EMPTY_REDUCER = "FREE_OUTPUT_EMPTY_REDUCER";

export const BASIC_OUTPUT_GET_REQUEST = "BASIC_OUTPUT_REQUEST";
export const BASIC_OUTPUT_GET_SUCCESS = "BASIC_OUTPUT_GET_SUCCESS";
export const BASIC_OUTPUT_GET_FAIL = "BASIC_OUTPUT_GET_FAIL";
export const BASIC_OUTPUT_EMPTY_REDUCER = "BASIC_OUTPUT_EMPTY_REDUCER";

export const FEASIBILITY_POST_REQUEST = "FEASIBILITY_POST_REQUEST";
export const FEASIBILITY_POST_SUCCESS = "FEASIBILITY_POST_SUCCESS";
export const FEASIBILITY_POST_FAIL = "FEASIBILITY_POST_FAIL";

export const PLANINPUT_POST_REQUEST = "PLANINPUT_POST_REQUEST";
export const PLANINPUT_POST_SUCCESS = "PLANINPUT_POST_SUCCESS";
export const PLANINPUT_POST_FAIL = "PLANINPUT_POST_FAIL";

export const FEASIBILITY_TAB1_POST_REQUEST = "FEASIBILITY_TAB1_POST_REQUEST";
export const FEASIBILITY_TAB1_POST_SUCCESS = "FEASIBILITY_TAB1_POST_SUCCESS";
export const FEASIBILITY_TAB1_POST_FAIL = "FEASIBILITY_TAB1_POST_FAIL";

export const UPLOAD_HISTORY_GET_REQUEST = "UPLOAD_HISTORY_GET_REQUEST";
export const UPLOAD_HISTORY_GET_SUCCESS = "UPLOAD_HISTORY_GET_SUCCESS";
export const UPLOAD_HISTORY_GET_FAIL = "UPLOAD_HISTORY_GET_FAIL";

export const UPLOAD_HISTORY_DELETE_REQUEST = "UPLOAD_HISTORY_DELETE_REQUEST";
export const UPLOAD_HISTORY_DELETE_SUCCESS = "UPLOAD_HISTORY_DELETE_SUCCESS";
export const UPLOAD_HISTORY_DELETE_FAIL = "UPLOAD_HISTORY_DELETE_FAIL";

export const DELETE_PROFILE_REQUEST = "DELETE_PROFILE_REQUEST";
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS";
export const DELETE_PROFILE_FAIL = "DELETE_PROFILE_FAIL";

export const EMAIL_DATA_GET_REQUEST = "EMAIL_DATA_GET_REQUEST";
export const EMAIL_DATA_GET_SUCCESS = "EMAIL_DATA_GET_SUCCESS";
export const EMAIL_DATA_GET_FAIL = "EMAIL_DATA_GET_FAIL";

export const REDIRECT_FETCH_GET_REQUEST = "REDIRECT_FETCH_GET_REQUEST";
export const REDIRECT_FETCH_GET_SUCCESS = "REDIRECT_FETCH_GET_SUCCESS";
export const REDIRECT_FETCH_GET_FAIL = "REDIRECT_FETCH_GET_FAIL";

export const FREE_OUTPUT_POST_SUCCESS = "FREE_OUTPUT_POST_SUCCESS"
export const FREE_OUTPUT_POST_REQUEST = "FREE_OUTPUT_POST_REQUEST"
export const FREE_OUTPUT_POST_FAILURE = "FREE_OUTPUT_POST_FAILURE"

export const ENERGY_FETCH_DATA_REQUEST = "ENERGY_FETCH_DATA_REQUEST";
export const ENERGY_FETCH_DATA_SUCCESS = "ENERGY_FETCH_DATA_SUCCESS";
export const ENERGY_FETCH_DATA_FAILURE = "ENERGY_FETCH_DATA_FAILURE";

export const FREE_ENERGY_POST_REQUEST = "FREE_ENERGY_POST_REQUEST";
export const FREE_ENERGY_POST_SUCCESS = "FREE_ENERGY_POST_SUCCESS";
export const FREE_ENERGY_POST_FAILURE = "FREE_ENERGY_POST_FAILURE";

export const CONTACT_POST_REQUEST = "CONTACT_POST_REQUEST";
export const CONTACT_POST_SUCCESS = "CONTACT_POST_SUCCESS";
export const CONTACT_POST_FAILURE = "CONTACT_POST_FAILURE";

export const CONNECTION_POST_REQUEST = "CONNECTION_POST_REQUEST";
export const CONNECTION_POST_SUCCESS = "CONNECTION_POST_SUCCESS";
export const CONNECTION_POST_FAILURE = "CONNECTION_POST_FAILURE";

export const TRAILS_CONTACTUS_POST_REQUEST = "TRAILS_CONTACTUS_POST_REQUEST";
export const TRAILS_CONTACTUS_POST_SUCCESS = "TRAILS_CONTACTUS_POST_SUCCESS";
export const TRAILS_CONTACTUS_POST_FAILURE = "TRAILS_CONTACTUS_POST_FAILURE";

export const SURVEY_FORMS_GET_REQUEST = "SURVEY_FORMS_GET_REQUEST";
export const SURVEY_FORMS_GET_SUCCESS = "SURVEY_FORMS_GET_SUCCESS";
export const SURVEY_FORMS_GET_FAIL = "SURVEY_FORMS_GET_FAIL";

export const SURVEY_FORMS_POST_REQUEST = "SURVEY_FORMS_POST_REQUEST";
export const SURVEY_FORMS_POST_SUCCESS = "SURVEY_FORMS_POST_SUCCESS";
export const SURVEY_FORMS_POST_FAIL = "SURVEY_FORMS_POST_FAIL";


