import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <hr className="footer-line11" />
      <div className="footer-container">
        <div>
          <h2 className="footer-heading111 mb-0">Nature Electric</h2>
          <div className="footer-flex33">
            <Link to="/about-us">About Us</Link>
            <Link to="/contact-us">Contact Us</Link>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-between align-items-end">
          <div className="footer-flex33 mb-2">
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
            <Link to="/privacy-policy" className="pe-0">
              Privacy Policy
            </Link>
          </div>

          <p className="mb-0"> © 2024 Nature Electric. All Rights Reserved</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
