import React from "react";
import { Link } from "react-router-dom";
import image1 from "../assets/images/ImageDash11.jpg";
import image2 from "../assets/images/Image (144).jpg";
import image3 from "../assets/images/Image123e.jpg";
import image4 from "../assets/images/ImageR487i.jpg";
import image5 from "../assets/images/Imageuyi7ty.svg";
import image6 from "../assets/images/Imageg1we.svg";
import image7 from "../assets/images/Imagef55.svg";
import video1 from "../assets/videos/Solar&BatteryFeasibilityTool.mp4";
import ReactPlayer from "react-player";

const Dashbord = () => {
  return (
    <div className="dashboard-container">
      <div className="dashboard-section1">
        <div className="dashboard-flex">
          <h2 className="dashboard-heading122">
            A tool that facilitates your decision-making to adopt renewable energy
          </h2>
          <p className="dashboard-text6y">
            Find out how renewable options will help reduce your bills and
            carbon footprint
          </p>
          <div className="d-flex justify-content-between gap-5">
            <div className="home-div26p">
              <Link to="/free-energy">
                <button className="dashboard-btn1">
                  Start The Free Trial
                </button>
              </Link>
              <p className="dashboard-text6y home-margin33">
             <b> Estimate with Insight</b>: Power Your Decisions with Industry Averages
              </p>
            </div>
            <div className="home-div26p">
              <Link to="/premium-energy">
                <button className="dashboard-btn1">
                  Use our Premium Tool
                </button>
              </Link>
              <p className="dashboard-text6y home-margin33">
              <b>Analyse Precisely:</b> Transforming Your Energy Profile into Accurate Insights
              </p>
            </div>
          </div>
        </div>
        <div className="flexW-dashboard">
          <img className="dashboard-img11" src={image1} alt="home img" />
          <img className="dashboard-img12" src={image2} alt="home img" />
          <img className="dashboard-img13" src={image3} alt="home img" />
        </div>
      </div>

      <div className="dashboard-section2">
        {/* <div className="dashboard-div33">
          {playing ? (
            <button className="dashboard-div3Btn44 btn-hidecls" onClick={() => setPlaying(false)}>
              <i class="fa-solid fa-pause"></i>
            </button>
          ) : (
            <button className="dashboard-div3Btn44 " onClick={() => setPlaying(true)}>
              <i class="fa-solid fa-play"></i>
            </button>
          )}
        </div> */}
        <ReactPlayer
          url={video1}
          type="video/mp4"
          controls={true}
          playing={false}
          loop={true}
          muted={true}
          width="100%"
          height="100%"
        />
      </div>

      <div className="dashboard-flex21">
        <div className="flexW-dashboard">
          <img className="dashboard-img124" src={image4} alt="image" />
        </div>
        <div className="dashboard-flex-222">
          <p className="dashboard-Text21">Key Benefits</p>
          <h2 className="heading-45t ">Intelligent Renewables Estimator</h2>
          <ol>
            <li className="dashboard-Text21">
              Tailored, unbiased suggestion based on your energy needs
            </li>
            <li className="dashboard-Text21">Cost-benefits analysis of going renewable</li>
            <li className="dashboard-Text21">Understand your investment risk</li>
            <li className="dashboard-Text21">Intuitive instant results</li>
          </ol>
          <Link to="/free-energy">
            <button className="dashboard-btn2">
              Start The Free Trial
            </button>
          </Link>
        </div>
      </div>

      <div>
        <div className="text-center">
          <h2 className="dashboard-heading1 mb-0">FAQs</h2>
          <p className="dashboard-Text53uo">
            Read the frequently asked question here
          </p>
        </div>
        <div className="div877">
          <div className="div76hh-flexParent">
            <div className="div877-flex">
              <img className="w-100" src={image5} alt="img" />
              <p className="text-dashboard4">
                <b><i>How accurate are the estimates provided?</i></b> While we aim for accuracy, our estimates rely on your input and general solar energy data. 
                They provide a realistic starting point for decision-making and discussions. 
                For a precise estimate, we suggest a professional onsite evaluation through our partners or your chosen solar installer.
              </p>
            </div>
            <div className="div877-flex">
              <img className="w-100" src={image6} alt="img" />
              <p className="text-dashboard4">
              <b><i>How is the system size estimated?</i></b>
              We determine solar system size by considering your property's space, energy consumption, and local sunlight levels.
              Our goal is to match the system size to your energy requirements, avoiding inefficiency (too big) or shortfall (too small). 
              We optimize based on roof dimensions and weather patterns to maximize economic benefits.
              </p>
            </div>
            <div className="div877-flex">
              <img className="w-100" src={image7} alt="img" />
              <p className="text-dashboard4">
              <b><i>How is the potential savings estimated?</i></b>
                We estimate potential savings by comparing your current electricity expenses with those after installing a solar and battery system. 
                We factor in system size, efficiency, expected energy output, and your usage patterns. 
                Savings also depend on feed-in tariffs for surplus energy sent to the grid. 
                We also consider future changes in electricity rates for a more holistic savings projection. These are estimates and a professional evaluation and financial consultation is required.
              </p>
            </div>
          </div>
          <Link to="/faq">
            <button className="dashboard-btn78y">Load More</button>
          </Link>
        </div>
      </div>

      <div className="dashboard-section4">
        <h2 className="dashboard-heading1 mb-0">
          Take the First Step Towards Success
        </h2>
        <Link to="/free-energy">
          <button className="dashboard-btn2 dashboard-btnMargin22">
            Start The Free Trial
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Dashbord;
