import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";

const TermsAndCondition = () => {
  const [openCollapse1, setOpenCollapse1] = useState(false);
  const [openCollapse2, setOpenCollapse2] = useState(false);
  const [openCollapse3, setOpenCollapse3] = useState(false);
  const [openCollapse4, setOpenCollapse4] = useState(false);
  const [openCollapse5, setOpenCollapse5] = useState(false);
  const [openCollapse6, setOpenCollapse6] = useState(false);
  const [openCollapse7, setOpenCollapse7] = useState(false);
  const [openCollapse8, setOpenCollapse8] = useState(false);
  const [openCollapse9, setOpenCollapse9] = useState(false);
  const [openCollapse10, setOpenCollapse10] = useState(false);
  const [openCollapse11, setOpenCollapse11] = useState(false);
  const [loadMore, setloadMore] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="termsCondtion-container">
      <div className="termsCondtion-section1">
        <p className="faq-para11 mb-0">
          Our end-to-end solution simplifies the transition to renewable energy
          and achieving net-zero
        </p>
      </div>
      <h2 className="faq-para124">Nature Electric Terms & Conditions</h2>
      <div className="termsCondtion-section2">
        <div className="collapse-section">
          <button
            className="faq-quesBtn"
            variant="primary"
            onClick={() => setOpenCollapse1(!openCollapse1)}
          >
            <span>Definitions</span>
            {openCollapse1 ? (
              <i class="fa-regular fa-circle-chevron-up"></i>
            ) : (
              <i class="fa-regular fa-circle-chevron-down"></i>
            )}
          </button>
          <Collapse in={openCollapse1}>
            <div>
              <p className="faq-answerSection">
                In these Terms of Service and Conditions:
                <br />
                “Terms of Service and Conditions” means this document.
                <br />
                “We”, “our”, or “us” refer to Nature Electric PTY Limited ABN 52
                695 693 647
                <br />
                “You”, “your”, or “user” refers to the person or entity using
                our website and/or the services we provide.
              </p>
            </div>
          </Collapse>
        </div>
        <div className="collapse-section">
          <button
            className="faq-quesBtn"
            variant="primary"
            onClick={() => setOpenCollapse2(!openCollapse2)}
          >
            <span>‍Acceptance of Terms</span>
            {openCollapse2 ? (
              <i class="fa-regular fa-circle-chevron-up"></i>
            ) : (
              <i class="fa-regular fa-circle-chevron-down"></i>
            )}
          </button>
          <Collapse in={openCollapse2}>
            <div>
              <p className="faq-answerSection">
                By accessing or using Nature Electric's services, including but
                not limited to our website, software, mobile applications, and
                any related services (collectively, the "Service"), you agree to
                comply with and be bound by these Terms and Conditions. If you
                do not agree to these Terms and Conditions, please do not use
                our Service.
              </p>
            </div>
          </Collapse>
        </div>{" "}
        <div className="collapse-section">
          <button
            className="faq-quesBtn"
            variant="primary"
            onClick={() => setOpenCollapse3(!openCollapse3)}
          >
            <span>Services</span>
            {openCollapse3 ? (
              <i class="fa-regular fa-circle-chevron-up"></i>
            ) : (
              <i class="fa-regular fa-circle-chevron-down"></i>
            )}
          </button>
          <Collapse in={openCollapse3}>
            <div>
              <p className="faq-answerSection">
                We provide a technology platform that examines user behaviour to
                offer renewable energy transition plans. This platform is
                augmented with advanced technology simulation, enabling
                organisations to explore various renewable energy adoption
                scenarios. The conditions for service include: These terms and
                conditions constitute the entire agreement between the User and
                Nature Electric with respect to the use of the Company's website
                and supersede all prior or contemporaneous understandings and
                agreements, whether oral or written. You agree to use the
                Service only for lawful purposes and in compliance with all
                applicable laws and regulations. You may not use the Service in
                any manner that could damage, disable, overburden, or impair
                Nature Electric's servers or networks or interfere with any
                other party's use and enjoyment of the Service. Nature Electric
                reserves the right to suspend or terminate your access to the
                service at any time and for any reason, including but not
                limited to, violations of these Terms and Conditions.
              </p>
            </div>
          </Collapse>
        </div>{" "}
        <div className="collapse-section">
          <button
            className="faq-quesBtn"
            variant="primary"
            onClick={() => setOpenCollapse4(!openCollapse4)}
          >
            <span>‍Registration and Account</span>
            {openCollapse4 ? (
              <i class="fa-regular fa-circle-chevron-up"></i>
            ) : (
              <i class="fa-regular fa-circle-chevron-down"></i>
            )}
          </button>
          <Collapse in={openCollapse4}>
            <div>
              <p className="faq-answerSection">
                You may be required to create a user account to access certain
                features of our Services. You are responsible for maintaining
                the confidentiality of your account information and for all
                activities that occur under your account. You agree to provide
                accurate and complete information when creating your account and
                to update it as necessary. We reserve the right to suspend or
                terminate your account and access to the Service if we suspect
                that you have provided false or misleading information or
                violated any provision of these Terms and Conditions.
              </p>
            </div>
          </Collapse>
        </div>{" "}
        <div className="collapse-section">
          <button
            className="faq-quesBtn"
            variant="primary"
            onClick={() => setOpenCollapse5(!openCollapse5)}
          >
            <span>‍Privacy and Data Protection</span>
            {openCollapse5 ? (
              <i class="fa-regular fa-circle-chevron-up"></i>
            ) : (
              <i class="fa-regular fa-circle-chevron-down"></i>
            )}
          </button>
          <Collapse in={openCollapse5}>
            <div>
              <p className="faq-answerSection">
                Your use of the Service is also governed by our Privacy Policy,
                which can be found on our website. By using our Service, you
                consent to the collection and use of your personal information
                as described in the Privacy Policy.
              </p>
            </div>
          </Collapse>
        </div>{" "}
        <div className="collapse-section">
          <button
            className="faq-quesBtn"
            variant="primary"
            onClick={() => setOpenCollapse6(!openCollapse6)}
          >
            <span>‍Disclaimer of Warranties</span>
            {openCollapse6 ? (
              <i class="fa-regular fa-circle-chevron-up"></i>
            ) : (
              <i class="fa-regular fa-circle-chevron-down"></i>
            )}
          </button>
          <Collapse in={openCollapse6}>
            <div>
              <p className="faq-answerSection">
                The content of our website is provided "as is" and "as
                available." We make no warranties, express or implied, regarding
                the accuracy, reliability, or suitability of the website for any
                particular purpose. Further, makes no warranties, either express
                or implied, regarding the accuracy, likelihood of results, or
                reliability of the content on its website or any linked
                websites, including, but not limited to, warranties of
                merchantability, fitness for a particular purpose, or
                non-infringement.
              </p>
            </div>
          </Collapse>
        </div>
        <Collapse in={loadMore}>
          <div>
            <div className="collapse-section">
              <button
                className="faq-quesBtn"
                variant="primary"
                onClick={() => setOpenCollapse7(!openCollapse7)}
              >
                <span>‍Limitation of Liability</span>
                {openCollapse7 ? (
                  <i class="fa-regular fa-circle-chevron-up"></i>
                ) : (
                  <i class="fa-regular fa-circle-chevron-down"></i>
                )}
              </button>
              <Collapse in={openCollapse7}>
                <div>
                  <p className="faq-answerSection">
                    In no event shall the Company be liable to the User or any
                    third party for any direct, indirect, consequential,
                    special, or punitive damages arising out of or related to
                    the use of Nature Electric's website or any linked websites,
                    including, but not limited to, lost profits, business
                    interruption, or loss of data. Nature Electric shall not be
                    liable for any direct, indirect, incidental, special,
                    consequential, or punitive damages, or any loss of profits
                    or revenues, whether incurred directly or indirectly, or any
                    loss of data, use, goodwill, or other intangible losses,
                    resulting from (i) your use or inability to use the Service;
                    (ii) any unauthorised access to or use of our servers and/or
                    any personal information stored therein; (iii) any
                    interruption or cessation of transmission to or from the
                    Service; (iv) any bugs, viruses, trojan horses, or the like
                    that may be transmitted to or through the Service; or (v)
                    any errors or omissions in any content or materials on the
                    Service.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse-section">
              <button
                className="faq-quesBtn"
                variant="primary"
                onClick={() => setOpenCollapse8(!openCollapse8)}
              >
                <span>Indemnification</span>
                {openCollapse8 ? (
                  <i class="fa-regular fa-circle-chevron-up"></i>
                ) : (
                  <i class="fa-regular fa-circle-chevron-down"></i>
                )}
              </button>
              <Collapse in={openCollapse8}>
                <div>
                  <p className="faq-answerSection">
                    The User agrees to indemnify and hold harmless Nature
                    Electric, its officers, directors, employees, agents, and
                    affiliates from and against any claims, losses, liabilities,
                    expenses, damages, and costs (including attorney's fees)
                    arising out of or related to the User's use of the Company's
                    website or any linked websites.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse-section">
              <button
                className="faq-quesBtn"
                variant="primary"
                onClick={() => setOpenCollapse9(!openCollapse9)}
              >
                <span>‍Changes to Terms and Conditions</span>
                {openCollapse9 ? (
                  <i class="fa-regular fa-circle-chevron-up"></i>
                ) : (
                  <i class="fa-regular fa-circle-chevron-down"></i>
                )}
              </button>
              <Collapse in={openCollapse9}>
                <div>
                  <p className="faq-answerSection">
                    Nature Electric may update these Terms and Conditions from
                    time to time. Any changes will be effective upon posting on
                    our website. It is your responsibility to review these terms
                    periodically.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse-section">
              <button
                className="faq-quesBtn"
                variant="primary"
                onClick={() => setOpenCollapse10(!openCollapse10)}
              >
                <span>‍Governing Law and Dispute Resolution</span>
                {openCollapse10 ? (
                  <i class="fa-regular fa-circle-chevron-up"></i>
                ) : (
                  <i class="fa-regular fa-circle-chevron-down"></i>
                )}
              </button>
              <Collapse in={openCollapse10}>
                <div>
                  <p className="faq-answerSection">
                    These terms and conditions shall be governed by and
                    construed in accordance with the laws of Australia without
                    regard to its conflict of law principles. Any disputes
                    arising out of or related to these terms and conditions, or
                    the use of Nature Electric's website shall be subject to the
                    exclusive jurisdiction of the courts located in Australia.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse-section">
              <button
                className="faq-quesBtn"
                variant="primary"
                onClick={() => setOpenCollapse11(!openCollapse11)}
              >
                <span>Severability</span>
                {openCollapse11 ? (
                  <i class="fa-regular fa-circle-chevron-up"></i>
                ) : (
                  <i class="fa-regular fa-circle-chevron-down"></i>
                )}
              </button>
              <Collapse in={openCollapse11}>
                <div>
                  <p className="faq-answerSection">
                    If any provision of these terms and conditions is found to
                    be invalid or unenforceable, the remaining provisions shall
                    remain in full force and effect.
                  </p>
                </div>
              </Collapse>
            </div>
          </div>
        </Collapse>
        <div className="d-flex justify-content-end">
          <button
            className="faq-btn9 me-0"
            onClick={() => setloadMore(!loadMore)}
          >
            {loadMore ? "Load less" : "Load more"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
