import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validations } from "../utils";
import ReactPlayer from "react-player";
import { MoreTrailsAction } from "../Redux/actions/PublicActions";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import video1 from "../assets/videos/SmartMeterAnalytics.mp4";
import video2 from "../assets/videos/RenewablePathway.mp4";

const MoreTrialsContactUs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const heading = searchParams.get("value");

  const { data, error } = useSelector((state) => state.MoreTrailsPostReducer);

  const [name, setname] = useState("");
  const [organization, setorganization] = useState("");
  const [email, setemail] = useState("");
  const [notes, setnotes] = useState("");
  const [errors, seterrors] = useState({
    name: null,
    organization: null,
    email: null,
    notes: null,
  });
  const [render, setRender] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (data && render) {
      swal({
        title: "",
        text: "Submitted",
        className: "successAlert",
        icon: "/img/logo.svg",
        buttons: false,
        timer: 3000,
        open: true,
      });
      setname("");
      setorganization("");
      setemail("");
      setnotes("");
      setRender(false);
      setTimeout(() => {
        navigate("/");
      }, [3000]);
    }

    if (error && render) {
      swal({
        title: "",
        text: error,
        className: "successAlert",
        icon: "img/logo.svg",
        buttons: false,
        timer: 3000,
        open: true,
      });
      setRender(false);
    }
  }, [data, error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const tempErrors = {
      name: validations.fullName(name),
      organization: validations.organization(organization),
      email: validations.email(email),
      notes: !notes && "*Required",
    };
    seterrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }

    const data = new FormData();
    data.append("name", name);
    data.append("organisation", organization);
    data.append("email", email);
    data.append("content", notes);
    setRender(true);
    dispatch(MoreTrailsAction(data));
  };
  return (
    <div className="contact-container">
      <div className="contact-flex">
        <div className="contact-flex1">
          <div className="contact-newdiv32">
            {heading == "Smart Meter Analytics" ? (
              <ReactPlayer
                url={video1}
                type="video/mp4"
                controls={true}
                playing={false}
                loop={true}
                muted={true}
                width="100%"
                height="100%"
              />
            ) : (
              <ReactPlayer
                url={video2}
                type="video/mp4"
                controls={true}
                playing={false}
                loop={true}
                muted={true}
                width="100%"
                height="100%"
              />
            )}
          </div>
          <h2 className="contact-heading22 headingMargin">
            {heading == "Smart Meter Analytics"
              ? "Smart Meter Analytics"
              : "100% Renewable Pathway"}
          </h2>
        </div>
        <div className="contact-flex2">
          {/* <div className="contactus-div233">
            <img className="contactus-Img22" src={image1} alt="home img" />
          </div> */}
          <div className="contact-flex2-div33">
            <h2 className="contact-heading22 mt-5">
              Registeration of Interest
            </h2>
            <div className="contact-div44">
              <label className="contact-label">Name</label>
              <input
                type="text"
                onChange={(e) => {
                  setname(e.target.value);
                  seterrors({ ...errors, name: null });
                }}
                className="contact-input"
              />
              <p
                style={{
                  color: "red",
                  fontSize: "13px",
                  opacity: errors.name ? 1 : 0,
                  position: "absolute",
                }}
              >
                {errors.name ?? "valid"}
              </p>
            </div>
            <div className="contact-div44">
              <label className="contact-label">Organisation</label>
              <input
                type="text"
                onChange={(e) => {
                  setorganization(e.target.value);
                  seterrors({ ...errors, organization: null });
                }}
                className="contact-input"
              />
              <p
                style={{
                  color: "red",
                  fontSize: "13px",
                  opacity: errors.organization ? 1 : 0,
                  position: "absolute",
                }}
              >
                {errors.organization ?? "valid"}
              </p>
            </div>
            <div className="contact-div44">
              <label className="contact-label">Email</label>
              <input
                type="email"
                onChange={(e) => {
                  setemail(e.target.value);
                  seterrors({ ...errors, email: null });
                }}
                className="contact-input"
              />
              <p
                style={{
                  color: "red",
                  fontSize: "13px",
                  opacity: errors.email ? 1 : 0,
                  position: "absolute",
                }}
              >
                {errors.email ?? "valid"}
              </p>
            </div>
            <div className="contact-div44">
              <label className="contact-label">How we can help you</label>
              <textarea
                className="contact-input"
                onChange={(e) => {
                  setnotes(e.target.value);
                  seterrors({ ...errors, notes: null });
                }}
                cols="30"
                rows="8"
              ></textarea>
              <p
                style={{
                  color: "red",
                  fontSize: "13px",
                  opacity: errors.notes ? 1 : 0,
                  position: "absolute",
                }}
              >
                {errors.notes ?? "valid"}
              </p>
            </div>
            <button className="contact-btn33" onClick={handleSubmit}>
              {" "}
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreTrialsContactUs;
